import React from 'react'
import { IconType } from '../../types/icon.type'

const LinkIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.19999 16C5.19999 13.72 7.05332 11.8667 9.33332 11.8667H14.6667V9.33334H9.33332C5.65332 9.33334 2.66666 12.32 2.66666 16C2.66666 19.68 5.65332 22.6667 9.33332 22.6667H14.6667V20.1333H9.33332C7.05332 20.1333 5.19999 18.28 5.19999 16ZM10.6667 17.3333H21.3333V14.6667H10.6667V17.3333ZM22.6667 9.33334H17.3333V11.8667H22.6667C24.9467 11.8667 26.8 13.72 26.8 16C26.8 18.28 24.9467 20.1333 22.6667 20.1333H17.3333V22.6667H22.6667C26.3467 22.6667 29.3333 19.68 29.3333 16C29.3333 12.32 26.3467 9.33334 22.6667 9.33334Z'
        fill={color}
      />
    </svg>
  )
}

export default LinkIcon
