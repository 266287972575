import _ from 'lodash'
import { useState } from 'react'

// eslint-disable-next-line
const UseForm = <T extends object>(
  data: T,
  setTrigger?: React.Dispatch<React.SetStateAction<boolean>>,
  trigger?: boolean,
) => {
  const [form] = useState<T>(data)

  const updateForm = (
    name: keyof typeof data | (keyof typeof data)[],
    value: string | string[] | boolean | number | object | [],
  ) => {
    if (Array.isArray(name) && Array.isArray(value)) {
      for (let i = 0; i < name.length; i++) {
        _.set(form, name[i], value[i])
      }
    } else {
      _.set(form, name, value)
    }

    if (setTrigger !== undefined) {
      setTrigger(!trigger)
    }
  }

  return { form, updateForm }
}

export default UseForm
