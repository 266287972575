import React from 'react'
import { IconType } from '../../types/icon.type'

const ProblemIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 7.98667L26.04 25.3333H5.95998L16 7.98667ZM16 2.66667L1.33331 28H30.6666L16 2.66667ZM17.3333 21.3333H14.6666V24H17.3333V21.3333ZM17.3333 13.3333H14.6666V18.6667H17.3333V13.3333Z'
        fill={color}
      />
    </svg>
  )
}

export default ProblemIcon
