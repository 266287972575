import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../../../global.module.css'
import styles from './cart.module.css'

import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { User } from '../../../../types/user-types/user.type'
import Loader from '../../../../components/loader/Loader'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import { ITool } from '../../../../types/tool-types/tool.types'
import { staticSvgs } from '../../../../app/assets/images'
import ToolCardMolecule from '../../../../components/molecules/tool-card/ToolCard.molecule'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes } from '../../../../app/constants/constants.app'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import {
  addToolToCart,
  removeToolFromCart,
} from '../../../../app/features/tool-cart/tool-cart.slice'
import { RootState } from '../../../../app/store/store'

const DashboardCartView = () => {
  const [toolMakers, setToolMakers] = useState<User[]>([])
  const [tools, setTools] = useState<ITool[]>([])
  const dispatch = useAppDispatch()
  const toolCartState = useAppSelector((state: RootState) => state.toolCartSlice)

  const [loading, setLoading] = useState(false)
  const [selectedToolMaker, setSelectedToolMaker] = useState('')

  const fetchUsers = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ toolMakers: User[] }>('/tools/tool-makers')
        .then((response) => {
          setToolMakers(response.data.toolMakers)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  useEffect(() => {
    if (selectedToolMaker.length > 0) {
      const fetchToolMakersTools = async () => {
        setLoading(true)
        try {
          await publicApiRequest()
            .get<{ tools: ITool[] }>(`/tools/${selectedToolMaker}`)
            .then((response) => {
              setTools(response.data.tools)
            })
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }

      fetchToolMakersTools()
    }
  }, [selectedToolMaker])

  console.log('====================================')
  console.log({ tools })
  console.log('====================================')

  return (
    <div className={`${globals['full-width']} ${globals['p-desktop']} ${styles.cart}`}>
      <div className={styles.head}>
        <h2>Shop Farming Tools</h2>
      </div>
      {loading ? <Loader /> : ''}
      {!loading && selectedToolMaker.length > 0 && tools.length === 0 ? (
        <div
          className={`${globals.flex} ${globals['gap-16']} ${globals['flex-column']} ${styles.no__content} ${globals['center-items']} ${globals['full-width']}`}
        >
          <img width={150} height={150} src={staticSvgs.dashboard.EmptySearch} alt='no content' />
          <div>
            <h3>Oops, Empty List</h3>
            <h4>No tools found for this tool maker yet.</h4>
          </div>
        </div>
      ) : (
        ''
      )}
      {!loading && selectedToolMaker.length > 0 && tools.length > 0 ? (
        <div className={`${styles.grid}`}>
          {tools.length > 0 &&
            tools.map((tool, index) => {
              return (
                <div
                  // onClick={() => {
                  //   setIsAdding(false)
                  //   setIsEditing(true)
                  //   setSelectedId(tool._id as string)
                  // }}
                  key={index}
                >
                  <ToolCardMolecule tool={tool} />
                  <div style={{ marginTop: '12px' }}>
                    <ButtonAtom
                      onClick={() => {
                        if (
                          toolCartState.tools.find((item) => item._id === tool._id) !== undefined
                        ) {
                          dispatch(removeToolFromCart({ id: tool._id as string }))
                        } else dispatch(addToolToCart({ product: tool }))
                      }}
                      type={buttonTypes.primary}
                      btnText={
                        toolCartState.tools.find((item) => item._id === tool._id) === undefined
                          ? 'Add to Cart'
                          : 'Remove from Cart'
                      }
                      fontWeight={500}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      ) : (
        ''
      )}
      <div className={`${styles.farmers} ${globals['full-width']}`}>
        {toolMakers.length > 0 &&
          !loading &&
          selectedToolMaker.length === 0 &&
          toolMakers.map((farmer, index) => {
            return (
              <article
                onClick={() => {
                  setSelectedToolMaker(farmer._id as string)
                }}
                key={index}
                className={styles.card}
              >
                <div className={styles.image}>
                  <img src={farmer.imageUrl} alt={farmer.firstName} />
                </div>
                <div className={styles.body}>
                  <h3>
                    {farmer?.firstName} {farmer?.lastName}
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus earum
                    facilis laboriosam, dignissimos reiciendis incidunt.
                  </p>

                  <div
                    style={{ marginTop: '20px' }}
                    className={`${styles.rating} ${globals.flex} ${globals['a-center']}`}
                  >
                    <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                    <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                    <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                    <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                    <p>(56 People)</p>
                  </div>
                </div>
              </article>
            )
          })}
      </div>
    </div>
  )
}

export default DashboardCartView
