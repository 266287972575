import React, { useState } from 'react'

import globals from '../../../global.module.css'
import styles from './input.module.css'

import { inputState } from '../../../app/constants/constants.app'
import { IconsRepository } from '../../../repository/icons/icons.repository'
import useWindowSize from '../../../lib/hooks/use-window-soze'

export type InputProps = {
  type: string
  name: string
  placeholder: string
  value?: string
  state?: inputState
  id?: string
  list: string[]
  onSelect: (name: string) => void
}

const SelectAtom = ({ type, state, onSelect, list, name, placeholder, value, id }: InputProps) => {
  const { width } = useWindowSize()

  const [activeDrop, setActiveDrop] = useState(false)

  return (
    <div
      className={`${styles.input__wrapper} ${globals.flex} ${globals['a-center']} ${globals['full-width']}`}
    >
      {activeDrop ? (
        <div className={styles.dropdown}>
          {list.length > 0 &&
            list.map((item, index) => {
              return (
                <h4
                  onClick={() => {
                    onSelect(item)
                    setActiveDrop(false)
                  }}
                  key={index}
                >
                  {item}
                </h4>
              )
            })}
        </div>
      ) : (
        ''
      )}
      <input
        readOnly
        id={id}
        className={`${styles.input} ${
          state === 'error' ? styles.error : state === 'success' ? styles.success : ''
        } ${globals['full-width']} ${globals.flex} ${globals['a-center']} ${globals['gap-12']}`}
        placeholder={placeholder}
        type={type}
        onClick={() => {
          setActiveDrop(!activeDrop)
        }}
        style={{
          paddingLeft: '16px',
          cursor: 'pointer',
        }}
        value={value}
        name={name}
      />

      <span className={`${styles.right__icon} ${globals.cursor} ${styles[state as string]}`}>
        <IconsRepository.DropdownIcon size={width < 1440 ? 24 : 32} />
      </span>
    </div>
  )
}

export default SelectAtom
