import React from 'react'

import globals from '../../../../../global.module.css'
import styles from './pcard.module.css'

import { ViewState } from '../../manage-products/NewManageProducts.view'
import { ITool } from '../../../../../types/tool-types/tool.types'

export type PCardProps = {
  image: string
  name: string
  description: string
  price: string
  unit: string
  onClick?: () => void
  category?: string
  quantity?: number
  id?: string
  setViewState?: React.Dispatch<React.SetStateAction<ViewState>>
  setSelectedId?: React.Dispatch<React.SetStateAction<string>>
  ableToEdit?: boolean
  loading?: boolean
  toolCard?: boolean
  orderView?: number
  tool?: ITool
  toolCart?: boolean
  count?: number
  hideCartOptions?: boolean
}

const PCardMolecule: React.FC<PCardProps> = ({
  image,
  name,
  onClick,
  description,
  price,
  unit,
  loading,
  toolCard,
  orderView,
  count,
}) => {
  return (
    <article
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      className={`${globals['full-width']} ${loading ? styles.loading : ''} ${styles.card}`}
    >
      <div className={`${styles.image} ${globals.flex} ${globals['center-items']}`}>
        {loading ? '' : <img src={image} width='100%' height='100%' alt={name} />}
      </div>
      <div
        className={`${styles.body} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
      >
        {loading ? <h3></h3> : <h3>{name}</h3>}
        {orderView ? (
          <h4
            style={{
              fontSize: '1.6rem',
            }}
          >
            Quantity Ordered:{' '}
            <span
              style={{
                fontWeight: '600',
              }}
            >
              {orderView}
            </span>
          </h4>
        ) : (
          ''
        )}
        {loading ? (
          <p></p>
        ) : (
          <p>{description.length > 80 ? description.substring(0, 80) + '...' : description}</p>
        )}
        {loading ? (
          <span></span>
        ) : (
          <span>
            {price} XAF{' '}
            {toolCard ? (
              <>
                <span>per</span> {unit}
              </>
            ) : (
              ''
            )}
          </span>
        )}
        {loading ? (
          <span></span>
        ) : count !== undefined ? (
          <span>Quantity In Cart: {count} </span>
        ) : (
          ''
        )}
      </div>
    </article>
  )
}

export default PCardMolecule
