import {
  ChangePasswordDataType,
  ForgotPasswordDataType,
  LoginResponseType,
  RegisterDataType,
  VerifyUserDataType,
} from './../types/auth.type'
import { publicApiRequest } from '../lib/hooks/axios-instance'
import { LoginDataType } from '../types/auth.type'

export class AuthService {
  public async loginUserService(data: LoginDataType) {
    const response = await publicApiRequest().post<LoginResponseType>('/auth/login', data)
    return response.data
  }

  public async registerUserService(data: RegisterDataType) {
    const response = await publicApiRequest().post('/auth/register', {
      ...data,
      contact: data.phoneNumber,
    })
    return response.data
  }

  public async verifyUserService(data: VerifyUserDataType) {
    const response = await publicApiRequest().patch(`/auth/verify-user/${data.token}/${data.email}`)
    return response.data
  }

  public async forgotPasswordService(data: ForgotPasswordDataType) {
    const response = await publicApiRequest().post('/auth/forgot-password', data)
    return response.data
  }

  public async changePasswordService(data: ChangePasswordDataType) {
    const response = await publicApiRequest().post('/auth/change-password', data)
    return response.data
  }
}
