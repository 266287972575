import React from 'react'

import AuthLayout from '../../../layouts/auth/Auth.layout'
import LoginTemplate from '../../../components/templates/auth/Login.auth.template'

const LoginPage = () => {
  return (
    <AuthLayout catchText='Welcome back to Agrox. Login and resume your session.'>
      <LoginTemplate />
    </AuthLayout>
  )
}

export default LoginPage
