import React from 'react'
import { IconType } from '../../types/icon.type'

const HeartOutlinedIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.2133 5.31999C22.6933 2.91999 18.3467 4.03999 16 6.78665C13.6533 4.03999 9.30666 2.90665 5.78666 5.31999C3.91999 6.59999 2.74666 8.75999 2.66666 11.04C2.47999 16.2133 7.06666 20.36 14.0667 26.72L14.2 26.84C15.2133 27.76 16.7733 27.76 17.7867 26.8267L17.9333 26.6933C24.9333 20.3467 29.5067 16.2 29.3333 11.0267C29.2533 8.75999 28.08 6.59999 26.2133 5.31999ZM16.1333 24.7333L16 24.8667L15.8667 24.7333C9.51999 18.9867 5.33332 15.1867 5.33332 11.3333C5.33332 8.66665 7.33332 6.66665 9.99999 6.66665C12.0533 6.66665 14.0533 7.98665 14.76 9.81332H17.2533C17.9467 7.98665 19.9467 6.66665 22 6.66665C24.6667 6.66665 26.6667 8.66665 26.6667 11.3333C26.6667 15.1867 22.48 18.9867 16.1333 24.7333Z'
        fill={color}
      />
    </svg>
  )
}

export default HeartOutlinedIcon
