import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './tab.module.css'

type TabProps = {
  id: number
  label: string
  link?: string
}

type Props = {
  children: React.ReactNode[]
  listOfTabs: TabProps[]
  secondary?: boolean
  forceSwitchTab?: boolean
  tab?: number
}

const TabComponentMolecule: React.FC<Props> = ({
  children,
  forceSwitchTab,
  tab,
  listOfTabs,
  secondary,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const location = useLocation()

  useEffect(() => {
    if (forceSwitchTab === true && tab) {
      setActiveTab(tab)
    }
  }, [forceSwitchTab, tab])

  return (
    <div className={`${globals['full-width']} ${styles.outer} ${secondary ? styles.second : ''}`}>
      <div className={`${globals.flex} ${globals['full-width']} ${styles.tabs}`}>
        {listOfTabs.map((tab, index) => {
          return (
            <>
              {tab.link ? (
                <Link
                  key={index}
                  to={tab.link}
                  className={`${location.pathname === tab.link ? styles.active : ''} ${
                    secondary ? styles.secondary : ''
                  } ${styles.current__tab}`}
                >
                  {tab.label}
                </Link>
              ) : (
                <div
                  key={index}
                  onClick={() => {
                    setActiveTab(index)
                  }}
                  className={`${activeTab === index ? styles.active : ''} ${
                    secondary ? styles.secondary : ''
                  } ${styles.current__tab}`}
                >
                  {tab.label}
                </div>
              )}
            </>
          )
        })}
      </div>
      <div>{children[activeTab]}</div>
    </div>
  )
}

export default TabComponentMolecule
