import React from 'react'
import { IconType } from '../../types/icon.type'

const CheckBoxIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.3333 4H6.66667C5.18667 4 4 5.2 4 6.66667V25.3333C4 26.8 5.18667 28 6.66667 28H25.3333C26.8133 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8133 4 25.3333 4ZM13.3333 22.6667L6.66667 16L8.54667 14.12L13.3333 18.8933L23.4533 8.77333L25.3333 10.6667L13.3333 22.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckBoxIcon
