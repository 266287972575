import { appConstants } from '../constants/constants.app'

import HomePage from '../../pages/home/Home.page'
import LoginPage from '../../pages/auth/login/Login.auth.page'
import RegisterPage from '../../pages/auth/register/Register.auth.page'
import ForgotPasswordPage from '../../pages/auth/forgot-password/ForgotPassword.auth.page'
import ChangePasswordPage from '../../pages/auth/change-password/ChangePassword.auth.page'
import DashboardPage from '../../pages/dashboard/Dashboard.page'
import ProfilePashboardPage from '../../pages/dashboard/views/profile/Profile.dashboard.page'
import SettingsPashboardPage from '../../pages/dashboard/views/settings/Settings.dashboard.page'
import IndexDashboardPage from '../../pages/dashboard/views/index/Index.dashboard.page'
import StoreFrontDashboardPage from '../../pages/dashboard/views/store-front/StoreFront.dashboard.page'
import AnalysisDashboardPage from '../../pages/dashboard/views/analysis/Analysis.dashboard.page'
import OrdersDashboardPage from '../../pages/dashboard/views/orders/Orders.dashboard.page'
import VerifyEmailAuthPage from '../../pages/auth/verify-email/VerifyEmail.auth.page'
import FarmstorePage from '../../pages/farm-store/Farmstore.page'
import StoreDetailsPage from '../../pages/store-details/StoreDetails.page'
import ProductDetailsPage from '../../pages/product-details/ProductDetails.page'
import CartPage from '../../pages/cart/Cart.page'
import InvestorsPage from '../../pages/investors/Investors.page'
import ToolsOrderPage from '../../pages/dashboard/views/tool-orders/ToolsOrder.page'
import DashboardCartView from '../../pages/dashboard/views/dashboard-cart/DashboardCart.view'
import ManageToolOrdersDashboardPage from '../../pages/dashboard/views/manage-tool-orders/ToolOrders.dashboard.page'
import InvestorsDPage from '../../pages/dashboard/views/investors/InvestorsD.page'
import OnboardingPage from '../../pages/new-auth/Onboarding.page'
import NewDashboardPage from '../../pages/new-dashboard/NewDashboard.page'
import NewManageProductsView from '../../pages/new-dashboard/views/manage-products/NewManageProducts.view'
import NewFarmingToolsView from '../../pages/new-dashboard/views/farming-tools/NewFarmingTools.view'
import NewOrdersView from '../../pages/new-dashboard/views/orders/NewOrders.view'
import NewPersonalInfoView from '../../pages/new-dashboard/views/personal-info/NewPersonalInfo.view'
import ToolCartView from '../../pages/new-dashboard/views/tool-cart/ToolCart.view'
import MyOrdersViews from '../../pages/new-dashboard/views/my-orders/MyOrders.views'
import ToolMakersView from '../../pages/new-dashboard/views/tool-makers/ToolMakers.view'
import FarmersView from '../../pages/new-dashboard/views/farmers/Farmers.view'
import ProjectsView from '../../pages/new-dashboard/views/projects/Projects.view'
import NewProjectView from '../../pages/new-dashboard/views/new-project/NewProject.view'

export const appRoutes = [
  {
    path: appConstants.routes.home,
    component: HomePage,
  },
  {
    path: appConstants.routes.onboardingPage,
    component: OnboardingPage,
  },
  {
    path: appConstants.routes.stores,
    component: FarmstorePage,
  },
  {
    path: appConstants.routes.storeDetails,
    component: StoreDetailsPage,
  },
  {
    path: appConstants.routes.investorsView,
    component: InvestorsPage,
  },
  {
    path: appConstants.routes.cart,
    component: CartPage,
  },
  {
    path: appConstants.routes.productDetails,
    component: ProductDetailsPage,
  },
  {
    path: appConstants.routes.auth.login,
    component: LoginPage,
  },
  {
    path: appConstants.routes.auth.register,
    component: RegisterPage,
  },
  {
    path: appConstants.routes.newDashboard.index,
    component: NewDashboardPage,
    views: [
      {
        index: true,
        path: appConstants.routes.newDashboard.index,
        component: NewManageProductsView,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.manageTools,
        component: NewFarmingToolsView,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.orders,
        component: NewOrdersView,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.personalInfo,
        component: NewPersonalInfoView,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.cart,
        component: ToolCartView,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.myOrders,
        component: MyOrdersViews,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.farmers,
        component: FarmersView,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.toolMakers,
        component: ToolMakersView,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.createNewProject,
        component: StoreFrontDashboardPage,
      },
      {
        index: false,
        path: appConstants.routes.newDashboard.projects,
        component: ProjectsView,
      },
    ],
  },
  {
    path: appConstants.routes.auth.forgotPassword,
    component: ForgotPasswordPage,
  },
  {
    path: appConstants.routes.auth.changePassword,
    component: ChangePasswordPage,
  },
  {
    path: appConstants.routes.auth.verifyEmail,
    component: VerifyEmailAuthPage,
  },
  {
    path: appConstants.routes.dashboard,
    component: DashboardPage,
    views: [
      {
        index: false,
        path: appConstants.routes.dViews.profile,
        component: ProfilePashboardPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.settings,
        component: SettingsPashboardPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.investors,
        component: InvestorsDPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.manageProject,
        component: StoreFrontDashboardPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.analysis,
        component: AnalysisDashboardPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.orders,
        component: OrdersDashboardPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.manageToolOrder,
        component: ManageToolOrdersDashboardPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.toolOrders,
        component: ToolsOrderPage,
      },
      {
        index: false,
        path: appConstants.routes.dViews.cart,
        component: DashboardCartView,
      },
      {
        index: true,
        component: IndexDashboardPage,
      },
    ],
  },
]
