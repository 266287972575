import React from 'react'
import { IconType } from '../../types/icon.type'

const FilledStarIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 20.0889L22.5955 24.8889L20.0711 17.1378L26.6666 12.4444H18.5778L16 4.44444L13.4222 12.4444H5.33331L11.9289 17.1378L9.40442 24.8889L16 20.0889Z'
        fill={color}
      />
    </svg>
  )
}

export default FilledStarIcon
