import React from 'react'

import globals from '../../../global.module.css'
import styles from './notification.module.css'

import { useAppSelector } from '../../../lib/hooks'
import {
  NotificationStatus,
  NotificationTitle,
} from '../../../app/features/notification/notification.slice'
import { IconsRepository } from '../../../repository/icons/icons.repository'

const NotificationMolecule = () => {
  const notification = useAppSelector((state) => state.notificationSlice)

  return (
    <div
      className={`${globals['gap-12']} ${styles.notification} ${
        notification.title === NotificationTitle.ERROR ? styles.error : ''
      } ${notification.status === NotificationStatus.ACTIVE ? styles.show : ''}`}
    >
      <div className={`${styles.head} ${globals.flex} ${globals['a-center']} ${globals['gap-16']}`}>
        <IconsRepository.AnnouncementIcon
          size={32}
          color={
            notification.title === NotificationTitle.ERROR
              ? 'var(--AGROX-RED-COLOR)'
              : 'var(--AGROX-GREEN-COLOR)'
          }
        />
        <h2>{notification.title === NotificationTitle.SUCCESS ? 'Success' : 'Error'}</h2>
      </div>

      <p>{notification.message}</p>
    </div>
  )
}

export default NotificationMolecule
