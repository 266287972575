import React from 'react'

import globals from '../../../../global.module.css'
import styles from './settings.module.css'

const SettingsPashboardPage = () => {
  return <div className={`${globals['p-desktop']} ${styles.settings}`}></div>
}

export default SettingsPashboardPage
