import React from 'react'

import globals from '../../../global.module.css'
import styles from './tabselection.module.css'

type TabsSelectionProps = {
  tabs: string[]
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  icons?: JSX.Element[]
  counts?: number[]
  colors?: string[]
}

const TabsSelectionAtom: React.FC<TabsSelectionProps> = ({
  setActiveIndex,
  icons,
  activeIndex,
  tabs,
  counts,
  colors,
}) => {
  return (
    <div className={`${styles.tabs} ${globals.flex} ${globals['center-items']}`}>
      {tabs.length > 0 &&
        tabs.map((tab, index) => {
          return (
            <button
              className={`${styles.tab} ${globals.flex} ${globals['center-items']} ${
                globals['gap-12']
              } ${activeIndex === index ? styles.active : ''}`}
              onClick={() => {
                setActiveIndex(index)
              }}
              style={{
                background: colors && activeIndex === index ? colors[activeIndex] : '',
              }}
              key={index}
            >
              {icons !== undefined ? icons[index] : ''} {tab}
              {counts !== undefined && (
                <span
                  style={{
                    color: colors && activeIndex === index ? colors[activeIndex] : '',
                  }}
                  className={`${globals.flex} ${globals['center-items']}`}
                >
                  {counts[index]}
                </span>
              )}
            </button>
          )
        })}
    </div>
  )
}

export default TabsSelectionAtom
