import React from 'react'
import { IconType } from '../../types/icon.type'

const HalfStarIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M29.3334 12.32L19.7467 11.4933L16 2.66667L12.2534 11.5067L2.66669 12.32L9.94669 18.6267L7.76002 28L16 23.0267L24.24 28L22.0667 18.6267L29.3334 12.32ZM16 20.5333V8.13334L18.28 13.52L24.12 14.0267L19.6934 17.8667L21.0267 23.5733L16 20.5333Z'
        fill={color}
      />
    </svg>
  )
}

export default HalfStarIcon
