import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './authtemplate.module.css'

import ButtonAtom from '../../atoms/button/Button.atom'
import {
  appConstants,
  buttonTypes,
  iconDirection,
  inputState,
} from '../../../app/constants/constants.app'
import LabeledInputMolecule from '../../molecules/labeled-input/LabeledInput.molecule'
import { IconsRepository } from '../../../repository/icons/icons.repository'
import UseForm from '../../../lib/hooks/use-form'
import { validateEmail, validatePassword } from '../../../lib/utils'
import useWindowSize from '../../../lib/hooks/use-window-soze'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { loginUserThunk } from '../../../app/features/auth/thunks/auth.thunk'
import { ApiRequestStatus } from '../../../types/api.type'
import { RootState } from '../../../app/store/store'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../app/features/notification/notification.slice'

const LoginTemplate = () => {
  const [trigger, setTrigger] = useState(false)
  const { width } = useWindowSize()
  const dispatch = useAppDispatch()

  const authState = useAppSelector((state: RootState) => state.loginUserSlice)

  const { form, updateForm } = UseForm(
    {
      email: '',
      password: '',
    },
    setTrigger,
    trigger,
  )

  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(loginUserThunk(form))
  }

  useEffect(() => {
    if (authState.requestResponse.status === ApiRequestStatus.FULFILLED) {
      dispatch(
        triggerNotification({
          message: 'Login successful',
          title: NotificationTitle.SUCCESS,
        }),
      )
    } else {
      if (authState.requestResponse.status === ApiRequestStatus.REJECTED) {
        dispatch(
          triggerNotification({
            message: authState.requestResponse.error?.message as string,
            title: NotificationTitle.ERROR,
          }),
        )
      }
    }
  }, [authState.requestResponse])

  return (
    <div
      className={`${styles.auth__template} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
    >
      <h2>Welcome Back!</h2>

      <form
        className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['gap-10']}`}
        onSubmit={handleSubmit}
      >
        <LabeledInputMolecule
          label='Email'
          placeholder='getexample@gmail.com'
          onChange={(e) => {
            updateForm('email', e.target.value)
          }}
          state={
            form.email.length <= 0
              ? inputState.normal
              : validateEmail(form.email)
              ? inputState.error
              : inputState.success
          }
          type='text'
          name='email'
          iconDir={[iconDirection.left]}
          iconLeft={<IconsRepository.MailIcon size={width < 1440 ? 20 : 24} />}
        />

        <LabeledInputMolecule
          label='Password'
          placeholder='********'
          onChange={(e) => {
            updateForm('password', e.target.value)
          }}
          state={
            form.password.length <= 0
              ? inputState.normal
              : validatePassword(form.password)
              ? inputState.error
              : inputState.success
          }
          type={showPassword ? 'text' : 'password'}
          name='password'
          iconDir={[iconDirection.left, iconDirection.right]}
          iconLeft={<IconsRepository.LockIcon size={width < 1440 ? 20 : 24} />}
          iconRight={
            showPassword ? (
              <IconsRepository.EyeIcon size={width < 1440 ? 20 : 24} />
            ) : (
              <IconsRepository.StrokeEyeIcon size={width < 1440 ? 20 : 24} />
            )
          }
          iconClick={() => {
            setShowPassword(!showPassword)
          }}
        />

        <div
          className={`${styles.forgot} ${globals.flex} ${globals['a-center']} ${globals['s-b']}`}
        >
          <div></div>
          <Link to={appConstants.routes.auth.forgotPassword}>Forgot Password?</Link>
        </div>

        <div className={styles.btn}>
          <ButtonAtom
            submit='submit'
            fontSize='1.8rem'
            type={buttonTypes.primary}
            btnText={
              authState.requestResponse.status === ApiRequestStatus.PENDING ? 'Loading...' : 'Login'
            }
            fullWidth={true}
            disabled={authState.requestResponse.status === ApiRequestStatus.PENDING}
          />
        </div>

        <div className={styles.ref}>
          <h4>
            New to Agrox ? <Link to={appConstants.routes.auth.register}>Register</Link>
          </h4>
        </div>
      </form>
    </div>
  )
}

export default LoginTemplate
