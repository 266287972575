import React from 'react'

import { IconType } from '../../types/icon.type'

const MailIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.6667 5.33334H5.33335C3.86669 5.33334 2.68002 6.53334 2.68002 8.00001L2.66669 24C2.66669 25.4667 3.86669 26.6667 5.33335 26.6667H26.6667C28.1334 26.6667 29.3334 25.4667 29.3334 24V8.00001C29.3334 6.53334 28.1334 5.33334 26.6667 5.33334ZM26.6667 10.6667L16 17.3333L5.33335 10.6667V8.00001L16 14.6667L26.6667 8.00001V10.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default MailIcon
