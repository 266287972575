import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../../../global.module.css'
import styles from './order.module.css'

import TabsSelectionAtom from '../../../../components/atoms/tabs/TabsSelection.atom'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import OrderListTemplate from '../../../../components/templates/dashboard/order-list/OrderList.template'
import { OrderStatus } from '../../../../components/molecules/order-card/OrderCard.molecule'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { IOrder } from '../../../../types/order-types/order.type'
import Loader from '../../../../components/loader/Loader'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { RootState } from '../../../../app/store/store'
import { AccountTypes } from '../../../../repository/data/accountType'
import { IProduct } from '../../../../types/product-types/product.type'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes } from '../../../../app/constants/constants.app'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { staticSvgs } from '../../../../app/assets/images'

const statusStore = [OrderStatus.PAID, OrderStatus.PENDING, OrderStatus.DELIVERED]

const OrdersDashboardPage = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [orderId, setOrderId] = useState('')
  const dispatch = useAppDispatch()

  const [orderStatus, setOrderStatus] = useState<OrderStatus>(statusStore[activeTab])
  const [orders, setOrders] = useState<IOrder[]>([])
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)

  const selectedOrder = orders.find((order) => order._id === orderId)

  const tabs =
    authState.requestResponse.data?.user.accountType === AccountTypes.FARMER
      ? ['Pending Delivery', 'Delivered', 'Collected']
      : ['Pending Delivery', 'Delivered', 'Received']

  const [loading, setLoading] = useState(false)

  const fetchOrders = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ orders: IOrder[] }>(`/orders/${authState.requestResponse.data?.user._id}/{null}`)
        .then((response) => {
          setOrders(response.data.orders)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const filterOrders = (idx: number): IOrder[] => {
    if (idx === 0) {
      return orders.filter((order) => order.delivered === false && order.collected === false)
    }

    if (idx === 1) {
      return orders.filter((order) => order.delivered === true && order.collected === false)
    }

    return orders.filter((order) => order.delivered === true && order.collected === true)
  }

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  useEffect(() => {
    setOrderStatus(statusStore[activeTab])
  }, [activeTab])

  const controlOrder = async (action: string) => {
    setLoading(true)
    try {
      await publicApiRequest()
        .patch(`/orders/${action === 'delivery' ? 'delivered' : 'collected'}/${orderId}`)
        .then((response) => {
          console.log({ response })
          dispatch(
            triggerNotification({
              message: 'Successfully marked as delivered.',
              title: NotificationTitle.SUCCESS,
            }),
          )

          fetchOrders()
          setOrderId('')
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (orders.length > 0) {
      const filteredOrders = orders.filter((order) => order.docModel !== 'Tool')

      setOrders(filteredOrders)
    }
  }, [orders])

  return (
    <div
      className={`${styles.orders} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${globals['p-desktop']}`}
    >
      <div className={styles.head}>
        <div>
          <span>
            <span onClick={() => setOrderId('')}>Orders</span> &gt;
            {orderId.length > 0 ? ` ${orderId}` : ''}
          </span>
        </div>
        <h2>Manage Orders</h2>
        {authState.requestResponse.data?.user.accountType === AccountTypes.FARMER ? (
          <h3>
            Total Sales:{' '}
            <span>
              {authState.requestResponse.data.user.totalSales
                ? authState.requestResponse.data.user.totalSales
                : 0}{' '}
              FCFA
            </span>
          </h3>
        ) : (
          ''
        )}
      </div>

      {orderId.length === 0 ? (
        <div className={styles.tabs}>
          <TabsSelectionAtom
            icons={[
              <>
                <IconsRepository.BellIcon size={24} />
              </>,
              <>
                <IconsRepository.AnnouncementIcon size={24} />
              </>,
              <>
                <IconsRepository.CreateNewFolderIcon size={24} />
              </>,
            ]}
            counts={[filterOrders(0).length, filterOrders(1).length, filterOrders(2).length]}
            activeIndex={activeTab}
            setActiveIndex={setActiveTab}
            tabs={tabs}
            colors={[
              'var(--AGROX-GREY-COLOR)',
              'var(--AGROX-RED-COLOR)',
              'var(--AGROX-LIGHT-GREEN-COLOR)',
            ]}
          />
        </div>
      ) : (
        ''
      )}

      {loading ? <Loader /> : ''}

      {!loading && filterOrders(activeTab).length === 0 ? (
        <div
          className={`${globals.flex} ${globals['gap-16']} ${globals['flex-column']} ${styles.no__content} ${globals['center-items']} ${globals['full-width']}`}
        >
          <div>
            <img width={150} height={150} src={staticSvgs.dashboard.EmptySearch} alt='no content' />
          </div>
          <div>
            <h3>No orders here</h3>
            <h4>Check out the other tabs</h4>
          </div>
        </div>
      ) : (
        ''
      )}

      {!loading && orderId.length === 0 && orders && orders.length > 0 ? (
        <div>
          <OrderListTemplate
            setOrderId={setOrderId}
            accountType={authState.requestResponse.data?.user.accountType}
            orders={filterOrders(activeTab)}
            status={orderStatus}
          />
        </div>
      ) : (
        ''
      )}

      {!loading &&
      orderId.length > 0 &&
      authState.requestResponse.data?.user.accountType === AccountTypes.FARMER &&
      activeTab === 0 ? (
        <div>
          <ButtonAtom
            disabled={loading}
            onClick={() => {
              controlOrder('delivery')
            }}
            fullWidth={false}
            btnText={loading ? 'Loading...' : 'Mark as Delivered'}
            type={buttonTypes.primary}
          />
        </div>
      ) : (
        ''
      )}

      {!loading &&
      orderId.length > 0 &&
      authState.requestResponse.data?.user.accountType === AccountTypes.CUSTOMER &&
      activeTab === 1 ? (
        <div>
          <ButtonAtom
            disabled={loading}
            onClick={() => {
              controlOrder('')
            }}
            fullWidth={false}
            btnText={loading ? 'Loading...' : 'Mark as Collected'}
            type={buttonTypes.primary}
          />
        </div>
      ) : (
        ''
      )}

      {!loading && orderId.length > 0 ? (
        <div className={styles.products}>
          {selectedOrder?.productIds !== undefined &&
            selectedOrder?.productIds.length > 0 &&
            (selectedOrder.productIds as IProduct[]).map((product, index) => {
              return (
                <article key={index} className={styles.card}>
                  <div className={styles.image}>
                    <img src={product.imageUrl} alt='p' />
                  </div>
                  <div className={styles.body}>
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>
                    <p style={{ fontSize: '1.6rem', marginBottom: '5px' }}>
                      <span>Price: {product.price}</span> per <span>{product.unit}</span>
                    </p>
                    <p style={{ fontSize: '1.6rem' }}>
                      <span>Quantity Left: {product.quantity}</span>
                    </p>
                    <p style={{ fontSize: '1.6rem', marginBottom: 0 }}>
                      <span>
                        Quantity Ordered: {selectedOrder.productQuantities[index]} {product.unit}
                      </span>
                    </p>
                  </div>
                </article>
              )
            })}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default OrdersDashboardPage
