import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../../../global.module.css'
import styles from './profile.module.css'

import UseForm from '../../../../lib/hooks/use-form'
import LabeledInputMolecule from '../../../../components/molecules/labeled-input/LabeledInput.molecule'
import { buttonTypes, inputState } from '../../../../app/constants/constants.app'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { User } from '../../../../types/user-types/user.type'
import Loader from '../../../../components/loader/Loader'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import SelectAtom from '../../../../components/atoms/input/Select.atom'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { useAppDispatch } from '../../../../lib/hooks'
import { LoginResponseType } from '../../../../types/auth.type'
import { LocalStorageService } from '../../../../service/localstorage.service'
import { updateAuthStateData } from '../../../../app/features/auth/slices/login-user.slice'

const localStorage = new LocalStorageService()

const ProfilePashboardPage = () => {
  const [trigger, setTrigger] = useState(false)
  const [loading, setLoading] = useState(false)
  const [myProfile, setMyPofile] = useState<User>({} as User)
  const [imageSelected, setImageSelected] = useState<File>()
  const dispatch = useAppDispatch()

  const { form, updateForm } = UseForm(
    {
      image: myProfile?.profileURL,
      accountType: myProfile?.accountType,
      email: myProfile?.email ? myProfile.email : '',
      firstName: myProfile?.firstName,
      lastName: myProfile?.lastName,
      contact: myProfile.contact,
      gender: myProfile.gender,
    },
    setTrigger,
    trigger,
  )

  const updateProfile = async () => {
    setLoading(true)
    const body = new FormData()

    body.append('file', imageSelected === undefined ? '' : (imageSelected as File))
    body.append('firstName', form.firstName)
    body.append('lastName', form.lastName)
    body.append('email', `${form.email}`)
    body.append('contact', `${form.contact}`)
    body.append('gender', form.gender === 'NOT SELECTED' ? 'NOT SELECTED' : form.gender)

    try {
      await publicApiRequest()
        .patch('/auth/update-profile', body)
        .then((_response) => {
          dispatch(
            triggerNotification({
              message: 'Profile successfully updated',
              title: NotificationTitle.SUCCESS,
            }),
          )
          setImageSelected(undefined)
          fetchMyProfile()
        })
    } catch (e) {
      dispatch(
        triggerNotification({
          message: 'An error occured, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const fetchMyProfile = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ user: User }>('/auth/my-profile')
        .then((response) => {
          const user: LoginResponseType = JSON.parse(localStorage.getLoginData() as string)
          localStorage.storeLoginData({
            user: response.data.user,
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
          })
          dispatch(
            updateAuthStateData({
              data: {
                user: response.data.user,
                accessToken: user.accessToken,
                refreshToken: user.refreshToken,
              },
            }),
          )
          setMyPofile(response.data.user)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchMyProfile()
  }, [fetchMyProfile])

  useEffect(() => {
    updateForm(
      ['firstName', 'lastName', 'email', 'gender', 'contact', 'image', 'accountType'],
      [
        myProfile?.firstName,
        myProfile?.lastName,
        myProfile?.email,
        myProfile.gender,
        myProfile?.contact,
        myProfile?.profileURL,
        myProfile?.accountType,
      ],
    )
  }, [myProfile])

  return (
    <div className={`${styles.profile} ${globals['p-desktop']}`}>
      <div className={styles.head}>
        <h2>Profile Management</h2>
      </div>

      {loading ? <Loader /> : ''}

      {!loading ? (
        <div className={`${styles.form} ${globals.flex} ${globals['gap-24']}`}>
          <div
            className={`${styles.image__wrapper} ${globals.flex} ${globals['flex-column']} ${globals['center-items']}`}
          >
            <div className={`${styles.image}`}>
              <img
                width={200}
                height={200}
                src={
                  imageSelected !== undefined
                    ? URL.createObjectURL(imageSelected as File)
                    : (form?.image as string)
                }
                alt={form?.firstName}
              />
            </div>
            <div className={styles.upload}>
              <ButtonAtom
                onClick={() => {
                  setImageSelected(undefined)
                }}
                bgColor='rgba(0, 0, 0, 0.1)'
                fontWeight={400}
                color='#000'
                btnText={imageSelected !== undefined ? 'Clear Selection' : 'Upload New Picture'}
                type={buttonTypes.primary}
              />
              {imageSelected === undefined ? (
                <input
                  type='file'
                  onChange={(e) => setImageSelected(e.target.files ? e.target.files[0] : undefined)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div
            className={`${styles.data} ${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
          >
            <LabeledInputMolecule
              label='First Name'
              placeholder='First Name'
              onChange={(e) => {
                updateForm('firstName', e.target.value)
              }}
              state={inputState.normal}
              type='text'
              value={form?.firstName}
              name='firstName'
            />
            <LabeledInputMolecule
              label='Last Name'
              placeholder='Last Name'
              onChange={(e) => {
                updateForm('lastName', e.target.value)
              }}
              state={inputState.normal}
              type='text'
              value={form?.lastName}
              name='lastName'
            />
            <SelectAtom
              onSelect={(name: string) => {
                updateForm('gender', name)
              }}
              placeholder='Select Gender'
              state={inputState.normal}
              list={['MALE', 'FEMALE']}
              type='text'
              value={form.gender}
              name='gender'
            />

            <LabeledInputMolecule
              label='Email'
              placeholder='Email'
              onChange={(e) => {
                updateForm('email', e.target.value)
              }}
              state={inputState.normal}
              type='text'
              value={form?.email}
              name='email'
            />
            <LabeledInputMolecule
              label='Phone Number'
              placeholder='Phone Number'
              onChange={(e) => {
                updateForm('contact', e.target.value)
              }}
              state={inputState.normal}
              type='text'
              value={form?.contact}
              name='contact'
            />

            <div className={`${styles.update} ${globals.flex}`}>
              <div></div>
              <ButtonAtom
                onClick={() => {
                  updateProfile()
                }}
                btnText={loading ? 'Loading...' : 'Update Profile Information'}
                type={buttonTypes.primary}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ProfilePashboardPage
