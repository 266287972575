import React from 'react'

import globals from '../../../global.module.css'
import styles from './modal.module.css'

import ButtonAtom from '../../atoms/button/Button.atom'
import { buttonTypes } from '../../../app/constants/constants.app'

type Props = {
  children: React.ReactNode
  close: () => void
}

const ModalMolecule = ({ children, close }: Props) => {
  return (
    <div
      className={`${styles.modal} ${globals.flex} ${globals['center-items']} ${globals['full-width']}`}
    >
      <div className={styles.close}>
        <ButtonAtom
          fontSize='1.6rem'
          type={buttonTypes.primary}
          btnText={'Close'}
          fullWidth={false}
          borderRadius='4px'
          onClick={() => {
            close()
          }}
          bgColor='var(--AGROX-RED-COLOR)'
        />
      </div>
      <div className={styles.child}>{children}</div>
    </div>
  )
}

export default ModalMolecule
