import React from 'react'

import { IconType } from '../../types/icon.type'

const HambugerMenuIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M3 7H21M3 12H21M3 17H21' stroke={color} strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}

export default HambugerMenuIcon
