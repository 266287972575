import React, { useState, useEffect, useCallback } from 'react'

import globals from '../../../../global.module.css'
import styles from '../view.module.css'

import { IconsRepository } from '../../../../repository/icons/icons.repository'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes, inputState } from '../../../../app/constants/constants.app'
import SelectAtom from '../../../../components/atoms/input/Select.atom'
import TabComponentMolecule from '../../../../components/molecules/tab-component/TabComponent.molecule'
import PCardMolecule from '../cards/p-card/PCard.molecule'
import ModalMolecule from '../../../../components/molecules/modal/Modal.molecule'
import PDetailsMolecule from '../cards/p-details/PDetails.molecule'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { IProduct, ProductsResponse } from '../../../../types/product-types/product.type'
import { CategoriesResponse, ICategory } from '../../../../types/category-types/category.type'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { RootState } from '../../../../app/store/store'
import { AccountTypes } from '../../../../repository/data/accountType'
import { ITool } from '../../../../types/tool-types/tool.types'
import TabsSelectionAtom from '../../../../components/atoms/tabs/TabsSelection.atom'
import { IOrder } from '../../../../types/order-types/order.type'
import Accordion from '../../../../components/organisms/accordion/Accordion.organism'

export enum ViewState {
  NORMAL,
  EDITING,
}

const NewManageProductsView = () => {
  const [formData, setFormData] = useState({
    productName: '',
    description: '',
    quantity: '',
    unitOfMeasurement: '',
    price: '',
    categoryId: '',
    image: '',
  })
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)
  const user = authState.requestResponse.data?.user
  const isFarmer = user?.accountType === AccountTypes.FARMER
  const [orders, setOrders] = useState<IOrder[]>([])
  const [openIndex, setOpenIndex] = useState(0)
  const [activeTab, setActiveTab] = useState(0)

  const tabs = ['Pending Delivery', 'Delivered', 'Received']

  const [imageSelected, setImageSelected] = useState<File>()
  const [selectedCat, setSelectedCat] = useState('')
  const [loading, setLoading] = useState(false)
  const [marking, setMarking] = useState(false)
  const [adding, setAdding] = useState(false)

  const [selectedId, setSelectedId] = useState('')
  const [viewState, setViewState] = useState<ViewState>(ViewState.NORMAL)
  const dispatch = useAppDispatch()
  const [myProducts, setMyProducts] = useState<IProduct[]>([])
  const [cats, setCats] = useState<ICategory[]>([])
  const [tools, setTools] = useState<ITool[]>([])

  const { productName, description, quantity, categoryId, unitOfMeasurement, price } = formData

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    setAdding(true)
    const body = new FormData()

    body.append('file', imageSelected === undefined ? '' : (imageSelected as File))
    body.append('name', formData.productName)
    body.append('description', description)
    body.append('price', `${price}`)
    body.append('quantity', `${quantity}`)
    body.append('unit', unitOfMeasurement)
    body.append('categoryId', categoryId)

    try {
      if (user?.accountType === AccountTypes.FARMER) {
        await publicApiRequest()
          .post<ProductsResponse>('/products/add-new-product', body)
          .then((_response) => {
            dispatch(
              triggerNotification({
                message: 'Product successfully created',
                title: NotificationTitle.SUCCESS,
              }),
            )
            fetchProducts()
            setFormData({
              productName: '',
              description: '',
              quantity: '',
              unitOfMeasurement: '',
              price: '',
              categoryId: '',
              image: '',
            })
          })
      } else {
        await publicApiRequest()
          .post('/tools/add-new-tool', body)
          .then((_response) => {
            console.log({ _response })
            fetchProducts()
            dispatch(
              triggerNotification({
                message: 'Tool successfully created',
                title: NotificationTitle.SUCCESS,
              }),
            )
            setFormData({
              productName: '',
              description: '',
              quantity: '',
              unitOfMeasurement: '',
              price: '',
              categoryId: '',
              image: '',
            })
          })
      }
    } catch (e) {
      dispatch(
        triggerNotification({
          message: 'An error occured, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setAdding(false)
    }
  }

  const fetchProducts = useCallback(async () => {
    setLoading(true)

    try {
      if (user?.accountType === AccountTypes.FARMER) {
        await Promise.all([
          await publicApiRequest().get<ProductsResponse>('/products/my-products'),
          await publicApiRequest().get<CategoriesResponse>('/categories'),
        ]).then((response) => {
          setMyProducts(response[0].data.products)
          setCats(response[1].data.categories)
        })
      } else {
        await Promise.all([await publicApiRequest().get('/tools/my-tools')]).then((response) => {
          setTools(response[0].data.tools)
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const editProductData = useCallback(async () => {
    setLoading(true)

    const body = {
      name: formData.productName,
      quantity: formData.quantity,
      price: formData.price,
      unit: formData.unitOfMeasurement,
      description: formData.description,
    }

    try {
      await publicApiRequest()
        .put<ProductsResponse>(
          `/products/${myProducts.find((p) => p._id === selectedId)?._id}`,
          body,
        )
        .then((_response) => {
          dispatch(
            triggerNotification({
              message: 'Product data successfully edited.',
              title: NotificationTitle.SUCCESS,
            }),
          )
          fetchProducts()
        })
    } catch (e) {
      console.log({ e })
      dispatch(
        triggerNotification({
          message: 'An error occured, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }, [])

  const editProductImage = async () => {
    setLoading(true)

    try {
      const body = new FormData()
      body.append('file', imageSelected as File)

      await publicApiRequest()
        .patch(`/products/${myProducts.find((p) => p._id === selectedId)?._id}`, body)
        .then(() => {
          dispatch(
            triggerNotification({
              message: 'Product image updated.',
              title: NotificationTitle.SUCCESS,
            }),
          )
        })
    } catch (e) {
      console.log(e)
      dispatch(
        triggerNotification({
          message: 'An error occured updating image data.',
          title: NotificationTitle.SUCCESS,
        }),
      )
    } finally {
      fetchProducts()
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user?.accountType !== AccountTypes.CUSTOMER) {
      fetchProducts()
    }
  }, [fetchProducts])

  const extractCatNames = () => {
    const temp: string[] = []
    if (cats.length > 0) {
      cats.map((cat) => {
        temp.push(cat.name)
      })

      return temp
    } else return []
  }

  const getCategory = () => {
    const p = myProducts.find((p) => p._id === selectedId)
    const cat = cats.find((item) => item._id === p?.categoryId)
    return cat ? cat?.name : ''
  }

  useEffect(() => {
    if (selectedId.length > 0) {
      const selected = isFarmer
        ? myProducts.find((p) => p._id === selectedId)
        : tools.find((p) => p._id === selectedId)

      if (selected) {
        setFormData({
          productName: selected.name,
          description: selected.description,
          quantity: `${selected.quantity}`,
          unitOfMeasurement: '',
          price: `${selected.price}`,
          categoryId: '',
          image: selected.imageUrl as string,
        })
      }
    } else {
      setFormData({
        productName: '',
        description: '',
        quantity: '',
        unitOfMeasurement: '',
        price: '',
        categoryId: '',
        image: '',
      })
    }
  }, [selectedId, viewState])

  const fetchOrders = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ orders: IOrder[] }>(`/orders/${authState.requestResponse.data?.user._id}/{null}`)
        .then((response) => {
          console.log('====================================')
          console.log({ response })
          console.log('====================================')

          setOrders(response.data.orders)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const toggleAccordion = (index: number) => {
    setOpenIndex(index === openIndex ? -1 : index)
  }

  const filterOrders = (idx: number): IOrder[] => {
    if (idx === 0) {
      return orders.filter((order) => order.delivered === false && order.collected === false)
    }

    if (idx === 1) {
      return orders.filter((order) => order.delivered === true && order.collected === false)
    }

    return orders.filter((order) => order.delivered === true && order.collected === true)
  }

  const controlOrder = async (action: string, orderId: string) => {
    setMarking(true)
    try {
      await publicApiRequest()
        .patch(`/orders/${action === 'delivery' ? 'delivered' : 'collected'}/${orderId}`)
        .then((response) => {
          console.log({ response })
          fetchOrders()

          dispatch(
            triggerNotification({
              message: 'Successfully marked as collected.',
              title: NotificationTitle.SUCCESS,
            }),
          )
        })
    } catch (e) {
      console.log(e)
    } finally {
      setMarking(false)
    }
  }

  useEffect(() => {
    if (authState.requestResponse.data?.user?.accountType === AccountTypes.CUSTOMER) {
      fetchOrders()
    }
  }, [fetchOrders])

  useEffect(() => {
    if (orders.length > 0) {
      const filteredOrders = orders.filter((order) => order.docModel !== 'Tool')

      setOrders(filteredOrders)
    }
  }, [orders])

  return (
    <div className={`${styles.view}`}>
      {user?.accountType === AccountTypes.CUSTOMER ? <h2>Manage Orders</h2> : ''}
      <div className={globals['full-width']}>
        {user?.accountType === AccountTypes.CUSTOMER ? (
          <>
            <div className={`${styles.grid}`}>
              {loading &&
                Array(8)
                  .fill(0)
                  .map((_product, index) => {
                    return (
                      <PCardMolecule
                        loading={true}
                        image=''
                        name=''
                        description=''
                        price=''
                        unit=''
                        key={index}
                      />
                    )
                  })}
            </div>
            <div
              style={{
                marginBottom: '20px',
              }}
              className={styles.tabs}
            >
              <TabsSelectionAtom
                icons={[
                  <>
                    <IconsRepository.BellIcon size={24} />
                  </>,
                  <>
                    <IconsRepository.AnnouncementIcon size={24} />
                  </>,
                  <>
                    <IconsRepository.CreateNewFolderIcon size={24} />
                  </>,
                ]}
                counts={[filterOrders(0).length, filterOrders(1).length, filterOrders(2).length]}
                activeIndex={activeTab}
                setActiveIndex={setActiveTab}
                tabs={tabs}
                colors={[
                  'var(--AGROX-GREY-COLOR)',
                  'var(--AGROX-RED-COLOR)',
                  'var(--AGROX-LIGHT-GREEN-COLOR)',
                ]}
              />
            </div>
            <div
              className={`${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
            >
              {filterOrders(activeTab) &&
                filterOrders(activeTab).length > 0 &&
                filterOrders(activeTab).map((order, index) => {
                  return (
                    <Accordion
                      key={order._id + index}
                      index={index}
                      openIndex={openIndex}
                      toggleAccordion={toggleAccordion}
                      title={`Order - #${order._id}`}
                      content={
                        <div
                          style={{
                            padding: '24px 16px',
                          }}
                        >
                          <div className={styles.grid}>
                            {order.productIds &&
                              order.productIds.length > 0 &&
                              order.productIds.map((product, index) => {
                                return (
                                  <PCardMolecule
                                    toolCard={true}
                                    image={(product as IProduct).imageUrl as string}
                                    name={(product as IProduct).name}
                                    orderView={order.productQuantities[index]}
                                    description={(product as IProduct).description}
                                    price={`${(product as IProduct).price}`}
                                    unit={(product as IProduct).unit}
                                    key={index}
                                  />
                                )
                              })}
                          </div>
                          {authState.requestResponse.data?.user?.accountType ===
                            AccountTypes.CUSTOMER && activeTab === 1 ? (
                            <div
                              style={{
                                marginTop: '24px',
                              }}
                            >
                              <ButtonAtom
                                borderRadius='4px'
                                disabled={marking}
                                onClick={() => {
                                  controlOrder('collected', order._id)
                                }}
                                fullWidth={false}
                                btnText={marking ? 'Loading...' : 'Mark as Collected'}
                                type={buttonTypes.primary}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      }
                    />
                  )
                })}
            </div>
          </>
        ) : (
          <TabComponentMolecule
            tab={1}
            forceSwitchTab={viewState === ViewState.EDITING}
            secondary
            listOfTabs={[
              { id: 0, label: 'My Products' },
              { id: 1, label: viewState === ViewState.EDITING ? 'Edit Details' : 'Create New' },
            ]}
          >
            <div
              style={{
                marginTop: '24px',
              }}
              className={`${styles.page__view}`}
            >
              <h2
                style={{
                  fontSize: '1.6rem',
                }}
              >
                Total:{' '}
                {user?.accountType === AccountTypes.FARMER ? myProducts.length : tools.length}
              </h2>
              {selectedId.length > 0 && viewState === ViewState.NORMAL ? (
                <ModalMolecule
                  close={() => {
                    setSelectedId('')
                  }}
                >
                  <PDetailsMolecule
                    hideCartOptions
                    toolCard
                    image={
                      isFarmer
                        ? (myProducts.find((p) => p._id === selectedId)?.imageUrl as string)
                        : (tools.find((p) => p._id === selectedId)?.imageUrl as string)
                    }
                    name={
                      isFarmer
                        ? (myProducts.find((p) => p._id === selectedId)?.name as string)
                        : (tools.find((p) => p._id === selectedId)?.name as string)
                    }
                    description={
                      isFarmer
                        ? (myProducts.find((p) => p._id === selectedId)?.description as string)
                        : (tools.find((p) => p._id === selectedId)?.description as string)
                    }
                    price={
                      isFarmer
                        ? `${myProducts.find((p) => p._id === selectedId)?.price}`
                        : `${tools.find((p) => p._id === selectedId)?.price}`
                    }
                    unit={''}
                    category={getCategory()}
                    setViewState={setViewState}
                    setSelectedId={setSelectedId}
                    ableToEdit={true}
                    quantity={
                      isFarmer
                        ? myProducts.find((p) => p._id === selectedId)?.quantity
                        : tools.find((p) => p._id === selectedId)?.quantity
                    }
                  />
                </ModalMolecule>
              ) : (
                ''
              )}
              <div className={`${styles.grid}`}>
                {loading &&
                  Array(8)
                    .fill(0)
                    .map((_product, index) => {
                      return (
                        <PCardMolecule
                          loading={true}
                          image=''
                          name=''
                          description=''
                          price=''
                          unit=''
                          key={index}
                        />
                      )
                    })}
              </div>
              <div className={`${styles.grid}`}>
                {user?.accountType === AccountTypes.FARMER ? (
                  <>
                    {!loading &&
                      myProducts &&
                      myProducts.length > 0 &&
                      myProducts.map((product, index) => {
                        return (
                          <PCardMolecule
                            toolCard={true}
                            onClick={() => {
                              setSelectedId(product._id)
                              setViewState(ViewState.NORMAL)
                            }}
                            image={product.imageUrl as string}
                            name={product.name}
                            description={product.description}
                            price={`${product.price}`}
                            unit={product.unit}
                            key={index}
                          />
                        )
                      })}
                  </>
                ) : (
                  <>
                    {!loading &&
                      tools &&
                      tools.length > 0 &&
                      tools.map((tool, index) => {
                        return (
                          <PCardMolecule
                            toolCard={true}
                            onClick={() => {
                              setSelectedId(tool._id as string)
                              setViewState(ViewState.NORMAL)
                            }}
                            hideCartOptions
                            image={tool.imageUrl as string}
                            name={tool.name}
                            description={tool.description}
                            price={`${tool.price}`}
                            unit=''
                            key={index}
                          />
                        )
                      })}
                  </>
                )}
              </div>
            </div>
            <div
              style={{
                flexDirection: 'row-reverse',
                marginTop: '24px',
              }}
              className={`${globals.flex} ${globals['full-width']} ${globals['gap-24']}`}
            >
              <div>
                <div className={`${styles.profile}`}>
                  <div className={`${styles.image}`}>
                    <img
                      width={200}
                      height={200}
                      src={
                        imageSelected !== undefined
                          ? URL.createObjectURL(imageSelected as File)
                          : formData.image && formData.image.length > 0
                          ? formData.image
                          : '/images/img2.jpg'
                      }
                      alt={productName}
                    />
                  </div>
                  <input
                    type='file'
                    onChange={(e) =>
                      setImageSelected(e.target.files ? e.target.files[0] : undefined)
                    }
                  />
                </div>
                <div className={styles.foot__note}>
                  <span>
                    <i>Click to change profile picture</i>
                  </span>

                  {viewState === ViewState.EDITING ? (
                    <div
                      style={{
                        marginTop: '16px',
                      }}
                    >
                      <ButtonAtom
                        disabled={loading}
                        btnText={loading ? 'Please wait...' : 'Update Image'}
                        onClick={() => {
                          isFarmer
                            ? editProductImage()
                            : dispatch(
                                triggerNotification({
                                  message:
                                    'This feature is in development. Please try deleting and recreating the tool.',
                                  title: NotificationTitle.ERROR,
                                }),
                              )
                        }}
                        borderRadius='4px'
                        type={buttonTypes.secondary}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div
                className={`${styles.form} ${globals.flex} ${globals['full-width']} ${globals['flex-column']} ${globals['gap-16']}`}
              >
                {viewState === ViewState.EDITING ? (
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                  >
                    <div
                      className={`${globals.flex} ${styles.editing__notice} ${globals['gap-10']}`}
                    >
                      <button
                        onClick={() => {
                          setViewState(ViewState.NORMAL)
                        }}
                      >
                        Reset View
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className={`${globals.flex} ${globals['gap-16']}`}>
                  <div>
                    <span className={`${styles.icon}`}>
                      <IconsRepository.UserIcon size={18} />
                    </span>
                    <input
                      name='productName'
                      value={productName}
                      onChange={onChange}
                      type='text'
                      placeholder='Product Name'
                    />
                  </div>
                </div>
                <div>
                  <textarea
                    name='description'
                    value={description}
                    onChange={onChange}
                    id='description'
                    cols={30}
                    placeholder='Describe the product...'
                    rows={5}
                  ></textarea>
                </div>
                {user && user?.accountType === AccountTypes.FARMER ? (
                  <div>
                    <SelectAtom
                      onSelect={(name: string) => {
                        setSelectedCat(name)
                        cats.map((item) => {
                          if (item.name === name) {
                            setFormData({ ...formData, categoryId: item._id })
                          }
                        })
                      }}
                      placeholder='Select Category'
                      state={inputState.normal}
                      list={extractCatNames()}
                      type='text'
                      value={selectedCat}
                      name='method'
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className={`${globals.flex} ${globals['gap-16']}`}>
                  <div>
                    <span className={`${styles.icon}`}>
                      <IconsRepository.MailIcon size={18} />
                    </span>
                    <input
                      name='quantity'
                      value={quantity}
                      onChange={onChange}
                      type='text'
                      placeholder='Quantity Available'
                    />
                  </div>
                  {user && user?.accountType === AccountTypes.FARMER ? (
                    <div>
                      <span className={`${styles.icon}`}>
                        <IconsRepository.PhoneIcon size={18} />
                      </span>

                      <input
                        name='unitOfMeasurement'
                        value={unitOfMeasurement}
                        onChange={onChange}
                        type='text'
                        placeholder='Unit of Measurement'
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={`${globals.flex} ${globals['gap-16']}`}>
                  <div>
                    <span className={`${styles.icon}`}>
                      <IconsRepository.MailIcon size={18} />
                    </span>
                    <input
                      name='price'
                      value={price}
                      onChange={onChange}
                      type='text'
                      placeholder='Price'
                    />
                  </div>
                </div>
                <ButtonAtom
                  height='50px'
                  type={buttonTypes.secondary}
                  borderRadius='4px'
                  disabled={loading}
                  onClick={() => {
                    if (viewState === ViewState.NORMAL) {
                      handleSubmit()
                    } else {
                      isFarmer
                        ? editProductData()
                        : dispatch(
                            triggerNotification({
                              message:
                                'This feature is in development. Please try deleting and recreating the tool.',
                              title: NotificationTitle.ERROR,
                            }),
                          )
                    }
                  }}
                  btnText={
                    adding || loading
                      ? 'Please wait...'
                      : viewState === ViewState.EDITING
                      ? 'Update Product Data'
                      : 'Create New Product'
                  }
                />
              </div>
            </div>
          </TabComponentMolecule>
        )}
      </div>
    </div>
  )
}

export default NewManageProductsView
