import React from 'react'

import { IconType } from '../../types/icon.type'

const LockIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 10.6667H22.6666V8.00001C22.6666 4.32001 19.68 1.33334 16 1.33334C12.32 1.33334 9.33331 4.32001 9.33331 8.00001V10.6667H7.99998C6.53331 10.6667 5.33331 11.8667 5.33331 13.3333V26.6667C5.33331 28.1333 6.53331 29.3333 7.99998 29.3333H24C25.4666 29.3333 26.6666 28.1333 26.6666 26.6667V13.3333C26.6666 11.8667 25.4666 10.6667 24 10.6667ZM16 22.6667C14.5333 22.6667 13.3333 21.4667 13.3333 20C13.3333 18.5333 14.5333 17.3333 16 17.3333C17.4666 17.3333 18.6666 18.5333 18.6666 20C18.6666 21.4667 17.4666 22.6667 16 22.6667ZM12 10.6667V8.00001C12 5.78668 13.7866 4.00001 16 4.00001C18.2133 4.00001 20 5.78668 20 8.00001V10.6667H12Z'
        fill={color}
      />
    </svg>
  )
}

export default LockIcon
