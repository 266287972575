import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../../../global.module.css'
import styles from './toolmaker.module.css'

import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes, inputState } from '../../../../app/constants/constants.app'
import { staticSvgs } from '../../../../app/assets/images'
import ToolCardMolecule from '../../../../components/molecules/tool-card/ToolCard.molecule'
import { AddToolType, ITool } from '../../../../types/tool-types/tool.types'
import LabeledInputMolecule from '../../../../components/molecules/labeled-input/LabeledInput.molecule'
import UseForm from '../../../../lib/hooks/use-form'
import Loader from '../../../../components/loader/Loader'
import ImageUploadAtom from '../../../../components/atoms/image-upload/ImageUpload.atom'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { useAppDispatch } from '../../../../lib/hooks'

const ToolMakerIndexPage = () => {
  const [isAdding, setIsAdding] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [tools, setTools] = useState<ITool[]>([])
  const [_selectedTool, setSelectedTool] = useState<ITool>({} as ITool)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState<File>()
  const dispatch = useAppDispatch()

  const { form, updateForm } = UseForm<AddToolType>(
    {
      name: '',
      imageUrl: '',
      price: 0,
      description: '',
      quantity: 0,
    },
    setTrigger,
    trigger,
  )

  const createTools = async () => {
    setLoading(true)
    const body = new FormData()

    body.append('name', form.name)
    body.append('file', image as File)
    body.append('description', form.description)
    body.append('price', `${form.price}`)
    body.append('quantity', `${form.quantity}`)

    try {
      await publicApiRequest()
        .post('/tools/add-new-tool', body)
        .then((_response) => {
          console.log({ _response })
          fetchTools()
          dispatch(
            triggerNotification({
              message: 'Tool successfully created',
              title: NotificationTitle.SUCCESS,
            }),
          )

          setIsAdding(false)
        })
    } catch (e) {
      console.log(e)
      dispatch(
        triggerNotification({
          message: 'An error occured!',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const fetchTools = useCallback(async () => {
    setLoading(true)

    try {
      await Promise.all([await publicApiRequest().get('/tools/my-tools')]).then((response) => {
        setTools(response[0].data.tools)
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const deleteTool = useCallback(async (id: string) => {
    setLoading(true)
    try {
      await publicApiRequest()
        .delete(`/tools/${id}`)
        .then((_response) => {
          dispatch(
            triggerNotification({
              message: 'Tool successfully deleted',
              title: NotificationTitle.SUCCESS,
            }),
          )
          fetchTools()
          setIsEditing(false)
        })
    } catch (e) {
      console.log(e)

      dispatch(
        triggerNotification({
          message: 'Error deleting... Please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchTools()
  }, [fetchTools])

  useEffect(() => {
    if (selectedId.length > 0) {
      const tool: ITool = tools.find((tool) => tool._id === selectedId) as ITool
      setSelectedTool(tool)
      updateForm(
        ['name', 'description', 'price', 'quantity', 'imageUrl'],
        [tool.name, tool.description, tool.price, tool.quantity, tool.imageUrl],
      )
    }
  }, [selectedId])

  return (
    <div className={`${globals['full-width']} ${styles.tool__maker}`}>
      <div className={`${globals.flex} ${globals['gap-16']} ${globals['s-b']}`}>
        {isAdding ? <h2>Add New Tool</h2> : isEditing ? <h2>Edit Tool</h2> : <h2>All Tools</h2>}
        <ButtonAtom
          onClick={() => {
            updateForm(['name', 'description', 'price', 'quantity', 'imageUrl'], ['', '', 0, 0, ''])
            setImage({} as File)

            setIsAdding(!isAdding)
            setIsEditing(false)
          }}
          type={buttonTypes.primary}
          btnText={isAdding ? 'Close Form' : 'Add New Tool'}
        />
      </div>

      {loading ? <Loader /> : ''}

      {isAdding || isEditing ? (
        <div className={`${globals.flex} ${globals['center-items']} ${globals['full-width']}`}>
          <div
            className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
          >
            <ImageUploadAtom setImage={setImage} image={image?.name ? image : form.imageUrl} />
            <LabeledInputMolecule
              label='Tool Name'
              placeholder='Cutlass'
              onChange={(e) => {
                updateForm('name', e.target.value)
              }}
              state={inputState.normal}
              value={form.name}
              type='text'
              name='name'
            />
            <div className={styles.text__area}>
              <h3>Description</h3>
              <textarea
                onChange={(e) => {
                  updateForm('description', e.target.value)
                }}
                name='description'
                placeholder='Please describe the tool'
                id='description'
                cols={20}
                value={form.description}
                rows={5}
              ></textarea>
            </div>
            <LabeledInputMolecule
              label='Price'
              placeholder='30000'
              onChange={(e) => {
                updateForm('price', e.target.value)
              }}
              state={inputState.normal}
              value={`${form.price}`}
              type='text'
              name='price'
            />
            <LabeledInputMolecule
              label='Quantity Available'
              placeholder='100'
              onChange={(e) => {
                updateForm('quantity', e.target.value)
              }}
              state={inputState.normal}
              value={`${form.quantity}`}
              type='text'
              name='quantity'
            />

            <ButtonAtom
              onClick={
                isAdding
                  ? createTools
                  : () => {
                      console.log('editing')
                    }
              }
              type={buttonTypes.primary}
              btnText={loading ? 'Loading...' : isAdding ? 'Save Tool' : 'Edit Tool'}
            />
            {isEditing ? (
              <ButtonAtom
                onClick={() => deleteTool(selectedId)}
                type={buttonTypes.primary}
                btnText={loading ? 'Loading' : 'Delete Tool'}
                bgColor='rgba(255, 0, 0, 0.2)'
                color='rgb(255, 0, 0)'
                fontWeight={500}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}

      {!isAdding && !isEditing ? (
        <div>
          {tools.length === 0 ? (
            <div
              className={`${globals.flex} ${globals['gap-16']} ${globals['flex-column']} ${styles.no__content} ${globals['center-items']} ${globals['full-width']}`}
            >
              <img
                width={150}
                height={150}
                src={staticSvgs.dashboard.EmptySearch}
                alt='no content'
              />
              <div>
                <h3>Looks like you still at ground zero</h3>
                <h4>Click on add button above to get started</h4>
              </div>
            </div>
          ) : (
            <div className={`${styles.grid}`}>
              {tools.length > 0 &&
                tools.map((tool, index) => {
                  return (
                    <div
                      onClick={() => {
                        setIsAdding(false)
                        setIsEditing(true)
                        setSelectedId(tool._id as string)
                      }}
                      key={index}
                    >
                      <ToolCardMolecule tool={tool} />
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ToolMakerIndexPage
