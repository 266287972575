import React, { useState } from 'react'
import Fade from 'react-reveal'

import globals from '../../../../global.module.css'
import styles from './features.module.css'

import TabsSelectionAtom from '../../../atoms/tabs/TabsSelection.atom'
import { IconsRepository } from '../../../../repository/icons/icons.repository'

const FeaturesTemplate = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section
      className={`${styles.features} ${globals['gap-24']} ${globals.flex} ${globals['center-items']} ${globals['flex-column']} ${globals['p-desktop']}`}
    >
      <div className={styles.head}>
        <Fade up>
          <h2>Global Agricultural Network Chain In One Place</h2>
        </Fade>
      </div>

      <div
        className={`${styles.accounts__display} ${globals['flex-column']} ${globals.flex} ${globals['center-items']}`}
      >
        <div className={styles.tabs}>
          <TabsSelectionAtom
            icons={[
              <>
                <IconsRepository.BellIcon size={24} />
              </>,
              <>
                <IconsRepository.AnnouncementIcon size={24} />
              </>,
              <>
                <IconsRepository.CreateNewFolderIcon size={24} />
              </>,
              <>
                <IconsRepository.CreateNewFolderIcon size={24} />
              </>,
              <>
                <IconsRepository.CreateNewFolderIcon size={24} />
              </>,
            ]}
            tabs={[
              'Farmers',
              'Investors',
              'Manufacturing Companies',
              'Machinery (Farming Tools)',
              'Customers',
            ]}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>

        {activeIndex === 0 ? (
          <div
            className={`${styles.showcase} ${globals['center-items']} ${globals.flex} ${globals['gap-24']}`}
          >
            <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Access to Agricultural Inputs</h2>
                  <p>
                    The platform should provide farmers with easy access to a wide range of
                    agricultural inputs such as seeds, fertilizers, pesticides, and machinery.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Market Information and Price Analysis</h2>
                  <p>
                    The platform should offer real-time market information, including crop prices,
                    demand trends, and market forecasts.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Networking and Collaboration:</h2>
                  <p>
                    The platform should facilitate networking and collaboration among farmers,
                    allowing them to connect with other stakeholders in the agricultural value
                    chain, such as suppliers, buyers, and agricultural organizations.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Fade up>
                <img src='/images/pic.webp' width={100} height={100} alt='pic' />
              </Fade>
            </div>
          </div>
        ) : (
          ''
        )}

        {activeIndex === 1 ? (
          <div
            className={`${styles.showcase} ${globals['center-items']} ${globals.flex} ${globals['gap-24']}`}
          >
            <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Market Insights</h2>
                  <p>
                    The platform can provide investors with valuable market insights, including
                    trends, demand-supply dynamics, and price fluctuations. This information helps
                    investors make informed decisions about investing in specific agricultural
                    sectors or commodities.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Investment Opportunities: </h2>
                  <p>
                    The platform can highlight investment opportunities in the agricultural sector,
                    such as emerging technologies, innovative farming practices, or value-added
                    processing ventures. Investors can identify promising projects or startups to
                    invest in, potentially generating attractive returns.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Networking and Collaboration:</h2>
                  <p>
                    The platform should facilitate networking and collaboration among farmers,
                    allowing them to connect with other stakeholders in the agricultural value
                    chain, such as suppliers, buyers, and agricultural organizations.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Fade up>
                <img src='/images/pic.webp' width={100} height={100} alt='pic' />
              </Fade>
            </div>
          </div>
        ) : (
          ''
        )}

        {activeIndex === 2 ? (
          <div
            className={`${styles.showcase} ${globals['center-items']} ${globals.flex} ${globals['gap-24']}`}
          >
            <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Quality Assurance:</h2>
                  <p>
                    The platform can facilitate quality control and assurance by implementing
                    standards and certifications for the farming produce used as raw material.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Market Intelligence: </h2>
                  <p>
                    The platform can offer market intelligence and insights on consumer trends,
                    preferences, and demands related to products made from farming produce. This
                    helps the manufacturing company align its production processes and product
                    offerings with market needs, enhancing its competitiveness.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Supply Chain Management: </h2>
                  <p>
                    The platform can assist in managing the supply chain by providing real-time
                    visibility into the availability, quality, and pricing of farming produce. This
                    helps the manufacturing company optimize its procurement processes, reduce
                    costs, and ensure timely delivery of raw materials.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Fade up>
                <img src='/images/pic.webp' width={100} height={100} alt='pic' />
              </Fade>
            </div>
          </div>
        ) : (
          ''
        )}

        {activeIndex === 3 ? (
          <div
            className={`${styles.showcase} ${globals['center-items']} ${globals.flex} ${globals['gap-24']}`}
          >
            <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Product Development and Innovation:</h2>
                  <p>
                    The platform can facilitate collaboration between the manufacturing company and
                    farmers to understand their specific needs and challenges. This enables the
                    company to develop innovative farming tools that address those needs, improving
                    efficiency, productivity, and sustainability in agricultural practices.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Market Intelligence: </h2>
                  <p>
                    The platform can offer market intelligence and insights on the demand for
                    farming tools, including trends, preferences, and emerging needs in the
                    agricultural sector.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>After-Sales Support: </h2>
                  <p>
                    The platform can provide a channel for farmers to access after-sales support,
                    including troubleshooting, repairs, and spare parts for the farming tools.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Fade up>
                <img src='/images/pic.webp' width={100} height={100} alt='pic' />
              </Fade>
            </div>
          </div>
        ) : (
          ''
        )}

        {activeIndex === 4 ? (
          <div
            className={`${styles.showcase} ${globals['center-items']} ${globals.flex} ${globals['gap-24']}`}
          >
            <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Easy Access to a Wide Range of Products: </h2>
                  <p>
                    Customers can conveniently browse and access a diverse range of agricultural
                    products offered by different farmers on a single platform. This saves time and
                    effort compared to traditional methods of searching for specific products from
                    individual farmers.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Convenience and Time Savings: </h2>
                  <p>
                    The online platform provides customers with the convenience of browsing,
                    comparing, and purchasing agricultural products from the comfort of their homes
                    or offices. It eliminates the need for physical visits to multiple farms or
                    markets, saving time and effort.
                  </p>
                </div>
              </div>
              <div className={`${globals.flex} ${styles.item} ${globals['gap-12']}`}>
                <span>
                  <IconsRepository.CheckBoxIcon size={24} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
                </span>
                <div>
                  <h2>Secure and Reliable Transactions:</h2>
                  <p>
                    The platform can ensure secure and reliable transactions, offering various
                    payment options and secure payment gateways. Customers can have peace of mind
                    knowing that their financial information is protected, and they are engaging in
                    trustworthy transactions.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Fade up>
                <img src='/images/pic.webp' width={100} height={100} alt='pic' />
              </Fade>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  )
}

export default FeaturesTemplate
