import MailIcon from '../../app/icons/Mail.icon'
import LockIcon from '../../app/icons/Lock.icon'
import EyeIcon from '../../app/icons/Eye.icon'
import StrokeEyeIcon from '../../app/icons/StrokeEye.icon'
import SendIcon from '../../app/icons/Send.icon'
import UserIcon from '../../app/icons/User.icon'
import SearchIcon from '../../app/icons/Search.icon'
import HelpIcon from '../../app/icons/Help.icon'
import BellIcon from '../../app/icons/Bell.icon'
import ActiveBellIcon from '../../app/icons/ActiveBell.icon'
import PhoneIcon from '../../app/icons/Phone.icon'
import CheckBoxIcon from '../../app/icons/CheckBox.icon'
import ArrowIcon from '../../app/icons/Arrow.icon'
import MoneyIcon from '../../app/icons/Money.icon'
import ShareIcon from '../../app/icons/Share.icon'
import DownloadIcon from '../../app/icons/Download.icon'
import DropdownIcon from '../../app/icons/Dropdown.icon'
import LogoutIcon from '../../app/icons/Logout.icon'
import FilledHeartIcon from '../../app/icons/FilledHeart.icon'
import HeartOutlinedIcon from '../../app/icons/HeartOutlined.icon'
import DeleteIcon from '../../app/icons/Delete.icon'
import SettingsIcon from '../../app/icons/Settings.icon'
import HomeIcon from '../../app/icons/Home.icon'
import CameraIcon from '../../app/icons/Camera.icon'
import LinkIcon from '../../app/icons/Link.icon'
import ShoppingCartIcon from '../../app/icons/ShoppingCart.icon'
import HalfStarIcon from '../../app/icons/HalfStar.icon'
import FilledStarIcon from '../../app/icons/FilledStar.icon'
import CheckIcon from '../../app/icons/Check.icon'
import CreateNewFolderIcon from '../../app/icons/CreateNewFolder.icon'
import FolderIcon from '../../app/icons/Folder.icon'
import FacebookIcon from '../../app/icons/Facebook.icon'
import NewIcon from '../../app/icons/New.icon'
import AnnouncementIcon from '../../app/icons/Announcement.icon'
import ProblemIcon from '../../app/icons/Problem.icon'
import OpenInNewTabIcon from '../../app/icons/OpenInNewTab.icon'
import LogoIcon from '../../app/icons/Logo.icon'
import HambugerMenuIcon from '../../app/icons/HambugerMenu.icon'
import DashboardIcon from '../../app/icons/Dashboard.icon'
import InvestorsIcon from '../../app/icons/Investors.icon'
import AnalysisIcon from '../../app/icons/Analysis.icon'
import OrderIcon from '../../app/icons/Order.icon'
import StorefrontIcon from '../../app/icons/Storefront.icon'
import PlusIcon from '../../app/icons/Plus.icon'
import EditIcon from '../../app/icons/Edit.icon'
import LinkedinIcon from '../../app/icons/Linkedin.icon'
import TwitterIcon from '../../app/icons/Twitter.icon'

export const IconsRepository = {
  TwitterIcon,
  LinkedinIcon,
  EditIcon,
  AnalysisIcon,
  InvestorsIcon,
  StorefrontIcon,
  DashboardIcon,
  OrderIcon,
  MailIcon,
  DeleteIcon,
  LockIcon,
  StrokeEyeIcon,
  OpenInNewTabIcon,
  ProblemIcon,
  AnnouncementIcon,
  NewIcon,
  FacebookIcon,
  FolderIcon,
  CheckIcon,
  CreateNewFolderIcon,
  ShoppingCartIcon,
  CameraIcon,
  FilledStarIcon,
  HalfStarIcon,
  HomeIcon,
  LinkIcon,
  EyeIcon,
  SendIcon,
  UserIcon,
  SettingsIcon,
  SearchIcon,
  HelpIcon,
  BellIcon,
  ActiveBellIcon,
  PhoneIcon,
  CheckBoxIcon,
  ArrowIcon,
  MoneyIcon,
  ShareIcon,
  DownloadIcon,
  DropdownIcon,
  LogoutIcon,
  FilledHeartIcon,
  HeartOutlinedIcon,
  LogoIcon,
  HambugerMenuIcon,
  PlusIcon,
}
