import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './authtemplate.module.css'

import useWindowSize from '../../../lib/hooks/use-window-soze'
import UseForm from '../../../lib/hooks/use-form'
import LabeledInputMolecule from '../../molecules/labeled-input/LabeledInput.molecule'
import {
  appConstants,
  buttonTypes,
  iconDirection,
  inputState,
} from '../../../app/constants/constants.app'
import { validatePassword } from '../../../lib/utils'
import { IconsRepository } from '../../../repository/icons/icons.repository'
import ButtonAtom from '../../atoms/button/Button.atom'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { changePasswordThunk } from '../../../app/features/auth/thunks/auth.thunk'
import { RootState } from '../../../app/store/store'
import { ApiRequestStatus } from '../../../types/api.type'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../app/features/notification/notification.slice'

const ChangePasswordTemplate = () => {
  const [trigger, setTrigger] = useState(false)
  const { width } = useWindowSize()
  const [params] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const changePasswordState = useAppSelector((state: RootState) => state.changePasswordSlice)

  useEffect(() => {
    if (changePasswordState.requestResponse.status === ApiRequestStatus.FULFILLED) {
      navigate(appConstants.routes.auth.login)
    }
  }, [changePasswordState.requestResponse])

  const email = params.get('userEmail')
  const token = params.get('token')

  const { form, updateForm } = UseForm(
    {
      email,
      otpCode: token,
      newPassword: '',
      confirmNewPassword: '',
    },
    setTrigger,
    trigger,
  )

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (email !== null && token !== null) {
      dispatch(changePasswordThunk(form))
    }
  }

  useEffect(() => {
    if (changePasswordState.requestResponse.status === ApiRequestStatus.FULFILLED) {
      dispatch(
        triggerNotification({
          message: 'Password successfully reset',
          title: NotificationTitle.SUCCESS,
        }),
      )
    } else {
      if (changePasswordState.requestResponse.status === ApiRequestStatus.REJECTED) {
        dispatch(
          triggerNotification({
            message: changePasswordState.requestResponse.error?.message as string,
            title: NotificationTitle.ERROR,
          }),
        )
      }
    }
  }, [changePasswordState.requestResponse])

  return (
    <div
      className={`${styles.auth__template} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
    >
      <h2>Create a New Password</h2>

      <form
        className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['gap-10']}`}
        onSubmit={handleSubmit}
      >
        <LabeledInputMolecule
          label='Create a New Password'
          placeholder='********'
          onChange={(e) => {
            updateForm('newPassword', e.target.value)
          }}
          state={
            form.newPassword.length <= 0
              ? inputState.normal
              : validatePassword(form.newPassword)
              ? inputState.error
              : inputState.success
          }
          type={showPassword ? 'text' : 'password'}
          name='password'
          iconDir={[iconDirection.left, iconDirection.right]}
          iconLeft={<IconsRepository.LockIcon size={width < 1440 ? 20 : 24} />}
          iconRight={
            showPassword ? (
              <IconsRepository.EyeIcon size={width < 1440 ? 20 : 24} />
            ) : (
              <IconsRepository.StrokeEyeIcon size={width < 1440 ? 20 : 24} />
            )
          }
          iconClick={() => {
            setShowPassword(!showPassword)
          }}
        />

        <LabeledInputMolecule
          label='Confirm Your New Password'
          placeholder='********'
          onChange={(e) => {
            updateForm('confirmNewPassword', e.target.value)
          }}
          state={
            form.confirmNewPassword.length <= 0
              ? inputState.normal
              : validatePassword(form.confirmNewPassword) &&
                form.confirmNewPassword === form.newPassword
              ? inputState.error
              : inputState.success
          }
          type={showConfirmPassword ? 'text' : 'password'}
          name='confirmPassword'
          iconDir={[iconDirection.left, iconDirection.right]}
          iconLeft={<IconsRepository.LockIcon size={width < 1440 ? 20 : 24} />}
          iconRight={
            showConfirmPassword ? (
              <IconsRepository.EyeIcon size={width < 1440 ? 20 : 24} />
            ) : (
              <IconsRepository.StrokeEyeIcon size={width < 1440 ? 20 : 24} />
            )
          }
          iconClick={() => {
            setShowConfirmPassword(!showConfirmPassword)
          }}
        />

        <div
          className={`${styles.forgot} ${globals.flex} ${globals['a-center']} ${globals['s-b']}`}
        >
          <div></div>
          <div>
            <h4>
              Didn&apos;t receive the code ?{' '}
              <Link to={appConstants.routes.auth.forgotPassword}>Resend</Link>
            </h4>
          </div>
        </div>

        <div className={styles.btn}>
          <ButtonAtom
            submit='submit'
            fontSize='1.8rem'
            type={buttonTypes.primary}
            btnText={
              changePasswordState.requestResponse.status === ApiRequestStatus.PENDING
                ? 'Loading...'
                : 'Change Password'
            }
            fullWidth={true}
            disabled={changePasswordState.requestResponse.status === ApiRequestStatus.PENDING}
          />
        </div>
      </form>
    </div>
  )
}

export default ChangePasswordTemplate
