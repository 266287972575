import React from 'react'
import { IconType } from '../../types/icon.type'

const AnalysisIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.11 11.15H7.46001C6.83001 11.15 6.32001 11.66 6.32001 12.29V17.41H10.11V11.15Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.761 6.6H11.241C10.611 6.6 10.101 7.11 10.101 7.74V17.4H13.891V7.74C13.891 7.11 13.391 6.6 12.761 6.6ZM16.548 12.85H13.898V17.4H17.688V13.99C17.678 13.36 17.168 12.85 16.548 12.85Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default AnalysisIcon
