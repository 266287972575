import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'

import './index.css'

import App from './App'

// store
import { store } from './app/store/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <Provider store={store}>
        <Notifications position='bottom-right' fz='xl' zIndex={9999} />
        <App />
      </Provider>
    </MantineProvider>
  </React.StrictMode>,
)

reportWebVitals()
