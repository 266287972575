import React from 'react'
import { IconType } from '../../types/icon.type'

const DropdownIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9.33331 13.3333L16 20L22.6666 13.3333H9.33331Z' fill={color} />
    </svg>
  )
}

export default DropdownIcon
