import React, { useState, useEffect, useCallback } from 'react'

import globals from '../../../../global.module.css'
import styles from '../view.module.css'

import { IOrder } from '../../../../types/order-types/order.type'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { RootState } from '../../../../app/store/store'
import Accordion from '../../../../components/organisms/accordion/Accordion.organism'
import PCardMolecule from '../cards/p-card/PCard.molecule'
import { IProduct } from '../../../../types/product-types/product.type'
import TabsSelectionAtom from '../../../../components/atoms/tabs/TabsSelection.atom'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import { AccountTypes } from '../../../../repository/data/accountType'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes } from '../../../../app/constants/constants.app'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'

const MyOrdersViews = () => {
  const [orders, setOrders] = useState<IOrder[]>([])
  const [loading, setLoading] = useState(false)
  const [marking, setMarking] = useState(false)
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)
  const [openIndex, setOpenIndex] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const dispatch = useAppDispatch()

  const tabs = ['Pending Delivery', 'Delivered', 'Received']

  const fetchOrders = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ orders: IOrder[] }>(
          `/orders/${authState.requestResponse.data?.user._id}/${
            authState.requestResponse.data?.user?.accountType === AccountTypes.FARMER
              ? 'farmer'
              : ''
          }`,
        )
        .then((response) => {
          console.log('====================================')
          console.log({ response })
          console.log('====================================')

          setOrders(response.data.orders)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const toggleAccordion = (index: number) => {
    setOpenIndex(index === openIndex ? -1 : index)
  }

  const filterOrders = (idx: number): IOrder[] => {
    if (idx === 0) {
      return orders.filter((order) => order.delivered === false && order.collected === false)
    }

    if (idx === 1) {
      return orders.filter((order) => order.delivered === true && order.collected === false)
    }

    return orders.filter((order) => order.delivered === true && order.collected === true)
  }

  const controlOrder = async (action: string, orderId: string) => {
    setMarking(true)
    try {
      await publicApiRequest()
        .patch(`/orders/${action === 'delivery' ? 'delivered' : 'collected'}/${orderId}`)
        .then((response) => {
          console.log({ response })
          fetchOrders()

          dispatch(
            triggerNotification({
              message: 'Successfully operation.',
              title: NotificationTitle.SUCCESS,
            }),
          )
        })
    } catch (e) {
      console.log(e)
    } finally {
      setMarking(false)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  useEffect(() => {
    if (orders.length > 0) {
      const filteredOrders = orders.filter((order) => order.docModel !== 'Product')

      setOrders(filteredOrders)
    }
  }, [orders])

  return (
    <div className={`${styles.view}`}>
      <h2>My Orders</h2>
      <div className={`${styles.grid}`}>
        {loading &&
          Array(8)
            .fill(0)
            .map((_product, index) => {
              return (
                <PCardMolecule
                  loading={true}
                  image=''
                  name=''
                  description=''
                  price=''
                  unit=''
                  key={index}
                />
              )
            })}
      </div>
      <div
        style={{
          marginBottom: '20px',
        }}
        className={styles.tabs}
      >
        <TabsSelectionAtom
          icons={[
            <>
              <IconsRepository.BellIcon size={24} />
            </>,
            <>
              <IconsRepository.AnnouncementIcon size={24} />
            </>,
            <>
              <IconsRepository.CreateNewFolderIcon size={24} />
            </>,
          ]}
          counts={[filterOrders(0).length, filterOrders(1).length, filterOrders(2).length]}
          activeIndex={activeTab}
          setActiveIndex={setActiveTab}
          tabs={tabs}
          colors={[
            'var(--AGROX-GREY-COLOR)',
            'var(--AGROX-RED-COLOR)',
            'var(--AGROX-LIGHT-GREEN-COLOR)',
          ]}
        />
      </div>
      <div
        className={`${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
      >
        {filterOrders(activeTab) &&
          filterOrders(activeTab).length > 0 &&
          filterOrders(activeTab).map((order, index) => {
            return (
              <Accordion
                key={order._id + index}
                index={index}
                openIndex={openIndex}
                toggleAccordion={toggleAccordion}
                title={`Order - #${order._id}`}
                content={
                  <div
                    style={{
                      padding: '24px 16px',
                    }}
                  >
                    <div className={styles.grid}>
                      {order.productIds &&
                        order.productIds.length > 0 &&
                        order.productIds.map((product, index) => {
                          return (
                            <PCardMolecule
                              toolCard={true}
                              image={(product as IProduct).imageUrl as string}
                              name={(product as IProduct).name}
                              orderView={order.productQuantities[index]}
                              description={(product as IProduct).description}
                              price={`${(product as IProduct).price}`}
                              unit={(product as IProduct).unit}
                              key={index}
                            />
                          )
                        })}
                    </div>
                    {authState.requestResponse.data?.user?.accountType === AccountTypes.FARMER &&
                    activeTab === 1 ? (
                      <div
                        style={{
                          marginTop: '24px',
                        }}
                      >
                        <ButtonAtom
                          borderRadius='4px'
                          disabled={marking}
                          onClick={() => {
                            controlOrder('', order._id)
                          }}
                          fullWidth={false}
                          btnText={marking ? 'Loading...' : 'Mark as Received'}
                          type={buttonTypes.primary}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {authState.requestResponse.data?.user?.accountType === AccountTypes.MACHINERY &&
                    activeTab === 0 ? (
                      <div
                        style={{
                          marginTop: '24px',
                        }}
                      >
                        <ButtonAtom
                          borderRadius='4px'
                          disabled={marking}
                          onClick={() => {
                            controlOrder('delivery', order._id)
                          }}
                          fullWidth={false}
                          btnText={marking ? 'Loading...' : 'Mark as Delivered'}
                          type={buttonTypes.primary}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                }
              />
            )
          })}
      </div>
    </div>
  )
}

export default MyOrdersViews
