import React from 'react'
import { IconType } from '../../types/icon.type'

const PhoneIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.64 20.3467L22.2534 19.96C21.44 19.8667 20.64 20.1467 20.0667 20.72L17.6134 23.1733C13.84 21.2533 10.7467 18.1733 8.82669 14.3867L11.2934 11.92C11.8667 11.3467 12.1467 10.5467 12.0534 9.73334L11.6667 6.37334C11.5067 5.02667 10.3734 4.01334 9.01336 4.01334H6.70669C5.20002 4.01334 3.94669 5.26667 4.04002 6.77334C4.74669 18.16 13.8534 27.2533 25.2267 27.96C26.7334 28.0533 27.9867 26.8 27.9867 25.2933V22.9867C28 21.64 26.9867 20.5067 25.64 20.3467Z'
        fill={color}
      />
    </svg>
  )
}

export default PhoneIcon
