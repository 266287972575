import React, { Fragment } from 'react'

import LabeledInputMolecule from '../../../molecules/labeled-input/LabeledInput.molecule'
import { iconDirection, inputState } from '../../../../app/constants/constants.app'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import { validateEmail } from '../../../../lib/utils'
import useWindowSize from '../../../../lib/hooks/use-window-soze'
import { RegModuleTypes } from '../../../../types/templates/register.type'
import SelectAtom from '../../../atoms/input/Select.atom'
import { AccountTypes } from '../../../../repository/data/accountType'

const UserinfoModule: React.FC<RegModuleTypes> = ({ form, updateForm }) => {
  const { width } = useWindowSize()

  const onSelect = (value: string) => {
    updateForm('accountType', value)
  }

  return (
    <Fragment>
      <SelectAtom
        onSelect={onSelect}
        placeholder='Choose account type'
        state={inputState.normal}
        list={[
          AccountTypes.CUSTOMER,
          AccountTypes.FARMER,
          AccountTypes.INVESTOR,
          AccountTypes.MANUFACTURER,
          AccountTypes.MACHINERY,
        ]}
        type='text'
        value={form.accountType}
        name='accountType'
      />

      <LabeledInputMolecule
        label='First Name'
        placeholder='Nchia Emmanuela'
        onChange={(e) => {
          updateForm('firstName', e.target.value)
        }}
        state={form.firstName.length <= 0 ? inputState.normal : inputState.success}
        type='text'
        name='firstName'
        iconDir={[iconDirection.left]}
        iconLeft={<IconsRepository.UserIcon size={width < 1440 ? 18 : 20} />}
      />

      <LabeledInputMolecule
        label='Last Name'
        placeholder='Ekei'
        onChange={(e) => {
          updateForm('lastName', e.target.value)
        }}
        state={form.lastName.length <= 0 ? inputState.normal : inputState.success}
        type='text'
        name='lastName'
        iconDir={[iconDirection.left]}
        iconLeft={<IconsRepository.UserIcon size={width < 1440 ? 18 : 20} />}
      />

      <LabeledInputMolecule
        label='Email (Optional)'
        placeholder='getexample@gmail.com'
        onChange={(e) => {
          updateForm('email', e.target.value)
        }}
        state={
          form.email && form?.email.length <= 0
            ? inputState.normal
            : validateEmail(form?.email as string)
            ? inputState.error
            : inputState.success
        }
        type='text'
        name='email'
        iconDir={[iconDirection.left]}
        iconLeft={<IconsRepository.MailIcon size={width < 1440 ? 18 : 20} />}
      />
    </Fragment>
  )
}

export default UserinfoModule
