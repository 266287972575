import React, { Fragment, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { appRoutes } from './app/router/router.app'
import { useAppDispatch, useAppSelector } from './lib/hooks'
import { RootState } from './app/store/store'
import {
  NotificationStatus,
  resetNotificationState,
} from './app/features/notification/notification.slice'
import NotificationMolecule from './components/molecules/notification/Notification.molecule'

const App = () => {
  const notificationState = useAppSelector((state: RootState) => state.notificationSlice)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (notificationState.status === NotificationStatus.ACTIVE) {
      setTimeout(() => {
        dispatch(resetNotificationState())
      }, 4000)
    }
  }, [notificationState])

  return (
    <div className='app'>
      <Router>
        <NotificationMolecule />
        <Routes>
          {appRoutes.length > 0 &&
            appRoutes.map((routes, index) => {
              return (
                <Fragment key={index}>
                  {routes.views !== undefined && routes.views.length > 0 ? (
                    <Route key={index} path={routes.path} element={<routes.component />}>
                      {routes.views.map((view, index) =>
                        view.index === true ? (
                          <Route key={index} index element={<view.component />} />
                        ) : (
                          <Route key={index} path={view.path} element={<view.component />} />
                        ),
                      )}
                    </Route>
                  ) : (
                    <Route key={index} path={routes.path} element={<routes.component />} />
                  )}
                </Fragment>
              )
            })}
        </Routes>
      </Router>
    </div>
  )
}

export default App
