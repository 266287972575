import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import globals from '../../global.module.css'
import styles from './authlayout.module.css'

import { SvgsRepository } from '../../repository/svgs/svg.repository'
import BG from '../../app/assets/images/auth_bg.png'
import useWindowSize from '../../lib/hooks/use-window-soze'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { RootState } from '../../app/store/store'
import { appConstants } from '../../app/constants/constants.app'
import { resetChangeAuthStatus } from '../../app/features/auth/slices/change-password.slice'
import { resetForgotAuthStatus } from '../../app/features/auth/slices/forgot-password.slice'
import { resetLoginAuthStatus } from '../../app/features/auth/slices/login-user.slice'
import { resetRegAuthStatus } from '../../app/features/auth/slices/register-user.slice'
import { resetVerificationAuthStatus } from '../../app/features/auth/slices/verify-email.slice'
import { AccountTypes } from '../../repository/data/accountType'

type AuthLayoutProps = {
  children: React.ReactNode
  catchText: string
}

const AuthLayout = ({ catchText, children }: AuthLayoutProps) => {
  const { width } = useWindowSize()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const authState = useAppSelector((state: RootState) => state.loginUserSlice)

  useEffect(() => {
    if (authState.requestResponse.data?.user) {
      if (localStorage.getItem('prevPath') !== null) {
        navigate(localStorage.getItem('prevPath') as string)

        // eslint-disable-next-line
        return
      }
      if (
        authState.requestResponse.data?.user &&
        authState.requestResponse.data.user.accountType === AccountTypes.INVESTOR
      ) {
        navigate(appConstants.routes.investorsView)
      }

      navigate(appConstants.routes.newDashboard.index)
    }
  }, [authState.requestResponse])

  useEffect(() => {
    dispatch(resetChangeAuthStatus())
    dispatch(resetForgotAuthStatus())
    dispatch(resetLoginAuthStatus())
    dispatch(resetRegAuthStatus())
    dispatch(resetVerificationAuthStatus())
  }, [window.location.pathname])

  return (
    <div className={`${globals.flex} ${styles.global__auth} ${globals['center-items']}`}>
      <img src={BG} alt='auth bg' width={100} height={100} />
      <div className={`${styles.auth__layout} ${globals.flex}`}>
        <div
          className={`${styles.left__banner} ${globals.flex} ${globals['center-items']} ${globals['p-desktop']}`}
        >
          <div
            className={`${styles.logo__text} ${globals['gap-24']} ${globals.flex} ${globals['flex-column']} ${globals['center-items']}`}
          >
            <Link to={appConstants.routes.home}>
              <SvgsRepository.LogoSvg />
            </Link>
            <h2 className={globals['text-center']}>{catchText}</h2>
          </div>
        </div>

        <div className={`${styles.auth__container} ${width > 634 ? globals['p-desktop'] : ''}`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
