export const tableData = [
  {
    product: 'Potatoes',
    price: '5000',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '10,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Beans',
    price: '7000',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '14,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Yam',
    price: '10000',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '20,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Casava',
    price: '3000',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '6,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Coacoa',
    price: '4000',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '8,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Rice',
    price: '22,000',
    units: 'Bags',
    quantitySold: '8',
    totalAmount: '206,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Sweet Potatoes',
    price: '2500',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '5,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Potatoes',
    price: '7000',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '14,000',
    productImage: '/images/img5.jpg',
  },
  {
    product: 'Potatoes',
    price: '7000',
    units: 'Bucket',
    quantitySold: '2',
    totalAmount: '14,000',
    productImage: '/images/img5.jpg',
  },
]
