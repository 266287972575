import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './authtemplate.module.css'

import ButtonAtom from '../../atoms/button/Button.atom'
import {
  appConstants,
  buttonTypes,
  iconDirection,
  inputState,
} from '../../../app/constants/constants.app'
import { IconsRepository } from '../../../repository/icons/icons.repository'
import UseForm from '../../../lib/hooks/use-form'
import useWindowSize from '../../../lib/hooks/use-window-soze'
import LabeledInputMolecule from '../../molecules/labeled-input/LabeledInput.molecule'
import { validateEmail } from '../../../lib/utils'
import SelectAtom from '../../atoms/input/Select.atom'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { forgotPasswordThunk } from '../../../app/features/auth/thunks/auth.thunk'
import { RootState } from '../../../app/store/store'
import { ApiRequestStatus } from '../../../types/api.type'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../app/features/notification/notification.slice'

const ForgotPasswordTemplate = () => {
  const [trigger, setTrigger] = useState(false)
  const { width } = useWindowSize()
  const dispatch = useAppDispatch()

  const [method, setMethod] = useState('')

  const forgotPasswordState = useAppSelector((state: RootState) => state.forgotPasswordSlice)

  const { form, updateForm } = UseForm(
    {
      email: '',
      phoneNumber: '',
    },
    setTrigger,
    trigger,
  )

  const onSelect = (value: string) => {
    setMethod(value)
  }

  const handleSubmit = async () => {
    dispatch(forgotPasswordThunk(form))
  }

  useEffect(() => {
    if (forgotPasswordState.requestResponse.status === ApiRequestStatus.FULFILLED) {
      dispatch(
        triggerNotification({
          message: 'Link sent successfully to your email',
          title: NotificationTitle.SUCCESS,
        }),
      )
    } else {
      if (forgotPasswordState.requestResponse.status === ApiRequestStatus.REJECTED) {
        dispatch(
          triggerNotification({
            message: forgotPasswordState.requestResponse.error?.message as string,
            title: NotificationTitle.ERROR,
          }),
        )
      }
    }
  }, [forgotPasswordState.requestResponse])

  return (
    <div
      className={`${styles.auth__template} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
    >
      <h2>Forgot Password</h2>

      <div
        className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['gap-10']}`}
      >
        <SelectAtom
          onSelect={onSelect}
          placeholder='Select Method'
          state={inputState.normal}
          list={['Email', 'Phone']}
          type='text'
          value={method}
          name='method'
        />

        {method === 'Email' ? (
          <LabeledInputMolecule
            label='Please enter your Email'
            placeholder='getexample@gmail.com'
            onChange={(e) => {
              updateForm('email', e.target.value)
            }}
            state={
              form.email.length <= 0
                ? inputState.normal
                : validateEmail(form.email)
                ? inputState.error
                : inputState.success
            }
            type='text'
            name='email'
            iconDir={[iconDirection.left]}
            iconLeft={<IconsRepository.MailIcon size={width < 1440 ? 20 : 24} />}
          />
        ) : (
          ''
        )}

        {method === 'Phone' ? (
          <LabeledInputMolecule
            label='Please enter your Phone Number'
            placeholder='+237 676 445 393'
            onChange={(e) => {
              updateForm('phoneNumber', e.target.value)
            }}
            state={form.phoneNumber.length <= 0 ? inputState.normal : inputState.success}
            type='text'
            name='phoneNumber'
            iconDir={[iconDirection.left]}
            iconLeft={<IconsRepository.PhoneIcon size={width < 1440 ? 20 : 24} />}
          />
        ) : (
          ''
        )}

        <div className={styles.btn}>
          <ButtonAtom
            fontSize='1.8rem'
            type={buttonTypes.primary}
            onClick={() => {
              handleSubmit()
            }}
            btnText={
              forgotPasswordState.requestResponse.status === ApiRequestStatus.PENDING
                ? 'Loading...'
                : 'Request Reset Link'
            }
            fullWidth={true}
            disabled={forgotPasswordState.requestResponse.status === ApiRequestStatus.PENDING}
          />
        </div>

        <div className={styles.ref}>
          <h4>
            Remember Password ? <Link to={appConstants.routes.auth.login}>Login</Link>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordTemplate
