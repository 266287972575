import React, { useCallback, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './productcard.module.css'
import { User } from '../../../types/user-types/user.type'
import { requirementType } from '../../../types/project-types/project.type'
import ButtonAtom from '../button/Button.atom'
import { buttonTypes } from '../../../app/constants/constants.app'
import { AccountTypes } from '../../../repository/data/accountType'
import { publicApiRequest } from '../../../lib/hooks/axios-instance'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../app/features/notification/notification.slice'
import { useAppDispatch } from '../../../lib/hooks'

type Props = {
  img: string
  productName: string
  descr: string
  price: string
  unit: string
  project?: boolean
  userId?: User
  req?: requirementType[]
  investorId?: string
  projectId?: string
  investorsIds?: string[]
  accountType?: AccountTypes
}

const ProductCardAtom = ({
  img,
  productName,
  req,
  userId,
  project,
  descr,
  price,
  unit,
  investorId,
  projectId,
  investorsIds,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const investProject = useCallback(async () => {
    setLoading(true)

    try {
      await publicApiRequest()
        .patch(`/projects/invest-in-project/${projectId}/${investorId}`)
        .then((res) => {
          console.log({ res })

          dispatch(
            triggerNotification({
              message: 'Investment successful',
              title: NotificationTitle.SUCCESS,
            }),
          )
        })
    } catch (e) {
      console.log('====================================')
      console.log({ e })
      console.log('====================================')

      dispatch(
        triggerNotification({
          message: 'An error occured!',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }, [])

  const dropInvestment = useCallback(async () => {
    setLoading(true)

    try {
      await publicApiRequest()
        .patch(`/projects/withdraw-invesment-interest/${projectId}/${investorId}`)
        .then((res) => {
          console.log({ res })

          dispatch(
            triggerNotification({
              message: 'Investment dropped successful',
              title: NotificationTitle.SUCCESS,
            }),
          )
        })
    } catch (e) {
      console.log('====================================')
      console.log({ e })
      console.log('====================================')

      dispatch(
        triggerNotification({
          message: 'An error occured!',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <div
      className={`${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${styles.product__card}`}
    >
      <div>
        <div
          style={{
            height: img.length === 0 ? '20px' : '200px',
          }}
          className={styles.img}
        >
          {img.length > 0 ? <img src={img} alt={productName} /> : ''}
        </div>
      </div>
      {userId?.imageUrl !== undefined && userId.imageUrl.length > 0 ? (
        <>
          <div>
            <div
              style={{
                height: '70px',
                width: '70px',
                borderRadius: '50%',
              }}
              className={styles.img}
            >
              {userId?.imageUrl !== undefined && userId?.imageUrl.length > 0 ? (
                <img src={userId.imageUrl} alt={userId.firstName} />
              ) : (
                ''
              )}
            </div>
          </div>
          <h2 className={styles.name}>Posted By: {userId.firstName}</h2>{' '}
        </>
      ) : (
        ''
      )}
      <div
        style={{
          height: '100%',
        }}
        className={`${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['s-b']}`}
      >
        <div
          className={`${globals.flex} ${globals['full-width']} ${globals['flex-column']} ${globals['gap-10']} ${styles.text}`}
        >
          <h4>{productName}</h4>
          <h5>{descr}</h5>
        </div>
        <div
          style={{ marginTop: '20px' }}
          className={`${globals.flex} ${globals['full-width']} ${globals['s-b']} ${styles.price}`}
        >
          <h4>
            {project === true ? 'Estimate: ' : ''} {price} FCFA
          </h4>
          <p>
            <span>{unit}</span>
          </p>
        </div>
      </div>
      {project === true && req !== undefined ? (
        <>
          <h2
            style={{
              marginBottom: '-10px',
              fontSize: '1.8rem',
            }}
            className={styles.name}
          >
            Purchases
          </h2>
          {req.map((req, index) => {
            return (
              <div key={index} className={styles.req}>
                <h2
                  className={`${globals.flex} ${globals['gap-12']} ${globals['s-b']}
                      `}
                >
                  <span>{req.toolName}</span>
                </h2>
                <h3>
                  <span>Description: </span> {req.toolDescription}
                </h3>
                <h3>
                  <span>Amount: </span> {req.amount} FCFA
                </h3>
              </div>
            )
          })}
        </>
      ) : (
        ''
      )}

      {project === true && investorId !== undefined && req !== undefined ? (
        <ButtonAtom
          btnText={
            loading
              ? 'Loading...'
              : investorsIds?.includes(investorId)
              ? 'Drop Investment'
              : 'Invest in Project'
          }
          type={buttonTypes.primary}
          onClick={investorsIds?.includes(investorId) ? dropInvestment : investProject}
          disabled={loading}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default ProductCardAtom
