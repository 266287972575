export const appConstants = {
  routes: {
    home: '/',
    onboardingPage: '/welcome',
    stores: '/farm-stores',
    cart: '/cart',
    storeDetails: '/farm-store/:id',
    productDetails: '/product/:id',
    auth: {
      login: '/auth/login',
      register: '/auth/register',
      forgotPassword: '/auth/forgot-password',
      changePassword: '/change-password',
      verifyEmail: '/verify-email',
    },
    investorsView: '/investor-dashboard',
    dashboard: '/dashboard',
    newDashboard: {
      index: '/new-dashboard',
      manageProducts: 'manage-products',
      orders: 'orders',
      manageTools: 'farming-tools',
      personalInfo: 'personal-info',
      cart: 'cart',
      myOrders: 'my-orders',
      farmers: 'farmers',
      toolMakers: 'tool-makers',
      projects: 'projects',
      createNewProject: 'create-project',
    },
    dViews: {
      index: '/dashboard',
      settings: 'settings',
      profile: 'profile',
      manageProject: 'manage-projects',
      analysis: 'analysis',
      toolOrders: 'tools/orders',
      orders: 'orders',
      investors: 'investors',
      manageToolOrder: 'tool-orders',
      cart: 'cart',
    },
  },
}

export enum iconDirection {
  right = 'right',
  left = 'left',
}

export enum inputState {
  error = 'error',
  normal = 'normal',
  success = 'success',
}

export enum buttonTypes {
  primary = 'primary',
  secondary = 'secondary',
  primaryOutlined = 'primaryOutlined',
  secondaryOutlined = 'secondaryOutlined',
}
