import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../../../global.module.css'
import styles from './index.module.css'

import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { IProduct, ProductsResponse } from '../../../../types/product-types/product.type'
import { staticSvgs } from '../../../../app/assets/images'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes, iconDirection, inputState } from '../../../../app/constants/constants.app'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import LabeledInputMolecule from '../../../../components/molecules/labeled-input/LabeledInput.molecule'
import UseForm from '../../../../lib/hooks/use-form'
import { CategoriesResponse, ICategory } from '../../../../types/category-types/category.type'
import SelectAtom from '../../../../components/atoms/input/Select.atom'
import ProductCardAtom from '../../../../components/atoms/product-card/ProductCard.atom'
import ModalMolecule from '../../../../components/molecules/modal/Modal.molecule'
import ProductDetailsOrganism from '../../../../components/organisms/product-details/ProductDetails.organism'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { AccountTypes } from '../../../../repository/data/accountType'
import ToolMakerIndexPage from '../tool-maker-index/ToolMakerIndex.page'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import Loader from '../../../../components/loader/Loader'
// import { IconsRepository } from '../../../../repository/icons/icons.repository'
// import CategoryCardAtom from '../../../../components/atoms/category-card/CategoryCard.atom'
// import ProductCardAtom from '../../../../components/atoms/product-card/ProductCard.atom'
// import ChartComponent from '../../../../components/molecules/chart/Chart.molecule'
// import ButtonAtom from '../../../../components/atoms/button/Button.atom'
// import { buttonTypes, iconDirection } from '../../../../app/constants/constants.app'

const IndexDashboardPage = () => {
  const [trigger, setTrigger] = useState(false)
  const dispatch = useAppDispatch()

  const [myProducts, setMyProducts] = useState<IProduct[]>([])
  const [cats, setCats] = useState<ICategory[]>([])
  const [selectedCat, setSelectedCat] = useState('')
  const [productId, setProductId] = useState('')
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const authState = useAppSelector((state) => state.loginUserSlice)
  const { requestResponse } = authState
  const { data } = requestResponse

  const [adding, setAdding] = useState(false)

  const { form, updateForm } = UseForm(
    {
      name: '',
      description: '',
      file: '',
      price: 0,
      quantity: 0,
      unit: '',
      categoryId: '',
    },
    setTrigger,
    trigger,
  )

  const handleSubmit = async () => {
    setLoading(true)
    const body = new FormData()

    body.append('file', form.file)
    body.append('name', form.name)
    body.append('description', form.description)
    body.append('price', `${form.price}`)
    body.append('quantity', `${form.quantity}`)
    body.append('unit', form.unit)
    body.append('categoryId', form.categoryId)

    try {
      await publicApiRequest()
        .post<ProductsResponse>('/products/add-new-product', body)
        .then((_response) => {
          dispatch(
            triggerNotification({
              message: 'Product successfully created',
              title: NotificationTitle.SUCCESS,
            }),
          )
          setAdding(false)
          fetchProducts()
        })
    } catch (e) {
      dispatch(
        triggerNotification({
          message: 'An error occured, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const fetchProducts = useCallback(async () => {
    console.log('fetching...')
    setLoading(true)

    try {
      await Promise.all([
        await publicApiRequest().get<ProductsResponse>('/products/my-products'),
        await publicApiRequest().get<CategoriesResponse>('/categories'),
      ]).then((response) => {
        setMyProducts(response[0].data.products)
        setCats(response[1].data.categories)
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  const extractCatNames = () => {
    const temp: string[] = []
    if (cats.length > 0) {
      cats.map((cat) => {
        temp.push(cat.name)
      })

      return temp
    } else return []
  }

  return (
    <div
      className={`${styles.index} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${globals['p-desktop']}`}
    >
      <div className={styles.head}>
        <h2>Dashboard</h2>
      </div>

      {authState.requestResponse.data?.user.accountType === AccountTypes.MACHINERY ? (
        <div>
          <ToolMakerIndexPage />
        </div>
      ) : (
        <div className={`${styles.container} ${globals.flex} ${globals['gap-16']}`}>
          {loading ? <Loader /> : ''}

          {!loading && myProducts.length <= 0 ? (
            <div
              className={`${styles.no__products} ${globals.flex} ${globals['flex-column']} ${globals['center-items']} ${globals['gap-24']}`}
            >
              <img src={staticSvgs.dashboard.EmptySvg} width='100%' height='100%' alt='empty' />
              <h3>
                No {data?.user.accountType === AccountTypes.FARMER ? 'products' : 'tools'} yet.
              </h3>
              <ButtonAtom
                submit='submit'
                fontSize='1.8rem'
                type={buttonTypes.primary}
                btnText={
                  adding
                    ? 'Close Add Modal'
                    : data?.user.accountType === AccountTypes.FARMER
                    ? 'Add New Product'
                    : 'Add New Tool'
                }
                iconDir={iconDirection.right}
                icon={<IconsRepository.PlusIcon size={22} />}
                fullWidth={false}
                onClick={() => {
                  setAdding(!adding)
                }}
              />
            </div>
          ) : (
            <div>
              <div className={`${styles.grid} ${globals['full-width']} ${globals['gap-16']}`}>
                {!loading &&
                  myProducts.length > 0 &&
                  myProducts.map((item, index) => (
                    <div
                      onClick={() => {
                        setEditing(true)
                        setProductId(item._id)
                      }}
                      key={index}
                      className={styles.box}
                    >
                      <ProductCardAtom
                        productName={item.name}
                        price={`${item.price} XAF`}
                        unit={item.unit}
                        img={item.imageUrl as string}
                        descr={item.description}
                      />
                    </div>
                  ))}
              </div>
              {!loading ? (
                <div
                  style={{
                    marginTop: '30px',
                  }}
                >
                  <ButtonAtom
                    submit='submit'
                    fontSize='1.8rem'
                    type={buttonTypes.primary}
                    btnText={adding ? 'Close Add Modal' : 'Add New Product'}
                    iconDir={iconDirection.right}
                    icon={<IconsRepository.PlusIcon size={22} />}
                    fullWidth={false}
                    onClick={() => {
                      setAdding(!adding)
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          )}

          {editing && myProducts.find((prod) => prod._id === productId) ? (
            <ModalMolecule
              close={() => {
                setEditing(false)
              }}
            >
              <ProductDetailsOrganism
                selectedCat={selectedCat}
                setSelectedCat={setSelectedCat}
                extractCatNames={extractCatNames}
                fetchProducts={fetchProducts}
                cats={cats}
                product={myProducts.find((prod) => prod._id === productId)}
                setLoading={setLoading}
                loading={loading}
              />
            </ModalMolecule>
          ) : (
            ''
          )}

          {adding ? (
            <ModalMolecule
              close={() => {
                setAdding(false)
              }}
            >
              <div
                className={`${styles.new_products} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
              >
                <input
                  type='file'
                  onChange={(e) => {
                    updateForm('file', (e.target.files as FileList)[0])
                  }}
                />
                <SelectAtom
                  onSelect={(name: string) => {
                    setSelectedCat(name)
                    cats.map((item) => {
                      if (item.name === name) {
                        updateForm('categoryId', item._id)
                      }
                    })
                  }}
                  placeholder='Select Category'
                  state={inputState.normal}
                  list={extractCatNames()}
                  type='text'
                  value={selectedCat}
                  name='method'
                />
                <LabeledInputMolecule
                  label='Product name'
                  placeholder='paw-paw'
                  onChange={(e) => {
                    updateForm('name', e.target.value)
                  }}
                  state={inputState.normal}
                  type='text'
                  name='name'
                />
                <LabeledInputMolecule
                  label='Description'
                  placeholder='Fresh paw=paw'
                  onChange={(e) => {
                    updateForm('description', e.target.value)
                  }}
                  state={inputState.normal}
                  type='text'
                  name='description'
                />
                <LabeledInputMolecule
                  label='Quantity Available'
                  placeholder='100'
                  onChange={(e) => {
                    updateForm('quantity', e.target.value)
                  }}
                  state={inputState.normal}
                  type='number'
                  name='quantity'
                />
                <LabeledInputMolecule
                  label='Unit'
                  placeholder='basket'
                  onChange={(e) => {
                    updateForm('unit', e.target.value)
                  }}
                  state={inputState.normal}
                  type='text'
                  name='unit'
                />
                <LabeledInputMolecule
                  label='Price (XAF)'
                  placeholder='1000'
                  onChange={(e) => {
                    updateForm('price', e.target.value)
                  }}
                  state={inputState.normal}
                  type='number'
                  name='price'
                />
                <div style={{ marginTop: '10px' }}>
                  <ButtonAtom
                    submit='submit'
                    fontSize='1.8rem'
                    type={buttonTypes.primary}
                    btnText={loading ? 'Loading...' : 'Create New Product'}
                    fullWidth={false}
                    onClick={() => {
                      handleSubmit()
                    }}
                  />
                </div>
              </div>
            </ModalMolecule>
          ) : (
            ''
          )}
        </div>
      )}

      {/* <div
        className={`${styles.content} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
      >
        <div className={styles.headline}>
          <h3>Sales Statistics</h3>
        </div>
      </div> */}

      {/* <div className={`${styles.chart} ${globals.flex} ${globals['gap-16']}`}>
        <div>
          <ChartComponent type='area' />
        </div>
        <div>
          <ChartComponent type='bar' />
        </div>
      </div> */}

      {/* <div
        className={`${styles.content} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
      >
        <div
          className={`${globals.flex} ${styles.headline} ${globals['center-items']} ${globals['s-b']} ${globals['gap-16']}`}
        >
          <h3>Categories & Stats </h3>
          <ButtonAtom
            fontSize='1.6rem'
            height='46px'
            type={buttonTypes.secondary}
            btnText='New Category'
            bgColor='var(--AGROX-LIGHT-GREEN-COLOR)'
            iconDir={iconDirection.right}
            icon={<IconsRepository.PlusIcon size={24} />}
          />
        </div>

        <div className={`${styles.grid} ${globals['gap-16']}`}>
          {Array(6)
            .fill(0)
            .map((_i, index) => (
              <Link to='/' key={index}>
                <div className={styles.box}>
                  <span className={styles.overlay}>
                    <IconsRepository.OpenInNewTabIcon size={24} />
                  </span>
                  <CategoryCardAtom
                    categoryName='Cherries'
                    itemNumber={200}
                    img={`/images/img${index + 1}.jpg`}
                  />
                </div>
              </Link>
            ))}
        </div>
      </div> */}

      {/* <div
        className={`${styles.content} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
      >
        <div className={styles.headline}>
          <h3>Popular Products</h3>
        </div>

        <div className={`${styles.grid} ${globals['gap-16']}`}>
          {Array(6)
            .fill(0)
            .map((_i, index) => (
              <Link to='/' key={index}>
                <div className={styles.box}>
                  <ProductCardAtom
                    productName='Casava'
                    price='12.32'
                    unit='per basket'
                    img={`/images/img${index + 1}.jpg`}
                    descr='Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore maxime, aspernatur explicabo.'
                  />
                </div>
              </Link>
            ))}
        </div>

        <div
          style={{
            marginTop: '1rem',
          }}
          className={`${globals.flex} ${globals['center-items']} ${globals['s-b']} ${globals['gap-16']}`}
        >
          <div></div>
          <Link to='/dashboard/store-front'>
            <ButtonAtom
              fontSize='1.6rem'
              height='46px'
              type={buttonTypes.secondary}
              btnText='View All Products'
              bgColor='var(--AGROX-LIGHT-GREEN-COLOR)'
              iconDir={iconDirection.right}
              icon={<IconsRepository.EyeIcon size={20} />}
            />
          </Link>
        </div>
      </div> */}
    </div>
  )
}

export default IndexDashboardPage
