import React, { useRef, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './accordion.module.css'

const AccordionItem: React.FC<{
  title: string
  isOpen: boolean
  toggle: () => void
  children: React.ReactNode
}> = ({ title, isOpen, toggle, children }) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [contentHeight, setContentHeight] = useState<number | null>(null)

  const onToggle = () => {
    if (contentRef.current) {
      if (isOpen) {
        setContentHeight(0)
        setTimeout(toggle, 250)
      } else {
        setContentHeight(contentRef.current.scrollHeight)
        toggle()
      }
    }
  }

  return (
    <div className={styles.accordionItem}>
      <button
        className={`${styles.accordionButton} ${globals.flex} ${globals['s-b']} ${globals['a-center']} ${globals['gap-16']}`}
        onClick={onToggle}
      >
        <span>{title}</span>
      </button>
      <div
        ref={contentRef}
        className={`${styles.accordionContent} ${isOpen ? styles.open : ''}`}
        style={{ maxHeight: contentHeight as number }}
      >
        {children}
      </div>
    </div>
  )
}

const Accordion: React.FC<{
  index: number
  title: string
  openIndex: number | null
  toggleAccordion: (index: number) => void
  content: React.ReactNode
}> = ({ index, openIndex, title, toggleAccordion, content }) => {
  return (
    <div className={styles.accordion}>
      <AccordionItem
        title={title}
        isOpen={openIndex === index}
        toggle={() => toggleAccordion(index)}
      >
        {content}
      </AccordionItem>
    </div>
  )
}

export default Accordion
