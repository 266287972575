import React from 'react'
import { IconType } from '../../types/icon.type'

const SearchIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.6667 18.6667H19.6134L19.24 18.3067C20.84 16.44 21.6667 13.8933 21.2134 11.1867C20.5867 7.48001 17.4934 4.52001 13.76 4.06668C8.12004 3.37334 3.37337 8.12001 4.06671 13.76C4.52004 17.4933 7.48004 20.5867 11.1867 21.2133C13.8934 21.6667 16.44 20.84 18.3067 19.24L18.6667 19.6133V20.6667L24.3334 26.3333C24.88 26.88 25.7734 26.88 26.32 26.3333C26.8667 25.7867 26.8667 24.8933 26.32 24.3467L20.6667 18.6667ZM12.6667 18.6667C9.34671 18.6667 6.66671 15.9867 6.66671 12.6667C6.66671 9.34668 9.34671 6.66668 12.6667 6.66668C15.9867 6.66668 18.6667 9.34668 18.6667 12.6667C18.6667 15.9867 15.9867 18.6667 12.6667 18.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
