import React from 'react'

import AuthLayout from '../../../layouts/auth/Auth.layout'
import RegisterTemplate from '../../../components/templates/auth/Register.auth.template'

const RegisterPage = () => {
  return (
    <AuthLayout catchText="We are very excited you're joining us. Yuppi!!!">
      <RegisterTemplate />
    </AuthLayout>
  )
}

export default RegisterPage
