import React from 'react'
import { IconType } from '../../types/icon.type'

const NewIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.66666 16.6667L6.33332 12H4.66666V20H6.33332V15.3333L9.73332 20H11.3333V12H9.66666V16.6667ZM12.6667 20H18V18.3333H14.6667V16.8533H18V15.1733H14.6667V13.68H18V12H12.6667V20ZM25.6667 12V18H24.1733V13.32H22.5067V18.0133H21V12H19.3333V18.6667C19.3333 19.4 19.9333 20 20.6667 20H26C26.7333 20 27.3333 19.4 27.3333 18.6667V12H25.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default NewIcon
