import React from 'react'
import { IconType } from '../../types/icon.type'

const CameraIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 20.2667C18.3564 20.2667 20.2666 18.3564 20.2666 16C20.2666 13.6436 18.3564 11.7333 16 11.7333C13.6436 11.7333 11.7333 13.6436 11.7333 16C11.7333 18.3564 13.6436 20.2667 16 20.2667Z'
        fill={color}
      />
      <path
        d='M12 2.66666L9.55999 5.33332H5.33332C3.86666 5.33332 2.66666 6.53332 2.66666 7.99999V24C2.66666 25.4667 3.86666 26.6667 5.33332 26.6667H26.6667C28.1333 26.6667 29.3333 25.4667 29.3333 24V7.99999C29.3333 6.53332 28.1333 5.33332 26.6667 5.33332H22.44L20 2.66666H12ZM16 22.6667C12.32 22.6667 9.33332 19.68 9.33332 16C9.33332 12.32 12.32 9.33332 16 9.33332C19.68 9.33332 22.6667 12.32 22.6667 16C22.6667 19.68 19.68 22.6667 16 22.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default CameraIcon
