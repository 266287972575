import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import globals from '../../global.module.css'
import styles from './productdetails.module.css'

import NavbarOrganism from '../../components/organisms/navbar/Navbar.organism'
import FooterOrganism from '../../components/organisms/footer/Footer.organism'
import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { IProduct } from '../../types/product-types/product.type'
import Loader from '../../components/loader/Loader'
import ButtonAtom from '../../components/atoms/button/Button.atom'
import { buttonTypes, iconDirection } from '../../app/constants/constants.app'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { addToCart, decrementQuantity } from '../../app/features/cart/cart.slice'

const ProductDetailsPage = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<IProduct>({} as IProduct)
  const cartState = useAppSelector((state) => state.cartSlice)
  const dispatch = useAppDispatch()

  const fetchProduct = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ product: IProduct }>(`/products/${id}`)
        .then((response) => {
          console.log('====================================')
          console.log({ response: response.data })
          console.log('====================================')
          setProduct(response.data.product)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchProduct()
  }, [fetchProduct])

  return (
    <>
      <NavbarOrganism />
      <div className={`${globals['full-width']} ${globals['p-desktop']} ${styles.details}`}>
        {loading ? <Loader /> : ''}
        {!loading && product._id ? (
          <div className={`${globals.flex} ${globals['gap-24']} ${styles.details__content}`}>
            <div className={styles.image}>
              <img src={product.imageUrl} width='100%' height={240} alt={product.name} />
            </div>
            <div
              className={`${styles.content} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
            >
              <h2>{product.name}</h2>
              <h3>
                At <span>{product.price}</span> XAF per <span>{product.unit}</span>
              </h3>
              <p>{product.description}</p>

              <h5>Posted By:</h5>

              <div
                className={`${styles.user} ${globals['a-center']} ${globals.flex} ${globals['gap-16']}`}
              >
                <div>
                  <div>
                    <div className={styles.image}>
                      {product.userId.profileURL ? (
                        <img
                          width={150}
                          height={150}
                          src={product.userId.profileURL}
                          alt={product.userId.firstName}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <h2>
                  {product.userId.firstName} {product.userId.lastName}
                </h2>
              </div>

              {cartState.products.find((prod) => prod._id === product._id) === undefined ||
              (cartState.products.find((prod) => prod._id === product._id) as IProduct)
                .cartCount === 0 ? (
                <></>
              ) : (
                <h3 className={styles.price}>
                  <span>
                    Total Price:{' '}
                    {(cartState.products.find((prod) => prod._id === product._id)
                      ?.cartCount as number) * product.price}
                  </span>
                  XAF
                </h3>
              )}

              <div
                className={`${globals.flex} ${styles.btns} ${globals['a-center']} ${globals['gap-12']}`}
              >
                {cartState.products.find((prod) => prod._id === product._id) === undefined ||
                (cartState.products.find((prod) => prod._id === product._id) as IProduct)
                  .cartCount === 0 ? (
                  <ButtonAtom
                    type={buttonTypes.primary}
                    onClick={() => {
                      dispatch(addToCart({ product }))
                    }}
                    btnText='Add to Cart'
                  />
                ) : (
                  <>
                    <ButtonAtom
                      iconDir={iconDirection.right}
                      btnText='-'
                      type={buttonTypes.primary}
                      onClick={() => {
                        dispatch(decrementQuantity({ id: product._id }))
                      }}
                    />
                    <h4>
                      {cartState.products.find((prod) => prod._id === product._id)?.cartCount}
                    </h4>
                    <ButtonAtom
                      onClick={() => {
                        dispatch(addToCart({ product }))
                      }}
                      btnText='+'
                      type={buttonTypes.primary}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <FooterOrganism />
    </>
  )
}

export default ProductDetailsPage
