import React from 'react'
import { IconType } from '../../types/icon.type'

const FilledHeartIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 28.4667L14.0667 26.7067C7.20002 20.48 2.66669 16.3733 2.66669 11.3333C2.66669 7.22667 5.89335 4 10 4C12.32 4 14.5467 5.08 16 6.78667C17.4534 5.08 19.68 4 22 4C26.1067 4 29.3334 7.22667 29.3334 11.3333C29.3334 16.3733 24.8 20.48 17.9334 26.72L16 28.4667Z'
        fill={color}
      />
    </svg>
  )
}

export default FilledHeartIcon
