import React from 'react'
import { IconType } from '../../types/icon.type'

const ShoppingCartIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.7333 17.3333C21.7333 17.3333 22.6133 16.7867 23.0666 15.96L27.84 7.30667C28.3333 6.42667 27.6933 5.33334 26.68 5.33334H6.94665L5.69331 2.66667H1.33331V5.33334H3.99998L8.79998 15.4533L6.99998 18.7067C6.02665 20.4933 7.30665 22.6667 9.33331 22.6667H25.3333V20H9.33331L10.8 17.3333H20.7333ZM8.21331 8.00001H24.4133L20.7333 14.6667H11.3733L8.21331 8.00001ZM9.33331 24C7.86665 24 6.67998 25.2 6.67998 26.6667C6.67998 28.1333 7.86665 29.3333 9.33331 29.3333C10.8 29.3333 12 28.1333 12 26.6667C12 25.2 10.8 24 9.33331 24ZM22.6666 24C21.2 24 20.0133 25.2 20.0133 26.6667C20.0133 28.1333 21.2 29.3333 22.6666 29.3333C24.1333 29.3333 25.3333 28.1333 25.3333 26.6667C25.3333 25.2 24.1333 24 22.6666 24Z'
        fill={color}
      />
    </svg>
  )
}

export default ShoppingCartIcon
