import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import changePasswordSlice from '../features/auth/slices/change-password.slice'
import forgotPasswordSlice from '../features/auth/slices/forgot-password.slice'
import loginUserSlice from '../features/auth/slices/login-user.slice'
import registerUserSlice from '../features/auth/slices/register-user.slice'
import verifyEmailSlice from '../features/auth/slices/verify-email.slice'
import notificationSlice from '../features/notification/notification.slice'
import cartSlice from '../features/cart/cart.slice'
import toolCartSlice from '../features/tool-cart/tool-cart.slice'

const persistConfig = {
  key: 'root',
  storage,
}

const persistToolConfig = {
  key: 'root-tool',
  storage,
}

const persistedReducer = persistReducer(persistConfig, cartSlice)
const persistedToolReducer = persistReducer(persistToolConfig, toolCartSlice)

// eslint-disable-next-line
export default {
  loginUserSlice,
  registerUserSlice,
  verifyEmailSlice,
  forgotPasswordSlice,
  changePasswordSlice,
  notificationSlice,
  cartSlice: persistedReducer,
  toolCartSlice: persistedToolReducer,
}
