import React from 'react'

import globals from '../../../global.module.css'
import styles from './button.module.css'

import { buttonTypes, iconDirection } from '../../../app/constants/constants.app'

type ButtonProps = {
  btnText: string
  iconDir?: iconDirection
  type: buttonTypes
  icon?: JSX.Element
  fullWidth?: boolean
  onClick?: (key?: string | React.MouseEvent<HTMLButtonElement>) => void
  key?: string
  fontSize?: string
  submit?: string
  borderRadius?: string
  height?: string
  bgColor?: string
  disabled?: boolean
  color?: string
  fontWeight?: number
}

const ButtonAtom = ({
  btnText,
  fontSize,
  iconDir,
  type,
  icon,
  key,
  onClick,
  disabled,
  bgColor,
  fullWidth,
  submit,
  borderRadius,
  height,
  color,
  fontWeight,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      type={submit as 'submit'}
      className={`${styles.button} ${
        fullWidth !== undefined && fullWidth === true ? globals['full-width'] : ''
      } ${globals.flex} ${globals['center-items']} ${globals['gap-12']} ${
        type === buttonTypes.primary
          ? styles.primary
          : type === buttonTypes.primaryOutlined
          ? styles.primary__outlined
          : type === buttonTypes.secondary
          ? styles.secondary
          : type === buttonTypes.secondaryOutlined
          ? styles.secondary__outlined
          : ''
      }`}
      onClick={() => {
        if (onClick !== undefined) {
          onClick(key)
        }
      }}
      style={{
        fontSize: fontSize !== undefined ? fontSize : '16px',
        borderRadius: borderRadius !== undefined ? borderRadius : '8px',
        height: height !== undefined ? height : 'fit-content',
        background: bgColor !== undefined ? bgColor : '',
        color,
        fontWeight,
      }}
    >
      {iconDir === iconDirection.left ? icon : ''}
      <span
        style={{
          fontWeight,
        }}
        className={styles.button__text}
      >
        {' '}
        {btnText}
      </span>
      {iconDir === iconDirection.right ? icon : ''}
    </button>
  )
}

export default ButtonAtom
