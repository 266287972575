import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// general imports
import { loginUserThunk } from '../thunks/auth.thunk'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api.type'
import { LoginResponseType } from '../../../../types/auth.type'
import { LocalStorageService } from '../../../../service/localstorage.service'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'

export type LoginUserState = {
  requestResponse: ApiRequestDataType<LoginResponseType>
}

const localStorageService = new LocalStorageService()

const authData = localStorageService.getLoginData()

export const initialState: LoginUserState = {
  requestResponse: {
    status: authData ? ApiRequestStatus.FULFILLED : ApiRequestStatus.IDLE,
    data: authData ? JSON.parse(authData) : ({} as LoginResponseType),
  },
}

const loginUserSlice = createSlice({
  name: 'loginUserSlice',
  initialState: initialState,
  reducers: {
    resetLoginUserState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE),
        (state.requestResponse.data = {} as LoginResponseType)
    },

    resetLoginAuthStatus: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
    },

    updateAuthStateData: (state, action: PayloadAction<{ data: LoginResponseType }>) => {
      state.requestResponse.data = action.payload.data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = {} as LoginResponseType
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(loginUserThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        publicApiRequest().defaults.headers.common.Authorization = `Bearer ${action.payload.accessToken}`

        state.requestResponse.data = action.payload
        localStorageService.storeLoginData(action.payload)
        window.location.reload()
      })
      .addCase(loginUserThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetLoginUserState, resetLoginAuthStatus, updateAuthStateData } =
  loginUserSlice.actions
export default loginUserSlice.reducer
