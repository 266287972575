import HeroSvg from './hero_image.svg'
import Avatar from './avatar.jpg'
import HeroBg from './hero.png'
import Farmerpic from './farmer.png'
import Investorpic from './investor.png'
import Manufacturingpic from './male-manufacturer.png'
import Customerpic from './customer-pic.png'
import Machinerypic from './male-investor.png'
import HeroBgGroup from './herobg.png'
import EmptySvg from './empty.svg'
import EmptySearch from './empty.png'

export const staticSvgs = {
  home: {
    HeroSvg,
    HeroBg,
    HeroBgGroup,
  },
  headerAvatar: {
    Avatar,
  },
  dashboard: {
    EmptySvg,
    EmptySearch,
  },
  heroPics: {
    Farmerpic,
    Investorpic,
    Customerpic,
    Machinerypic,
    Manufacturingpic,
  },
}
