import React from 'react'

import AuthLayout from '../../../layouts/auth/Auth.layout'
import ForgotPasswordTemplate from '../../../components/templates/auth/ForgotPassword.template'

const ForgotPasswordPage = () => {
  return (
    <AuthLayout catchText='Follow simple and easy steps to reset your password.'>
      <ForgotPasswordTemplate />
    </AuthLayout>
  )
}

export default ForgotPasswordPage
