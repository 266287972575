import React from 'react'

import globals from '../../../../global.module.css'
import styles from '../view.module.css'

const ProjectsView = () => {
  return (
    <div className={`${styles.view}`}>
      <h2>Farming Project</h2>
    </div>
  )
}

export default ProjectsView
