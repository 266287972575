import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './investorstemp.module.css'

import Loader from '../../loader/Loader'
import { publicApiRequest } from '../../../lib/hooks/axios-instance'
import { User } from '../../../types/user-types/user.type'
import { IconsRepository } from '../../../repository/icons/icons.repository'
import { IProduct } from '../../../types/product-types/product.type'
import ProductCardAtom from '../../atoms/product-card/ProductCard.atom'
import { IProject } from '../../../types/project-types/project.type'
import { useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../app/store/store'

const FarmersViewTemplate = () => {
  const [farmers, setFarmers] = useState<User[]>([])
  const [selectedFarmer, setSelectedFarmer] = useState<User>({} as User)
  const [projects, setProjects] = useState<IProject[]>([])
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const projectsID = params.get('projects')

  console.log('====================================')
  console.log({ projectsID })
  console.log('====================================')

  const fetchUsersProjects = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ projects: IProject[] }>(
          `/projects/farmer-projects/${projectsID ? projectsID : selectedFarmer._id}`,
        )
        .then((response) => {
          setProjects(response.data.projects)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const authState = useAppSelector((state: RootState) => state.loginUserSlice)

  const fetchUsers = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ farmers: User[] }>('/farmers')
        .then((response) => {
          setFarmers(response.data.farmers)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  useEffect(() => {
    if ((projectsID !== null && projectsID?.length > 0) || selectedFarmer._id) {
      fetchUsersProjects()
    }
  }, [selectedFarmer, projectsID])

  return (
    <section className={`${styles.template} ${globals['full-width']}`}>
      <div className={styles.head}>
        <h2>Browse Farmers</h2>
        <h3>
          Discover amazing farmers projects and invest into them today. No to hunger and poverty!
        </h3>
      </div>

      <div className={`${globals['full-width']} ${globals.flex} ${globals['center-items']}`}>
        {loading ? <Loader /> : ''}
      </div>
      {!loading && !projectsID && farmers.length > 0 ? (
        <div className={`${styles.farmers} ${globals['full-width']}`}>
          {farmers.length > 0 &&
            !loading &&
            farmers.map((farmer, index) => {
              return (
                <article
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSelectedFarmer(farmer)
                    navigate(`/investor-dashboard?projects=${farmer._id}`)
                  }}
                  key={index}
                  className={styles.card}
                >
                  <div className={styles.image}>
                    <img src={farmer.imageUrl} alt={farmer.firstName} />
                  </div>
                  <div className={styles.body}>
                    <h3>
                      {farmer?.firstName} {farmer?.lastName}
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus earum
                      facilis laboriosam, dignissimos reiciendis incidunt.
                    </p>

                    <div
                      style={{ marginTop: '20px' }}
                      className={`${styles.rating} ${globals.flex} ${globals['a-center']}`}
                    >
                      <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                      <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                      <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                      <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                      <p>(56 People)</p>
                    </div>
                  </div>
                </article>
              )
            })}
        </div>
      ) : (
        ''
      )}
      {!loading &&
      projects.length > 0 &&
      projectsID !== undefined &&
      projectsID !== null &&
      projectsID.length > 0 ? (
        <div
          style={{
            marginTop: '30px',
          }}
          className={`${styles.farmers} ${globals['full-width']}`}
        >
          {projects.map((proj, index) => {
            return (
              <ProductCardAtom
                key={index}
                productName={proj.name}
                price={`${proj.totalAmountNeeded}`}
                unit=''
                img={''}
                descr={proj.description}
                project={true}
                projectId={proj._id}
                investorsIds={proj.investorsIds}
                userId={proj.userId as User}
                req={proj.requirements}
                investorId={authState.requestResponse.data?.user._id}
              />
            )
          })}
        </div>
      ) : (
        ''
      )}
    </section>
  )
}

export default FarmersViewTemplate
