import React from 'react'
import { IconType } from '../../types/icon.type'

const EditIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6667 2.66667H12C5.33335 2.66667 2.66669 5.33333 2.66669 12V20C2.66669 26.6667 5.33335 29.3333 12 29.3333H20C26.6667 29.3333 29.3334 26.6667 29.3334 20V17.3333'
        stroke={color}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.3867 4.02667L10.88 14.5333C10.48 14.9333 10.08 15.72 10 16.2933L9.42669 20.3067C9.21336 21.76 10.24 22.7733 11.6934 22.5733L15.7067 22C16.2667 21.92 17.0534 21.52 17.4667 21.12L27.9734 10.6133C29.7867 8.8 30.64 6.69333 27.9734 4.02667C25.3067 1.36 23.2 2.21333 21.3867 4.02667Z'
        stroke={color}
        strokeWidth={2}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.88 5.53333C20.3221 7.10329 21.16 8.53342 22.3133 9.68673C23.4666 10.84 24.8967 11.6779 26.4667 12.12'
        stroke={color}
        strokeWidth={2}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default EditIcon
