import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export enum NotificationStatus {
  ACTIVE,
  INACTIVE,
}

export enum NotificationTitle {
  EMPTY,
  SUCCESS,
  ERROR,
}

type InitialStatetype = {
  status: NotificationStatus
  message: string
  title: NotificationTitle
}

export const initialState: InitialStatetype = {
  title: NotificationTitle.EMPTY,
  message: '',
  status: NotificationStatus.INACTIVE,
}

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: initialState,
  reducers: {
    resetNotificationState: (state) => {
      state.message = ''
      state.status = NotificationStatus.INACTIVE
      state.title = NotificationTitle.EMPTY
    },

    triggerNotification: (
      state,
      action: PayloadAction<{ title: NotificationTitle; message: string }>,
    ) => {
      state.status = NotificationStatus.ACTIVE
      state.message = action.payload.message
      state.title = action.payload.title
    },
  },
  extraReducers: (builder) => {
    builder
  },
})

export const { resetNotificationState, triggerNotification } = notificationSlice.actions
export default notificationSlice.reducer
