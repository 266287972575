import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ITool } from '../../../types/tool-types/tool.types'

type InitialStatetype = {
  tools: ITool[]
}

export const initialState: InitialStatetype = {
  tools: [],
}

const toolCartSlice = createSlice({
  name: 'toolCartSlice',
  initialState: initialState,
  reducers: {
    resetToolCartState: (state) => {
      state.tools = []
    },

    addToolToCart: (state, action: PayloadAction<{ product: ITool }>) => {
      const productInCart = state.tools.find((item) => item._id === action.payload.product._id)

      if (productInCart !== undefined && productInCart.cartCount !== undefined) {
        productInCart.cartCount++
      } else {
        state.tools.push({ ...action.payload.product, cartCount: 1 })
      }
    },

    removeToolFromCart: (state, action: PayloadAction<{ id: string }>) => {
      const productNotMatchingId = state.tools.filter((item) => item._id !== action.payload.id)
      state.tools = productNotMatchingId
    },

    decrementToolQuantity: (state, action: PayloadAction<{ id: string }>) => {
      const productInCart = state.tools.find((item) => item._id === action.payload.id)

      if (productInCart !== undefined && productInCart.cartCount !== undefined) {
        productInCart.cartCount--
      }
    },
  },
  extraReducers: (builder) => {
    builder
  },
})

export const { addToolToCart, decrementToolQuantity, resetToolCartState, removeToolFromCart } =
  toolCartSlice.actions
export default toolCartSlice.reducer
