import { Component } from 'react'
import Chart from 'react-apexcharts'

import styles from './chart.module.css'

type MyProps = {
  // using `interface` is also ok
  type?: string
}

class ChartComponent extends Component<MyProps> {
  // eslint-disable-next-line
  constructor(props: any) {
    super(props)

    this.state = {
      options: {
        chart: {
          id: 'basic-bar',
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
        },
      },
      series: [
        {
          name: 'series-1',
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
    }
  }

  render() {
    return (
      <div className={styles.chart}>
        <div className='row'>
          <div className='mixed-chart'>
            <Chart
              options={{
                stroke: {
                  curve: 'smooth',
                },
                fill: {
                  type: this.props.type === 'area' ? 'gradient' : 'solid',
                },
                dataLabels: {
                  style: {
                    fontSize: '0px',
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                  },
                },
                colors: ['#E8E8E8', '#00875a'],
                chart: {
                  id: 'basic-bar',
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                xaxis: {
                  categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
                  labels: {
                    style: {
                      fontSize: '14px',
                    },
                  },
                },
                yaxis: {
                  labels: {
                    style: {
                      fontSize: '14px',
                    },
                  },
                },
                legend: {
                  position: 'top',
                  horizontalAlign: 'right',
                  fontSize: '14px',
                },
              }}
              series={[
                {
                  name: 'series-1',
                  data: [30, 40, 45, 50, 49, 60, 70, 91],
                  // color: 'red',
                },
                {
                  name: 'series-2',
                  data: [20, 10, 15, 65, 80, 30, 70, 91],
                },
              ]}
              type={this.props.type as 'bar' | 'line' | 'area'}
              height='273px'
              width='100%'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ChartComponent
