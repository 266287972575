import React from 'react'

import globals from '../../../global.module.css'
import styles from './imageupload.module.css'

type Props = {
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>
  image: File | undefined | string
}

const ImageUploadAtom: React.FC<Props> = ({ setImage, image }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0] as File)
    }
  }
  return (
    <div
      className={`${globals['full-width']} ${globals['flex-column']} ${globals.flex} ${globals['center-items']} ${styles.image}`}
    >
      <div>
        <h3>Upload Image</h3>
      </div>
      <input className={styles.input} type='file' name='image' id='image' onChange={onChange} />
      {image !== undefined && image !== null && (typeof image === 'string' || image.name) ? (
        <img
          src={typeof image === 'string' ? image : URL.createObjectURL(image)}
          alt={image as string}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default ImageUploadAtom
