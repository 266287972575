import React from 'react'

import globals from '../../../global.module.css'
import styles from './toolcard.module.css'

import { ITool } from '../../../types/tool-types/tool.types'

type Props = {
  tool: ITool
}

const ToolCardMolecule: React.FC<Props> = ({ tool }) => {
  return (
    <article className={`${globals['full-width']} ${styles.card}`}>
      <div className={`${styles.image}`}>
        <img src={tool.imageUrl} alt={tool.name} />
      </div>

      <div className={`${globals['full-width']} ${styles.data}`}>
        <h2>{tool.name}</h2>
        <p>{tool.description}</p>

        <p className={styles.price}>
          <span>Price: </span>
          {tool.price} FCFA
        </p>
      </div>
    </article>
  )
}

export default ToolCardMolecule
