import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './investorstemp.module.css'

import { publicApiRequest } from '../../../lib/hooks/axios-instance'
import { IProject } from '../../../types/project-types/project.type'
import Loader from '../../loader/Loader'
import SelectAtom from '../../atoms/input/Select.atom'
import { inputState } from '../../../app/constants/constants.app'
import ProductCardAtom from '../../atoms/product-card/ProductCard.atom'
import { User } from '../../../types/user-types/user.type'
import { useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../app/store/store'

const enum ProjectSubTracker {
  ALL = 'All Projects',
  CROWDFUNDING = 'Crowdfunding',
  SINGLE = 'Single Fund Source',
}

const ProjectsViewTemplate = () => {
  const [projects, setProjects] = useState<IProject[]>([])
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([])
  const [loading, setLoading] = useState(false)

  const [method, setMethod] = useState('')

  const onSelect = (value: string) => {
    setMethod(value)
  }

  const filterProducts = () => {
    if (method === ProjectSubTracker.ALL) {
      setFilteredProjects(projects)
    } else if (method === ProjectSubTracker.CROWDFUNDING) {
      const filter = projects.filter((project) => project.category === 'CROWDFUNDING')
      setFilteredProjects(filter)
    } else {
      const filter = projects.filter((project) => project.category === 'SINGLE')
      setFilteredProjects(filter)
    }
  }

  const fetchProjects = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ projects: IProject[] }>('/projects')
        .then((response) => {
          setProjects(response.data.projects)
          setFilteredProjects(response.data.projects)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  useEffect(() => {
    filterProducts()
  }, [method])

  /**
   * This is what should be displayed
   * The filtered products, as initially its same as all products
   * Until a filter selection is made.
   */

  const authState = useAppSelector((state: RootState) => state.loginUserSlice)

  return (
    <section className={`${styles.template} ${globals['full-width']}`}>
      <div className={styles.head}>
        <h2>Available Projects</h2>
      </div>
      <div
        style={{
          width: 'fit-content',
        }}
      >
        <SelectAtom
          onSelect={onSelect}
          placeholder='Select Funding Type'
          state={inputState.normal}
          list={['All Projects', 'Crowdfunding', 'Single Fund Source']}
          type='text'
          value={method}
          name='method'
        />
      </div>
      <div className={`${globals['full-width']} ${globals.flex} ${globals['center-items']}`}>
        {loading ? <Loader /> : ''}
      </div>
      {!loading && filteredProjects.length > 0 ? (
        <div
          style={{
            marginTop: '30px',
          }}
          className={`${styles.farmers} ${globals['full-width']}`}
        >
          {filteredProjects.map((proj, index) => {
            return (
              <ProductCardAtom
                key={index}
                productName={proj.name}
                price={`${proj.totalAmountNeeded}`}
                unit=''
                img={''}
                descr={proj.description}
                project={true}
                userId={proj.userId as User}
                req={proj.requirements}
                investorId={authState.requestResponse.data?.user._id}
                projectId={proj._id}
                investorsIds={proj.investorsIds}
              />
            )
          })}
        </div>
      ) : (
        ''
      )}
    </section>
  )
}

export default ProjectsViewTemplate
