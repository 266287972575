import { createSlice } from '@reduxjs/toolkit'

// general imports
import { registerUserThunk } from '../thunks/auth.thunk'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api.type'

export type RegisterUserState = {
  requestResponse: ApiRequestDataType
}

export const initialState: RegisterUserState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: {},
  },
}

const registerUserSlice = createSlice({
  name: 'registerUserSlice',
  initialState: initialState,
  reducers: {
    resetRegisteredUserState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = {})
    },
    resetRegAuthStatus: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = {}
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(registerUserThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED

        state.requestResponse.data = action.payload
      })
      .addCase(registerUserThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetRegisteredUserState, resetRegAuthStatus } = registerUserSlice.actions
export default registerUserSlice.reducer
