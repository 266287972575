import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api.type'
import { changePasswordThunk } from '../thunks/auth.thunk'

export type ChangePasswordState = {
  requestResponse: ApiRequestDataType
}

export const initialState: ChangePasswordState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: {},
  },
}

const changePasswordSlice = createSlice({
  name: 'changePasswordSlice',
  initialState: initialState,
  reducers: {
    changePasswordState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = {})
    },
    resetChangeAuthStatus: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = {}
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED

        state.requestResponse.data = action.payload
      })
      .addCase(changePasswordThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { changePasswordState, resetChangeAuthStatus } = changePasswordSlice.actions
export default changePasswordSlice.reducer
