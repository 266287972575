import React from 'react'

import { IconType } from '../../types/icon.type'

const StrokeEyeIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.6293 20.376C12.376 20.376 12.1227 20.2827 11.9227 20.0827C11.3849 19.5481 10.9584 18.9124 10.6678 18.2121C10.3772 17.5118 10.2281 16.7609 10.2293 16.0027C10.2293 12.816 12.816 10.2293 16.0027 10.2293C17.536 10.2293 18.9893 10.8293 20.0827 11.9227C20.2699 12.1102 20.3751 12.3643 20.3751 12.6293C20.3751 12.8943 20.2699 13.1485 20.0827 13.336L13.336 20.0827C13.136 20.2827 12.8827 20.376 12.6293 20.376ZM16.0027 12.2293C15.3428 12.2308 14.6949 12.405 14.1233 12.7347C13.5517 13.0644 13.0764 13.538 12.7446 14.1084C12.4129 14.6788 12.2363 15.3261 12.2325 15.9859C12.2286 16.6458 12.3976 17.2951 12.7227 17.8693L17.8693 12.7227C17.3093 12.4027 16.6693 12.2293 16.0027 12.2293Z'
        fill={color}
      />
      <path
        d='M7.46667 24.68C7.24001 24.68 7.00001 24.6 6.81334 24.44C5.38667 23.2267 4.10667 21.7333 3.01334 20C1.60001 17.8 1.60001 14.2133 3.01334 12C6.26667 6.90666 11 3.97333 16 3.97333C18.9333 3.97333 21.8267 4.98666 24.36 6.89333C24.5722 7.05246 24.7124 7.28936 24.75 7.55191C24.7875 7.81446 24.7191 8.08115 24.56 8.29333C24.4009 8.5055 24.164 8.64577 23.9014 8.68328C23.6389 8.72078 23.3722 8.65246 23.16 8.49333C20.9733 6.83999 18.4933 5.97333 16 5.97333C11.6933 5.97333 7.57334 8.55999 4.69334 13.08C3.69334 14.64 3.69334 17.36 4.69334 18.92C5.69334 20.48 6.84001 21.8267 8.10667 22.92C8.52001 23.28 8.57334 23.9067 8.21334 24.3333C8.02667 24.56 7.74667 24.68 7.46667 24.68ZM15.9987 28.0267C14.2253 28.0267 12.492 27.6667 10.8253 26.96C10.5817 26.857 10.3888 26.6617 10.2888 26.4168C10.1889 26.1719 10.19 25.8974 10.292 25.6533C10.5053 25.1467 11.092 24.9067 11.5987 25.12C13.012 25.72 14.492 26.0267 15.9853 26.0267C20.292 26.0267 24.412 23.44 27.292 18.92C28.292 17.36 28.292 14.64 27.292 13.08C26.8787 12.4267 26.4253 11.8 25.9453 11.2133C25.7795 11.0056 25.702 10.741 25.7294 10.4766C25.7569 10.2123 25.8871 9.96927 26.092 9.79999C26.1942 9.71623 26.3119 9.65353 26.4385 9.61554C26.565 9.57755 26.6978 9.56501 26.8293 9.57865C26.9607 9.59228 27.0881 9.63183 27.2041 9.69499C27.3202 9.75816 27.4225 9.8437 27.5053 9.94666C28.0253 10.5867 28.532 11.28 28.9853 12C30.3987 14.2 30.3987 17.7867 28.9853 20C25.732 25.0933 20.9987 28.0267 15.9987 28.0267Z'
        fill={color}
      />
      <path
        d='M16.9213 21.6907C16.4547 21.6907 16.028 21.3573 15.9347 20.8773C15.828 20.3307 16.188 19.8107 16.7347 19.7173C18.2013 19.4507 19.428 18.224 19.6947 16.7573C19.8013 16.2107 20.3213 15.864 20.868 15.9573C21.4147 16.064 21.7747 16.584 21.668 17.1307C21.2413 19.4373 19.4013 21.264 17.108 21.6907C17.0413 21.6773 16.988 21.6907 16.9213 21.6907ZM2.668 30.332C2.41466 30.332 2.16133 30.2387 1.96133 30.0387C1.77568 29.8503 1.6716 29.5965 1.6716 29.332C1.6716 29.0675 1.77568 28.8137 1.96133 28.6253L11.9213 18.6653C12.308 18.2787 12.948 18.2787 13.3347 18.6653C13.7213 19.052 13.7213 19.692 13.3347 20.0787L3.37466 30.0387C3.17466 30.2387 2.92133 30.332 2.668 30.332ZM19.372 13.628C19.1187 13.628 18.8653 13.5347 18.6653 13.3347C18.4794 13.1465 18.3751 12.8926 18.3751 12.628C18.3751 12.3634 18.4794 12.1095 18.6653 11.9213L28.6253 1.96133C29.012 1.57466 29.652 1.57466 30.0387 1.96133C30.4253 2.34799 30.4253 2.98799 30.0387 3.37466L20.0787 13.3347C19.8787 13.5347 19.6253 13.628 19.372 13.628Z'
        fill={color}
      />
    </svg>
  )
}

export default StrokeEyeIcon
