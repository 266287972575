import React from 'react'
import { IconType } from '../../types/icon.type'

const DownloadIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.8 13.3867C24.8933 8.78668 20.8533 5.33334 16 5.33334C12.1467 5.33334 8.8 7.52001 7.13333 10.72C3.12 11.1467 0 14.5467 0 18.6667C0 23.08 3.58667 26.6667 8 26.6667H25.3333C29.0133 26.6667 32 23.68 32 20C32 16.48 29.2667 13.6267 25.8 13.3867ZM22.6667 17.3333L16 24L9.33333 17.3333H13.3333V12H18.6667V17.3333H22.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default DownloadIcon
