import React from 'react'
import { IconType } from '../../types/icon.type'

const ShareIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 21.44C22.9867 21.44 22.08 21.84 21.3867 22.4667L11.88 16.9333C11.9467 16.6267 12 16.32 12 16C12 15.68 11.9467 15.3733 11.88 15.0667L21.28 9.58666C22 10.2533 22.9467 10.6667 24 10.6667C26.2133 10.6667 28 8.87999 28 6.66666C28 4.45332 26.2133 2.66666 24 2.66666C21.7867 2.66666 20 4.45332 20 6.66666C20 6.98666 20.0533 7.29332 20.12 7.59999L10.72 13.08C10 12.4133 9.05333 12 8 12C5.78667 12 4 13.7867 4 16C4 18.2133 5.78667 20 8 20C9.05333 20 10 19.5867 10.72 18.92L20.2133 24.4667C20.1467 24.7467 20.1067 25.04 20.1067 25.3333C20.1067 27.48 21.8533 29.2267 24 29.2267C26.1467 29.2267 27.8933 27.48 27.8933 25.3333C27.8933 23.1867 26.1467 21.44 24 21.44Z'
        fill={color}
      />
    </svg>
  )
}

export default ShareIcon
