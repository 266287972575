import React from 'react'
import { IconType } from '../../types/icon.type'

const MoneyIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.7334 14.5333C12.7067 13.7467 11.7334 12.9333 11.7334 11.6667C11.7334 10.2133 13.08 9.2 15.3334 9.2C17.2267 9.2 18.1734 9.92 18.52 11.0667C18.68 11.6 19.12 12 19.68 12H20.08C20.96 12 21.5867 11.1333 21.28 10.3067C20.72 8.73333 19.4134 7.42667 17.3334 6.92V6C17.3334 4.89333 16.44 4 15.3334 4C14.2267 4 13.3334 4.89333 13.3334 6V6.88C10.7467 7.44 8.66669 9.12 8.66669 11.6933C8.66669 14.7733 11.2134 16.3067 14.9334 17.2C18.2667 18 18.9334 19.1733 18.9334 20.4133C18.9334 21.3333 18.28 22.8 15.3334 22.8C13.1334 22.8 12 22.0133 11.56 20.8933C11.36 20.3733 10.9067 20 10.36 20H9.98669C9.09335 20 8.46669 20.9067 8.80002 21.7333C9.56002 23.5867 11.3334 24.68 13.3334 25.1067V26C13.3334 27.1067 14.2267 28 15.3334 28C16.44 28 17.3334 27.1067 17.3334 26V25.1333C19.9334 24.64 22 23.1333 22 20.4C22 16.6133 18.76 15.32 15.7334 14.5333Z'
        fill={color}
      />
    </svg>
  )
}

export default MoneyIcon
