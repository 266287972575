import React from 'react'

import globals from '../../../../global.module.css'
import styles from './orderlist.module.css'

import OrderCardMolecule, { OrderStatus } from '../../../molecules/order-card/OrderCard.molecule'
import { IOrder } from '../../../../types/order-types/order.type'
import { AccountTypes } from '../../../../repository/data/accountType'

type Props = {
  status: OrderStatus
  orders?: IOrder[]
  accountType?: AccountTypes
  setOrderId: React.Dispatch<React.SetStateAction<string>>
}

const OrderListTemplate: React.FC<Props> = ({ status, orders, setOrderId }) => {
  return (
    <div className={`${styles.orders} ${globals['gap-16']}`}>
      {orders !== undefined &&
        orders?.length > 0 &&
        orders.map((order, index) => {
          return (
            <OrderCardMolecule setOrderId={setOrderId} order={order} status={status} key={index} />
          )
        })}
    </div>
  )
}

export default OrderListTemplate
