import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../../global.module.css'
import styles from './storefront.module.css'

import BannerAtom from '../../../../components/atoms/banner/Banner.atom'
import { staticSvgs } from '../../../../app/assets/images'
import ProductCardAtom from '../../../../components/atoms/product-card/ProductCard.atom'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import ModalMolecule from '../../../../components/molecules/modal/Modal.molecule'
import LabeledInputMolecule from '../../../../components/molecules/labeled-input/LabeledInput.molecule'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes, inputState } from '../../../../app/constants/constants.app'
import UseForm from '../../../../lib/hooks/use-form'
import { IProject, requirementType } from '../../../../types/project-types/project.type'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import SelectAtom from '../../../../components/atoms/input/Select.atom'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { isAxiosError } from 'axios'
import Loader from '../../../../components/loader/Loader'
import { User } from '../../../../types/user-types/user.type'

const StoreFrontDashboardPage = () => {
  const [adding, setAdding] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState<IProject[]>([])

  const dispatch = useAppDispatch()

  const [method, setMethod] = useState('')
  const { requestResponse } = useAppSelector((state) => state.loginUserSlice)
  const { data } = requestResponse

  const onSelect = (value: string) => {
    setMethod(value)
  }

  const { form, updateForm } = UseForm<IProject>(
    {
      name: '',
      description: '',
      category: '',
      requirements: [] as requirementType[],
      userId: '',
      openForInvestment: true,
      totalAmountNeeded: 0,
    },
    setTrigger,
    trigger,
  )

  const [requirements, setRequirements] = useState({
    toolName: '',
    toolDescription: '',
    amount: 0,
  } as requirementType)

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRequirements({ ...requirements, [e.target.name]: e.target.value })
  }

  const addRequirement = () => {
    const temp = form.requirements
    temp.push(requirements)

    updateForm('requirements', temp)
  }

  const removeRequirement = (name: string) => {
    const temp = form.requirements.filter((req) => req.toolName !== name)

    updateForm('requirements', temp)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const totalAmount = form.requirements.reduce((sum, i) => {
      return sum + i.amount
    }, 0)

    const { requirements } = form

    const body = {
      name: form.name,
      description: form.description,
      totalAmountNeeded: `${totalAmount}`,
      openForInvestment: `${form.openForInvestment}`,
      userId: data?.user._id,
      category: form.category,
      requirements,
    }

    try {
      await publicApiRequest()
        .post('/projects/add-new-project', body)
        .then((_response) => {
          setAdding(false)
          dispatch(
            triggerNotification({
              message: 'Project created successfully.',
              title: NotificationTitle.SUCCESS,
            }),
          )
          fetchProjects()
        })
    } catch (e) {
      console.log(e)

      if (isAxiosError(e)) {
        console.log({ res: e?.response })

        dispatch(
          triggerNotification({
            message: e.response?.data.error,
            title: NotificationTitle.ERROR,
          }),
        )
      } else {
        dispatch(
          triggerNotification({
            message: 'An error occured while creating your project. Please try again.',
            title: NotificationTitle.ERROR,
          }),
        )
      }
    } finally {
      setLoading(false)
    }
  }

  const fetchProjects = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ projects: IProject[] }>('/projects')
        .then((response) => {
          setProjects(response.data.projects)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  useEffect(() => {
    updateForm('category', method)
  }, [method])

  return (
    <div className={`${styles.store__front}`}>
      <BannerAtom
        setAdding={setAdding}
        img={
          requestResponse.data?.user?.profileURL
            ? requestResponse.data?.user?.profileURL
            : staticSvgs.headerAvatar.Avatar
        }
        farmName={`${data?.user.firstName}`}
      />

      {loading ? <Loader /> : ''}

      <div
        className={`${globals.flex} ${styles.container} ${globals['flex-column']} ${globals['gap-24']}`}
      >
        <div className={`${styles.grid} ${globals['gap-16']}`}>
          {projects !== undefined &&
            projects.length > 0 &&
            projects.map((project, index) => (
              <Link to='/' key={index}>
                <div className={styles.box}>
                  <ProductCardAtom
                    productName={project.name}
                    price={`${project.totalAmountNeeded}`}
                    unit=''
                    img={''}
                    userId={project.userId as User}
                    project={true}
                    descr={project.description}
                  />
                </div>
              </Link>
            ))}
        </div>
        <div>
          {adding ? (
            <ModalMolecule
              close={() => {
                setAdding(false)
              }}
            >
              <div
                className={`${styles.new_products} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
              >
                <LabeledInputMolecule
                  label='Project name'
                  placeholder='Yam Cultivation'
                  onChange={(e) => {
                    updateForm('name', e.target.value)
                  }}
                  state={inputState.normal}
                  type='text'
                  name='name'
                />
                <LabeledInputMolecule
                  label='Description'
                  placeholder='Fresh paw=paw'
                  onChange={(e) => {
                    updateForm('description', e.target.value)
                  }}
                  state={inputState.normal}
                  type='text'
                  name='description'
                />
                <SelectAtom
                  onSelect={onSelect}
                  placeholder='Select Category'
                  state={inputState.normal}
                  list={['CROWDFUNDING', 'SINGLE']}
                  type='text'
                  value={method}
                  name='method'
                />

                <h3 className={styles.req__title}>Project Requirements</h3>
                <LabeledInputMolecule
                  label='Tool name'
                  placeholder='Hoe'
                  onChange={onChange}
                  state={inputState.normal}
                  type='text'
                  name='toolName'
                />
                <LabeledInputMolecule
                  label='Tool description'
                  placeholder='For cultivation'
                  onChange={onChange}
                  state={inputState.normal}
                  type='text'
                  name='toolDescription'
                />
                <LabeledInputMolecule
                  label='Amount'
                  placeholder='5000 XAF'
                  onChange={onChange}
                  state={inputState.normal}
                  type='text'
                  name='amount'
                />
                <ButtonAtom
                  fontSize='1.8rem'
                  type={buttonTypes.primary}
                  btnText={'Add Requirements'}
                  fullWidth={false}
                  onClick={() => {
                    addRequirement()
                  }}
                />
                {form.requirements.map((req, index) => {
                  return (
                    <div key={index} className={styles.req}>
                      <h2
                        className={`${globals.flex} ${globals['gap-12']} ${globals['s-b']}
                      `}
                      >
                        <span>{req.toolName}</span>
                        <span
                          onClick={() => {
                            removeRequirement(req.toolName)
                          }}
                        >
                          <IconsRepository.LockIcon size={16} />
                        </span>
                      </h2>
                      <h3>
                        <span>Description: </span> {req.toolDescription}
                      </h3>
                      <h3>
                        <span>Amount: </span> {req.amount} FCFA
                      </h3>
                    </div>
                  )
                })}
                <div style={{ marginTop: '10px' }}>
                  <ButtonAtom
                    submit='submit'
                    fontSize='1.8rem'
                    type={buttonTypes.primary}
                    disabled={loading}
                    btnText={loading ? 'Loading...' : 'Create New Project'}
                    fullWidth={false}
                    onClick={() => {
                      handleSubmit()
                    }}
                  />
                </div>
              </div>
            </ModalMolecule>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default StoreFrontDashboardPage
