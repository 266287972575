import React, { Fragment, useState } from 'react'

import LabeledInputMolecule from '../../../molecules/labeled-input/LabeledInput.molecule'
import { iconDirection, inputState } from '../../../../app/constants/constants.app'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import { validatePassword } from '../../../../lib/utils'
import useWindowSize from '../../../../lib/hooks/use-window-soze'
import { RegModuleTypes } from '../../../../types/templates/register.type'

const UserinfoModuleTwo: React.FC<RegModuleTypes> = ({ form, updateForm }) => {
  const { width } = useWindowSize()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  return (
    <Fragment>
      <LabeledInputMolecule
        label='Phone Number'
        placeholder='+237 676 445 393'
        onChange={(e) => {
          updateForm('phoneNumber', e.target.value)
        }}
        state={form.phoneNumber.length <= 0 ? inputState.normal : inputState.success}
        type='text'
        name='phoneNumber'
        iconDir={[iconDirection.left]}
        iconLeft={<IconsRepository.PhoneIcon size={width < 1440 ? 20 : 24} />}
      />

      <LabeledInputMolecule
        label='Password'
        placeholder='********'
        onChange={(e) => {
          updateForm('password', e.target.value)
        }}
        state={
          form.password.length <= 0
            ? inputState.normal
            : validatePassword(form.password)
            ? inputState.error
            : inputState.success
        }
        type={showPassword ? 'text' : 'password'}
        name='password'
        iconDir={[iconDirection.left, iconDirection.right]}
        iconLeft={<IconsRepository.LockIcon size={width < 1440 ? 20 : 24} />}
        iconRight={
          showPassword ? (
            <IconsRepository.EyeIcon size={width < 1440 ? 20 : 24} />
          ) : (
            <IconsRepository.StrokeEyeIcon size={width < 1440 ? 20 : 24} />
          )
        }
        iconClick={() => {
          setShowPassword(!showPassword)
        }}
      />

      <LabeledInputMolecule
        label='Confirm Password'
        placeholder='********'
        onChange={(e) => {
          updateForm('confirmPassword', e.target.value)
        }}
        state={
          form.password.length <= 0
            ? inputState.normal
            : validatePassword(form.confirmPassword) && form.confirmPassword === form.password
            ? inputState.error
            : inputState.success
        }
        type={showConfirmPassword ? 'text' : 'password'}
        name='confirmPassword'
        iconDir={[iconDirection.left, iconDirection.right]}
        iconLeft={<IconsRepository.LockIcon size={width < 1440 ? 20 : 24} />}
        iconRight={
          showConfirmPassword ? (
            <IconsRepository.EyeIcon size={width < 1440 ? 20 : 24} />
          ) : (
            <IconsRepository.StrokeEyeIcon size={width < 1440 ? 20 : 24} />
          )
        }
        iconClick={() => {
          setShowConfirmPassword(!showConfirmPassword)
        }}
      />
    </Fragment>
  )
}

export default UserinfoModuleTwo
