import React from 'react'

import AuthLayout from '../../../layouts/auth/Auth.layout'
import ChangePasswordTemplate from '../../../components/templates/auth/ChangePassword.template'

const ChangePasswordPage = () => {
  return (
    <AuthLayout catchText='Follow simple and easy steps to reset your password.'>
      <ChangePasswordTemplate />
    </AuthLayout>
  )
}

export default ChangePasswordPage
