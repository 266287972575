import React, { useEffect, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './ordercard.module.css'

import { IconsRepository } from '../../../repository/icons/icons.repository'
import { IOrder } from '../../../types/order-types/order.type'
import { User } from '../../../types/user-types/user.type'

export enum OrderStatus {
  PAID = 'paid',
  PENDING = 'pending',
  DELIVERED = 'delivered',
}

type Props = {
  status: OrderStatus
  order?: IOrder
  setOrderId: React.Dispatch<React.SetStateAction<string>>
}

const OrderCardMolecule: React.FC<Props> = ({ status, order, setOrderId }) => {
  const [user, setUser] = useState<User>()

  useEffect(() => {
    // eslint-disable-next-line
    if ((order as object).hasOwnProperty('customerId')) {
      setUser(order?.customerId as User)
    } else {
      setUser(order?.farmerId as User)
    }
  }, [order])

  return (
    <div
      onClick={() => {
        setOrderId(order?._id as string)
      }}
      className={`${styles.order__card} ${
        status === OrderStatus.PAID
          ? styles.paid
          : status === OrderStatus.PENDING
          ? styles.pending
          : styles.delivered
      } ${globals['full-width']}`}
    >
      <div className={`${styles.indicator}`}>
        <IconsRepository.OpenInNewTabIcon color='var(--AGROX-LIGHT-GREEN-COLOR)' size={24} />
      </div>

      <div
        className={`${styles.customer} ${globals['a-center']} ${globals.flex} ${globals['gap-16']}`}
      >
        <div className={styles.profile}>
          {user !== undefined ? <img src={user.imageUrl} alt={user.firstName} /> : ''}
        </div>
        <div className={`${styles.details} ${globals.flex} ${globals['flex-column']}`}>
          {user !== undefined ? (
            <>
              <h2>
                {user?.firstName} {user?.lastName}
              </h2>
              <h3>Buea, Cameroon</h3>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className={`${styles.stats} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
      >
        <h3>
          Total Items: <span>{order?.productIds.length}</span>
        </h3>
        <h3>
          Total Price: <span>{order?.amount} FCFA</span>
        </h3>
        <h3>
          Delivery Fee: <span>100,000 FCFA</span>
        </h3>

        <div className={styles.total}>
          <h2>
            Grand Total: <span>{order?.amount} FCFA</span>
          </h2>
        </div>
      </div>
    </div>
  )
}

export default OrderCardMolecule
