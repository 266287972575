import React from 'react'
import { IconType } from '../../types/icon.type'

const ActiveBellIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 21.3333V14.6667C24 10.5733 21.8134 7.14668 18 6.24001V5.33334C18 4.22668 17.0934 3.33334 15.9867 3.33334C14.88 3.33334 14 4.22668 14 5.33334V6.24001C10.1734 7.14668 8.00002 10.56 8.00002 14.6667V21.3333L6.26669 23.0533C5.42669 23.8933 6.01335 25.3333 7.20002 25.3333H24.76C25.9467 25.3333 26.5467 23.8933 25.7067 23.0533L24 21.3333ZM15.9867 29.3333C17.4534 29.3333 18.6534 28.1333 18.6534 26.6667H13.32C13.32 28.1333 14.5067 29.3333 15.9867 29.3333ZM9.02669 6.30668C9.58669 5.80001 9.60002 4.93334 9.06669 4.40001C8.56002 3.89334 7.73335 3.88001 7.21335 4.37334C4.93335 6.45334 3.36002 9.28001 2.85335 12.4533C2.73335 13.2667 3.36002 14 4.18669 14C4.82669 14 5.38669 13.5333 5.49335 12.8933C5.89335 10.3067 7.17335 8.00001 9.02669 6.30668ZM24.8 4.37334C24.2667 3.88001 23.44 3.89334 22.9334 4.40001C22.4 4.93334 22.4267 5.78668 22.9734 6.29334C24.8134 7.98668 26.1067 10.2933 26.5067 12.88C26.6 13.52 27.16 13.9867 27.8134 13.9867C28.6267 13.9867 29.2667 13.2533 29.1334 12.44C28.6267 9.28001 27.0667 6.46668 24.8 4.37334Z'
        fill={color}
      />
    </svg>
  )
}

export default ActiveBellIcon
