import React, { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './verifyemail.module.css'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { verifyUserThunk } from '../../../app/features/auth/thunks/auth.thunk'
import { ApiRequestStatus } from '../../../types/api.type'
import { appConstants, buttonTypes } from '../../../app/constants/constants.app'
import ButtonAtom from '../../../components/atoms/button/Button.atom'

const VerifyEmailAuthPage = () => {
  const [params] = useSearchParams()
  const dispatch = useAppDispatch()

  const verifyEmailState = useAppSelector((state) => state.verifyEmailSlice)

  console.log('====================================')
  console.log({ params: params.get('userEmail') })
  console.log({ params: params.get('token') })
  console.log('====================================')

  const email = params.get('userEmail')
  const token = params.get('token')

  useEffect(() => {
    if (email !== null && token !== null) {
      dispatch(
        verifyUserThunk({
          email,
          token,
        }),
      )
    }
  }, [])

  return (
    <div
      className={`${globals['a-center']} #${globals['j-center']} ${globals['p-desktop']} ${globals.flex} ${styles.verify__email}`}
    >
      <div
        className={`${styles.container} ${globals['full-width']} ${globals.flex} ${globals['j-center']} ${globals['a-center']}`}
      >
        <h2>
          {email === null || token === null ? (
            'Broken Link'
          ) : verifyEmailState.requestResponse.status === ApiRequestStatus.PENDING ? (
            'Please wait...'
          ) : verifyEmailState.requestResponse.status === ApiRequestStatus.REJECTED ? (
            verifyEmailState.requestResponse.error?.message
          ) : verifyEmailState.requestResponse.status === ApiRequestStatus.FULFILLED ? (
            <div
              className={`${styles.success} ${globals.flex} ${globals['center-items']} ${globals['flex-column']}`}
            >
              <p>Successfully verified, Click on the link bellow to log into your account!</p>
              <Link className={styles.link} to={appConstants.routes.auth.login}>
                <ButtonAtom type={buttonTypes.primary} btnText='Login' />
              </Link>
            </div>
          ) : (
            'Broken Link'
          )}
        </h2>
      </div>
    </div>
  )
}

export default VerifyEmailAuthPage
