import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import globals from '../../global.module.css'
import styles from './cart.module.css'

import NavbarOrganism from '../../components/organisms/navbar/Navbar.organism'
import FooterOrganism from '../../components/organisms/footer/Footer.organism'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import ButtonAtom from '../../components/atoms/button/Button.atom'
import { appConstants, buttonTypes, iconDirection } from '../../app/constants/constants.app'
import { removeFromCart, resetCartState } from '../../app/features/cart/cart.slice'
import { publicApiRequest } from '../../lib/hooks/axios-instance'
import {
  NotificationTitle,
  triggerNotification,
} from '../../app/features/notification/notification.slice'
import { LocalStorageService } from '../../service/localstorage.service'
import { User } from '../../types/user-types/user.type'
import {
  addToolToCart,
  decrementToolQuantity,
  removeToolFromCart,
  resetToolCartState,
} from '../../app/features/tool-cart/tool-cart.slice'

const lStorage = new LocalStorageService()

const CartPage = () => {
  const cartState = useAppSelector((state) => state.cartSlice)
  const toolState = useAppSelector((state) => state.toolCartSlice)

  const authState = useAppSelector((state) => state.loginUserSlice)
  const location = useLocation()
  const [params] = useSearchParams()
  const user = params.get('user')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [creating, setCreating] = useState(false)

  console.log('====================================')
  console.log({ toolState }, user)
  console.log('====================================')

  const placeOrder = async () => {
    setCreating(true)
    const ids: string[] = []
    const qtties: number[] = []

    if (user === 'farmer') {
      toolState.tools.map((item) => {
        ids.push(item._id as string)
        qtties.push(item.cartCount as number)
      })
    } else {
      cartState.products.map((_i, _index) => {
        ids.push(_i._id)
        qtties.push(_i.cartCount as number)
      })
    }

    const body = {
      customerId: authState.requestResponse.data?.user._id,
      docModel: user === 'farmer' ? 'Tool' : 'Product',
      farmerId:
        user === 'farmer'
          ? (toolState.tools[0].userId as User)._id
          : cartState.products[0].userId._id,
      productIds: ids,
      productQuantities: qtties,
      delivered: false,
      collected: false,
      amount:
        user === 'farmer'
          ? toolState.tools.reduce((sum, prod) => {
              return sum + prod.price * (prod.cartCount as number)
            }, 0)
          : cartState.products.reduce((sum, prod) => {
              return sum + prod.price * (prod.cartCount as number)
            }, 0),
    }

    try {
      await publicApiRequest()
        .post('/orders', body)
        .then((response) => {
          console.log(response)
          dispatch(
            triggerNotification({
              message: 'Order successfully created.',
              title: NotificationTitle.SUCCESS,
            }),
          )

          dispatch(resetCartState())
          dispatch(resetToolCartState())
        })
    } catch (e) {
      console.log(e)

      dispatch(
        triggerNotification({
          message: 'An error occured creating your order, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setCreating(false)
    }
  }

  useEffect(() => {
    if (cartState.products.length === 0 && user !== 'farmer') {
      navigate(appConstants.routes.stores)
    }

    if (toolState.tools.length === 0 && user === 'farmer') {
      navigate(appConstants.routes.newDashboard.index)
    }
  }, [window.location, cartState])

  useEffect(() => {
    if (!authState.requestResponse.data?.user) {
      lStorage.storePreviousRoute(location.pathname)
      navigate(appConstants.routes.auth.login)
    }
  }, [authState.requestResponse])

  return (
    <>
      <NavbarOrganism />
      <div className={`${globals['full-width']} ${globals['p-desktop']} ${styles.cart}`}>
        <div className={styles.data}>
          <h2>Shopping Cart</h2>
          <h3>
            You have <span>{cartState.products.length}</span> product(s) in your cart
          </h3>

          <div className={`${globals.flex} ${globals['gap-24']}`}>
            {user === 'farmer' ? (
              <div
                className={`${styles.cart__items} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}
              >
                {toolState.tools.map((prod, index) => (
                  <div
                    key={index}
                    className={`${styles.product} ${globals['gap-24']} ${globals.flex} ${globals['a-center']}`}
                  >
                    <div className={styles.image}>
                      <img src={prod.imageUrl} width={150} height={150} alt={prod.name} />
                    </div>
                    <div
                      className={`${globals.flex} ${globals['full-width']} ${globals['s-b']} ${globals['gap-24']}`}
                    >
                      <div>
                        <h4 className={styles.diff}>{prod.name}</h4>
                        <h4>
                          <span>By</span> {(prod.userId as User).firstName}{' '}
                          {(prod.userId as User).lastName}
                        </h4>
                        <h4>
                          <span>Price:</span> {prod.price}
                        </h4>
                        <h4>
                          <span>Quantity Left In Stock: </span>
                          {(prod.quantity as number) - (prod.cartCount as number)}
                        </h4>

                        <div
                          className={`${globals.flex} ${styles.btns} ${globals['a-center']} ${globals['gap-12']}`}
                        >
                          <ButtonAtom
                            iconDir={iconDirection.right}
                            btnText='-'
                            type={buttonTypes.primary}
                            onClick={() => {
                              dispatch(decrementToolQuantity({ id: prod._id as string }))
                            }}
                          />
                          <h4>
                            {toolState.tools.find((prod) => prod._id === prod._id)?.cartCount}
                          </h4>
                          <ButtonAtom
                            onClick={() => {
                              dispatch(addToolToCart({ product: prod }))
                            }}
                            btnText='+'
                            type={buttonTypes.primary}
                          />
                        </div>
                      </div>
                      <div>
                        <h4>
                          <span>Quantity In Cart:</span>{' '}
                          <span className={styles.diff}>{prod.cartCount}</span>
                        </h4>
                        <h4>
                          <span>Total:</span>{' '}
                          <span className={styles.diff}>
                            {prod.price * (prod.cartCount as number)} XAF
                          </span>
                        </h4>
                        <div
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          <ButtonAtom
                            onClick={() => {
                              dispatch(removeToolFromCart({ id: prod._id as string }))
                            }}
                            btnText='Remove'
                            type={buttonTypes.secondaryOutlined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                className={`${styles.cart__items} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}
              >
                {cartState.products.map((prod, index) => (
                  <div
                    key={index}
                    className={`${styles.product} ${globals['gap-24']} ${globals.flex} ${globals['a-center']}`}
                  >
                    <div className={styles.image}>
                      <img src={prod.imageUrl} width={150} height={150} alt={prod.name} />
                    </div>
                    <div
                      className={`${globals.flex} ${globals['full-width']} ${globals['s-b']} ${globals['gap-24']}`}
                    >
                      <div>
                        <h4 className={styles.diff}>{prod.name}</h4>
                        <h4>
                          <span>By</span> {prod.userId.firstName} {prod.userId.lastName}
                        </h4>
                        <h4>
                          <span>Price:</span> {prod.price} per {prod.unit}
                        </h4>
                        <h4>
                          <span>Quantity Left In Stock: </span>
                          {prod.quantity - (prod.cartCount as number)}
                        </h4>
                      </div>
                      <div>
                        <h4>
                          <span>Quantity In Cart:</span>{' '}
                          <span className={styles.diff}>{prod.cartCount}</span>
                        </h4>
                        <h4>
                          <span>Total:</span>{' '}
                          <span className={styles.diff}>
                            {prod.price * (prod.cartCount as number)} XAF
                          </span>
                        </h4>
                        <div
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          <ButtonAtom
                            onClick={() => {
                              dispatch(removeFromCart({ id: prod._id }))
                            }}
                            btnText='Remove'
                            type={buttonTypes.secondaryOutlined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* <div
              className={`${styles.cart__items} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}
            >
              {cartState.products.map((prod, index) => (
                <div
                  key={index}
                  className={`${styles.product} ${globals['gap-24']} ${globals.flex} ${globals['a-center']}`}
                >
                  <div className={styles.image}>
                    <img src={prod.imageUrl} width={150} height={150} alt={prod.name} />
                  </div>
                  <div
                    className={`${globals.flex} ${globals['full-width']} ${globals['s-b']} ${globals['gap-24']}`}
                  >
                    <div>
                      <h4 className={styles.diff}>{prod.name}</h4>
                      <h4>
                        <span>By</span> {prod.userId.firstName} {prod.userId.lastName}
                      </h4>
                      <h4>
                        <span>Price:</span> {prod.price} per {prod.unit}
                      </h4>
                      <h4>
                        <span>Quantity Left In Stock: </span>
                        {prod.quantity - (prod.cartCount as number)}
                      </h4>
                    </div>
                    <div>
                      <h4>
                        <span>Quantity In Cart:</span>{' '}
                        <span className={styles.diff}>{prod.cartCount}</span>
                      </h4>
                      <h4>
                        <span>Total:</span>{' '}
                        <span className={styles.diff}>
                          {prod.price * (prod.cartCount as number)} XAF
                        </span>
                      </h4>
                      <div
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        <ButtonAtom
                          onClick={() => {
                            dispatch(removeFromCart({ id: prod._id }))
                          }}
                          btnText='Remove'
                          type={buttonTypes.secondaryOutlined}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div
              className={`${styles.checkout} ${globals['gap-16']} ${globals.flex} ${globals['flex-column']}`}
            >
              <span className={styles.total}>Total</span>
              <h3>
                {user === 'farmer'
                  ? toolState.tools.reduce((sum, prod) => {
                      return sum + prod.price * (prod.cartCount as number)
                    }, 0)
                  : cartState.products.reduce((sum, prod) => {
                      return sum + prod.price * (prod.cartCount as number)
                    }, 0)}
                XAF
              </h3>
              <div>
                <ButtonAtom
                  onClick={() => {
                    placeOrder()
                  }}
                  disabled={creating}
                  btnText={creating ? 'Please wait...' : 'Confirm & Place Order'}
                  type={buttonTypes.primary}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOrganism />
    </>
  )
}

export default CartPage
