import React from 'react'

import globals from '../../../global.module.css'
import styles from './banner.module.css'
import ButtonAtom from '../button/Button.atom'
import { buttonTypes, iconDirection } from '../../../app/constants/constants.app'
import { IconsRepository } from '../../../repository/icons/icons.repository'
import { Link } from 'react-router-dom'

type Props = {
  img: string
  farmName: string
  setAdding: React.Dispatch<React.SetStateAction<boolean>>
}

const BannerAtom = ({ img, farmName, setAdding }: Props) => {
  return (
    <div className={`${globals.flex} ${globals['flex-column']} ${styles.container}`}>
      <div className={styles.img}>
        <img className={styles.banner__bg} src='/images/img1.jpg' alt='banner' />
        <div className={styles.btn}>
          <ButtonAtom
            onClick={() => {
              setAdding(true)
            }}
            fontSize='1.6rem'
            height='46px'
            type={buttonTypes.secondary}
            btnText='New Project'
            fullWidth={true}
            bgColor=''
            iconDir={iconDirection.right}
            icon={<IconsRepository.PlusIcon size={24} />}
          />
        </div>
        <img src={img} alt='name' />
      </div>
      <div
        className={`${styles.text} ${globals.flex} ${globals['center-items']} ${globals['s-b']}`}
      >
        <h5>{farmName}</h5>
        <div
          style={{ width: 'fit-content' }}
          className={`${styles.social__handles} ${globals.flex} ${globals['center-items']} $${globals['gap-24']}`}
        >
          <a target='_blank' rel='noreferrer' href='https://facebook.com'>
            <IconsRepository.FacebookIcon size={28} />
          </a>
          <a target='_blank' rel='noreferrer' href='https://linkedin.com'>
            <IconsRepository.LinkedinIcon size={28} />
          </a>
          <a target='_blank' rel='noreferrer' href='https://twitter.com'>
            <IconsRepository.TwitterIcon size={26} />
          </a>
          <Link title='Edit Farm Info' to='/'>
            <IconsRepository.EditIcon size={26} />
          </Link>
        </div>
      </div>

      <div className={styles.intro}></div>
    </div>
  )
}

export default BannerAtom
