import React from 'react'

import globals from '../../../global.module.css'
import styles from './input.module.css'

import { iconDirection, inputState } from '../../../app/constants/constants.app'

export type InputProps = {
  type: string
  name: string
  placeholder: string
  value?: string
  readOnly?: boolean
  state?: inputState
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  iconDir?: iconDirection[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  iconClick?: () => void
  id?: string
}

const InputAtom = ({
  type,
  state,
  name,
  placeholder,
  value,
  id,
  onChange,
  iconDir,
  iconLeft,
  iconRight,
  iconClick,
  readOnly,
}: InputProps) => {
  return (
    <div
      className={`${styles.input__wrapper} ${globals.flex} ${globals['a-center']} ${globals['full-width']}`}
    >
      <span className={`${styles.left__icon} ${styles[state as string]}`}>
        {iconDir?.includes(iconDirection.left) ? iconLeft : ''}
      </span>

      <input
        readOnly={readOnly ? true : false}
        id={id}
        onChange={onChange}
        className={`${styles.input} ${
          state === 'error' ? styles.error : state === 'success' ? styles.success : ''
        } ${globals['full-width']} ${globals.flex} ${globals['a-center']} ${globals['gap-12']}`}
        placeholder={placeholder}
        type={type}
        style={{
          paddingLeft: iconDir?.includes(iconDirection.left) ? '4.5rem' : '16px',
        }}
        value={value}
        name={name}
      />

      <span
        onClick={() => {
          if (iconClick !== undefined) {
            iconClick()
          }
        }}
        className={`${styles.right__icon} ${globals.cursor} ${styles[state as string]}`}
      >
        {iconDir?.includes(iconDirection.right) ? iconRight : ''}
      </span>
    </div>
  )
}

export default InputAtom
