import React from 'react'

import { IconType } from '../../types/icon.type'

const EyeIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0027 21.776C12.816 21.776 10.2293 19.1893 10.2293 16.0027C10.2293 12.816 12.816 10.2293 16.0027 10.2293C19.1893 10.2293 21.776 12.816 21.776 16.0027C21.776 19.1893 19.1893 21.776 16.0027 21.776ZM16.0027 12.2293C13.9227 12.2293 12.2293 13.9227 12.2293 16.0027C12.2293 18.0827 13.9227 19.776 16.0027 19.776C18.0827 19.776 19.776 18.0827 19.776 16.0027C19.776 13.9227 18.0827 12.2293 16.0027 12.2293Z'
        fill={color}
      />
      <path
        d='M15.9973 28.0267C10.984 28.0267 6.25067 25.0933 2.99733 20C1.584 17.8 1.584 14.2133 2.99733 12C6.264 6.90666 10.9973 3.97333 15.9973 3.97333C20.9973 3.97333 25.7307 6.90666 28.984 12C30.3973 14.2 30.3973 17.7867 28.984 20C25.7307 25.0933 20.9973 28.0267 15.9973 28.0267ZM15.9973 5.97333C11.6907 5.97333 7.57067 8.55999 4.69067 13.08C3.69067 14.64 3.69067 17.36 4.69067 18.92C7.57067 23.44 11.6907 26.0267 15.9973 26.0267C20.304 26.0267 24.424 23.44 27.304 18.92C28.304 17.36 28.304 14.64 27.304 13.08C24.424 8.55999 20.304 5.97333 15.9973 5.97333Z'
        fill={color}
      />
    </svg>
  )
}

export default EyeIcon
