import React from 'react'

import globals from '../../../global.module.css'
import styles from './investorstemp.module.css'

const ProfileSetupViewTemplate = () => {
  return (
    <section className={`${styles.template} ${globals['full-width']}`}>
      <div className={styles.head}>
        <h2>Profile Setup</h2>
      </div>
    </section>
  )
}

export default ProfileSetupViewTemplate
