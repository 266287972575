import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './authtemplate.module.css'

import ButtonAtom from '../../atoms/button/Button.atom'
import { appConstants, buttonTypes, iconDirection } from '../../../app/constants/constants.app'
import { IconsRepository } from '../../../repository/icons/icons.repository'
import UseForm from '../../../lib/hooks/use-form'
import useWindowSize from '../../../lib/hooks/use-window-soze'
import UserinfoModule from './reg-modules/Userinfo.module'
import UserinfoModuleTwo from './reg-modules/UserInfoTwo.module'
import { registerUserThunk } from '../../../app/features/auth/thunks/auth.thunk'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { RegisterDataType } from '../../../types/auth.type'
import { RootState } from '../../../app/store/store'
import { ApiRequestStatus } from '../../../types/api.type'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../app/features/notification/notification.slice'
import { AccountTypes } from '../../../repository/data/accountType'

const RegisterTemplate = () => {
  const [trigger, setTrigger] = useState(false)
  const { width } = useWindowSize()
  const dispatch = useAppDispatch()

  const registrationState = useAppSelector((state: RootState) => state.registerUserSlice)

  const [currentStep, setCurrentStep] = useState(0)

  const { form, updateForm } = UseForm<RegisterDataType>(
    {
      accountType: AccountTypes.FARMER,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      confirmPassword: '',
    },
    setTrigger,
    trigger,
  )

  const handleSubmit = async () => {
    dispatch(registerUserThunk(form))
  }

  useEffect(() => {
    if (registrationState.requestResponse.status === ApiRequestStatus.FULFILLED) {
      dispatch(
        triggerNotification({
          message: 'Account successfully created. Please check your email to verify your account.',
          title: NotificationTitle.SUCCESS,
        }),
      )
    } else {
      if (registrationState.requestResponse.status === ApiRequestStatus.REJECTED) {
        dispatch(
          triggerNotification({
            message: registrationState.requestResponse.error?.message as string,
            title: NotificationTitle.ERROR,
          }),
        )
      }
    }
  }, [registrationState])

  return (
    <div
      className={`${styles.auth__template} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
    >
      <h2>Create An Account!</h2>

      <div
        className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['gap-10']}`}
      >
        {currentStep === 0 ? <UserinfoModule form={form} updateForm={updateForm} /> : ''}
        {currentStep === 1 ? <UserinfoModuleTwo form={form} updateForm={updateForm} /> : ''}

        <div className={styles.btn}>
          {currentStep > 0 ? (
            <ButtonAtom
              fontSize='1.8rem'
              type={buttonTypes.primary}
              onClick={() => {
                setCurrentStep((prev) => prev - 1)
              }}
              btnText='Back'
              fullWidth={true}
              icon={
                <span
                  style={{
                    transform: 'rotate(-180deg)',
                    marginTop: '-0.8rem',
                  }}
                >
                  <IconsRepository.ArrowIcon size={width < 1440 ? 20 : 24} />
                </span>
              }
              iconDir={iconDirection.left}
            />
          ) : (
            ''
          )}
          <ButtonAtom
            fontSize='1.8rem'
            type={buttonTypes.primary}
            onClick={() => {
              if (currentStep !== 1) {
                setCurrentStep((prev) => prev + 1)
              } else {
                handleSubmit()
              }
            }}
            btnText={
              registrationState.requestResponse.status === ApiRequestStatus.PENDING &&
              currentStep === 1
                ? 'Loading...'
                : currentStep === 1
                ? 'Register'
                : 'Continue'
            }
            fullWidth={true}
            icon={
              currentStep !== 1 ? (
                <IconsRepository.ArrowIcon size={width < 1440 ? 20 : 24} />
              ) : (
                <></>
              )
            }
            iconDir={iconDirection.right}
          />
        </div>

        <div className={styles.ref}>
          <h4>
            Already have an account ? <Link to={appConstants.routes.auth.login}>Login</Link>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default RegisterTemplate
