import React, { Fragment, useCallback, useEffect, useState } from 'react'

import globals from '../../../../global.module.css'
import styles from './toolorder.module.css'

import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes } from '../../../../app/constants/constants.app'
import ToolCardMolecule from '../../../../components/molecules/tool-card/ToolCard.molecule'
import TabsSelectionAtom from '../../../../components/atoms/tabs/TabsSelection.atom'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { IOrder } from '../../../../types/order-types/order.type'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import Loader from '../../../../components/loader/Loader'
import { User } from '../../../../types/user-types/user.type'
import { ITool } from '../../../../types/tool-types/tool.types'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { AccountTypes } from '../../../../repository/data/accountType'
import { staticSvgs } from '../../../../app/assets/images'

const ToolsOrderPage = () => {
  const [orders, setOrders] = useState<IOrder[]>([])
  const [activeDrop, setActiveDrop] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const dispatch = useAppDispatch()

  const authState = useAppSelector((state) => state.loginUserSlice)

  const [tools, setTools] = useState<ITool[]>([])

  const tabs = ['Pending Delivery', 'Delivered', 'Collected']

  const [loading, setLoading] = useState(false)

  const fetchOrders = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ orders: IOrder[] }>(`/orders/${authState.requestResponse.data?.user._id}/{null}`)
        .then((response) => {
          setOrders(response.data.orders)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const filterOrders = (idx: number): IOrder[] => {
    if (idx === 0) {
      return orders.filter((order) => order.delivered === false && order.collected === false)
    }

    if (idx === 1) {
      return orders.filter((order) => order.delivered === true && order.collected === false)
    }

    return orders.filter((order) => order.delivered === true && order.collected === true)
  }

  const controlOrder = async (action: string, id: string) => {
    console.log('====================================')
    console.log({ action })
    console.log('====================================')
    setLoading(true)
    try {
      await publicApiRequest()
        .patch(`/orders/${action === 'delivery' ? 'delivered' : 'collected'}/${id}`)
        .then((response) => {
          console.log({ response })
          dispatch(
            triggerNotification({
              message: 'Successfully marked as delivered.',
              title: NotificationTitle.SUCCESS,
            }),
          )

          fetchOrders()
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  return (
    <div className={`${globals['full-width']} ${globals['p-desktop']} ${styles.tools}`}>
      <h2>Manage Orders</h2>
      {authState.requestResponse.data?.user.accountType === AccountTypes.MACHINERY ? (
        <h3>
          Total Sales:{' '}
          <span>
            {authState.requestResponse.data?.user.totalSales
              ? authState.requestResponse.data.user.totalSales
              : 0}{' '}
            FCFA
          </span>
        </h3>
      ) : (
        ''
      )}

      <div style={{ margin: '20px 0' }} className={styles.tabs}>
        <TabsSelectionAtom
          icons={[
            <>
              <IconsRepository.BellIcon size={24} />
            </>,
            <>
              <IconsRepository.AnnouncementIcon size={24} />
            </>,
            <>
              <IconsRepository.CreateNewFolderIcon size={24} />
            </>,
          ]}
          counts={[filterOrders(0).length, filterOrders(1).length, filterOrders(2).length]}
          activeIndex={activeTab}
          setActiveIndex={setActiveTab}
          tabs={tabs}
          colors={[
            'var(--AGROX-GREY-COLOR)',
            'var(--AGROX-RED-COLOR)',
            'var(--AGROX-LIGHT-GREEN-COLOR)',
          ]}
        />
      </div>
      {!loading && filterOrders(activeTab).length === 0 ? (
        <div
          className={`${globals.flex} ${globals['gap-16']} ${globals['flex-column']} ${styles.no__content} ${globals['center-items']} ${globals['full-width']}`}
        >
          <div>
            <img width={150} height={150} src={staticSvgs.dashboard.EmptySearch} alt='no content' />
          </div>
          <div>
            <h3>No orders here</h3>
            <h4>Check out the other tabs</h4>
          </div>
        </div>
      ) : (
        ''
      )}

      {loading ? <Loader /> : ''}
      {!loading ? (
        <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}>
          {orders.length > 0 ? (
            <>
              {filterOrders(activeTab).map((order, index) => {
                return (
                  <Fragment key={index}>
                    <div
                      className={`${styles.product} ${globals['gap-24']} ${globals.flex} ${globals['s-b']}`}
                    >
                      <div
                        onClick={() => {
                          setActiveDrop(!activeDrop)
                          setTools(order.productIds as ITool[])
                        }}
                        className={`${globals.flex} ${globals['gap-16']}`}
                      >
                        <div className={styles.text}>
                          <h2>Order ID: {order._id}</h2>
                          <h3>
                            Total Tools: <span>{order.productIds.length}</span>
                          </h3>
                          <h3>
                            Total Price: <span>{order.amount} FCFA</span>
                          </h3>
                          <h3>
                            Date Created: <span>20-08-2008</span>
                          </h3>
                          <div
                            className={`${globals.flex} ${globals['center-items']} ${styles.farmer} ${globals['gap-16']}`}
                          >
                            <div className={styles.image}>
                              <img
                                src={(order.customerId as User).imageUrl}
                                width={150}
                                height={150}
                                alt=''
                              />
                            </div>
                            <h4>{(order.customerId as User).firstName}</h4>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${styles.content} ${globals.flex} ${globals['flex-column']} ${globals['gap-10']}`}
                      >
                        {/* <ButtonAtom
                          bgColor='var(--AGROX-RED-COLOR)'
                          btnText='Reject'
                          type={buttonTypes.primary}
                        /> */}
                        <ButtonAtom
                          onClick={() => {
                            if (activeTab === 0) {
                              controlOrder('delivery', order._id)
                            }
                          }}
                          btnText={activeTab === 0 ? 'Mark as Delivered' : 'Delivered'}
                          type={buttonTypes.primary}
                        />
                      </div>
                    </div>
                    {!loading && activeDrop ? (
                      <div className={`${styles.grid}`}>
                        {tools.length > 0 &&
                          tools.map((tool, index) => {
                            return (
                              <div key={index}>
                                <ToolCardMolecule tool={tool} />
                              </div>
                            )
                          })}
                      </div>
                    ) : (
                      ''
                    )}
                  </Fragment>
                )
              })}
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ToolsOrderPage
