import React from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './footer.module.css'

import { IconsRepository } from '../../../repository/icons/icons.repository'

const FooterOrganism = () => {
  return (
    <footer className={`${styles.footer} ${globals['p-desktop']}`}>
      <div className={styles.top}>
        <IconsRepository.LogoIcon width={100} height={60} color='var(--AGROX-LIGHT-GREEN-COLOR)' />
        <h3>Empowering farmers and connecting them to the resources they need for success!</h3>
      </div>

      <div className={`${styles.component} ${globals.flex} ${globals['s-b']}`}>
        <div className={styles.head}>
          <h2>Products</h2>

          <ul className={styles.footer__menu}>
            <li>
              <Link to='/'>Sales</Link>
            </li>
            <li>
              <Link to='/'>Marketting</Link>
            </li>
            <li>
              <Link to='/'>Jobs Openings</Link>
            </li>
            <li>
              <Link to='/'>Providence & Testing</Link>
            </li>
          </ul>
        </div>
        <div className={styles.head}>
          <h2>Products</h2>
          <ul className={styles.footer__menu}>
            <li>
              <Link to='/'>Speciality</Link>
            </li>
            <li>
              <Link to='/'>Junk Foods</Link>
            </li>
            <li>
              <Link to='/'>Healthy Tips</Link>
            </li>
            <li>
              <Link to='/'>Marketting</Link>
            </li>
            <li>
              <Link to='/'>Jobs Openings</Link>
            </li>
            <li>
              <Link to='/'>Providence & Testing</Link>
            </li>
          </ul>
        </div>
        <div className={styles.head}>
          <h2>Categories</h2>
          <ul className={styles.footer__menu}>
            <li>
              <Link to='/'>Wheat</Link>
            </li>
            <li>
              <Link to='/'>Vegetables</Link>
            </li>
            <li>
              <Link to='/'>Meat</Link>
            </li>
            <li>
              <Link to='/'>Diary</Link>
            </li>
          </ul>
        </div>
        <div className={styles.head}>
          <h2>Popular Products</h2>
          <ul className={styles.footer__menu}>
            <li>
              <Link to='/'>Just Some Insights</Link>
            </li>
            <li>
              <Link to='/'>Blogs</Link>
            </li>
          </ul>
        </div>
        <div className={styles.head}>
          <h2>Products</h2>

          <ul className={styles.footer__menu}>
            <li>
              <Link to='/'>Sales</Link>
            </li>
            <li>
              <Link to='/'>Marketting</Link>
            </li>
            <li>
              <Link to='/'>Jobs Openings</Link>
            </li>
            <li>
              <Link to='/'>Providence & Testing</Link>
            </li>
            <li>
              <Link to='/'>Marketting</Link>
            </li>
            <li>
              <Link to='/'>Jobs Openings</Link>
            </li>
            <li>
              <Link to='/'>Providence & Testing</Link>
            </li>
            <li>
              <Link to='/'>Marketting</Link>
            </li>
            <li>
              <Link to='/'>Jobs Openings</Link>
            </li>
          </ul>
        </div>
        <div className={styles.head}>
          <h2>Products</h2>
          <ul className={styles.footer__menu}>
            <li>
              <Link to='/'>Speciality</Link>
            </li>
            <li>
              <Link to='/'>Junk Foods</Link>
            </li>
            <li>
              <Link to='/'>Healthy Tips</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={`${styles.mark} ${globals.flex} ${globals['s-b']} ${globals['gap-24']}`}>
        <h4>Agrox &copy; {new Date().getFullYear()}</h4>
        <Link to='/'>Terms & Condition</Link>
      </div>
    </footer>
  )
}

export default FooterOrganism
