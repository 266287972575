import React from 'react'

import globals from '../../../../../global.module.css'
import styles from './pdetails.module.css'

import { PCardProps } from '../p-card/PCard.molecule'
import ButtonAtom from '../../../../../components/atoms/button/Button.atom'
import { buttonTypes } from '../../../../../app/constants/constants.app'
import { ViewState } from '../../manage-products/NewManageProducts.view'
import { useAppDispatch, useAppSelector } from '../../../../../lib/hooks'
import { RootState } from '../../../../../app/store/store'
import {
  addToolToCart,
  decrementToolQuantity,
  removeToolFromCart,
} from '../../../../../app/features/tool-cart/tool-cart.slice'
import { ITool } from '../../../../../types/tool-types/tool.types'

const PDetailsMolecule: React.FC<PCardProps> = ({
  image,
  name,
  onClick,
  description,
  price,
  unit,
  category,
  quantity,
  setViewState,
  ableToEdit,
  toolCard,
  tool,
  hideCartOptions,
}) => {
  const toolCartState = useAppSelector((state: RootState) => state.toolCartSlice)
  const dispatch = useAppDispatch()

  return (
    <article
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      className={`${globals['full-width']} ${category ? styles.no__animate : ''} ${styles.card}`}
    >
      <div className={`${styles.image} ${globals.flex} ${globals['center-items']}`}>
        <img src={image} width='100%' height='100%' alt={name} />
      </div>
      <div
        className={`${styles.body} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
      >
        {toolCard ? '' : <span className={styles.cat}>{category}</span>}
        <h3>{name}</h3>
        <p>{description}</p>
        <span className={styles.cat}>Quantity Available: {quantity}</span>
        {toolCard ? (
          <>
            <span className={styles.cat}>Cart Quantity: {tool?.cartCount}</span>
          </>
        ) : (
          <></>
        )}
        <span>
          {price} XAF{' '}
          {toolCard ? (
            <></>
          ) : (
            <>
              <span>per</span> {unit}
            </>
          )}
        </span>
        {toolCard && !hideCartOptions ? (
          <>
            <div className={`${globals.flex} ${globals['s-b']}`}>
              <div></div>
              <div className={`${globals.flex}  ${globals['a-center']} ${globals['gap-12']}`}>
                <ButtonAtom
                  bgColor='var(--AGROX-GREEN-COLOR)'
                  btnText='-'
                  borderRadius='4px'
                  type={buttonTypes.secondary}
                  onClick={() => {
                    if (tool?.cartCount === 1) {
                      dispatch(
                        removeToolFromCart({
                          id: tool._id as string,
                        }),
                      )
                    } else {
                      dispatch(
                        decrementToolQuantity({
                          id: tool?._id as string,
                        }),
                      )
                    }
                  }}
                />
                <ButtonAtom
                  borderRadius='4px'
                  bgColor='var(--AGROX-GREEN-COLOR)'
                  btnText='+'
                  type={buttonTypes.secondary}
                  onClick={() => {
                    if ((tool?.cartCount as number) < (tool?.quantity as number)) {
                      dispatch(
                        addToolToCart({
                          product: tool as ITool,
                        }),
                      )
                    }
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        {ableToEdit === true ? (
          <div className={`${globals.flex} ${globals['s-b']}`}>
            <div></div>
            <div className={`${globals.flex}  ${globals['a-center']} ${globals['gap-12']}`}>
              <ButtonAtom
                bgColor='var(--AGROX-GREEN-COLOR)'
                btnText='Edit'
                borderRadius='4px'
                type={buttonTypes.secondary}
                onClick={() => {
                  if (setViewState) {
                    setViewState(ViewState.EDITING)
                  }
                }}
              />
              <ButtonAtom
                borderRadius='4px'
                bgColor='var(--AGROX-RED-COLOR)'
                btnText='Delete'
                type={buttonTypes.secondary}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {toolCard ? (
          ''
        ) : (
          <ButtonAtom
            bgColor='var(--AGROX-GREEN-COLOR)'
            borderRadius='4px'
            type={buttonTypes.secondary}
            onClick={() => {
              if (tool) {
                if (toolCartState.tools.find((item) => item._id === tool._id) !== undefined) {
                  dispatch(removeToolFromCart({ id: tool._id as string }))
                } else dispatch(addToolToCart({ product: tool }))
              }
            }}
            btnText={
              toolCartState.tools.find((item) => item._id === tool?._id) === undefined
                ? 'Add to Cart'
                : 'Remove from Cart'
            }
          />
        )}
      </div>
    </article>
  )
}

export default PDetailsMolecule
