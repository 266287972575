import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import globals from '../../global.module.css'
import styles from './investor.module.css'

import NavbarOrganism from '../../components/organisms/navbar/Navbar.organism'
import FooterOrganism from '../../components/organisms/footer/Footer.organism'
import FarmersViewTemplate from '../../components/templates/investors/FarmersView.template'
import ProjectsViewTemplate from '../../components/templates/investors/ProjectView.template'
import ProfileSetupViewTemplate from '../../components/templates/investors/ProfileSetupView.template'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { RootState } from '../../app/store/store'
import { appConstants } from '../../app/constants/constants.app'
import { AccountTypes } from '../../repository/data/accountType'
import { LoginResponseType } from '../../types/auth.type'
import { LocalStorageService } from '../../service/localstorage.service'
import { resetLoginUserState } from '../../app/features/auth/slices/login-user.slice'

const localStorage = new LocalStorageService()

const InvestorsPage = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)
  const { requestResponse } = authState
  const { data } = requestResponse
  const { user } = data as LoginResponseType

  useEffect(() => {
    if (!authState.requestResponse.data?.user) {
      navigate(appConstants.routes.auth.login)
    }

    if (
      authState.requestResponse.data?.user &&
      authState.requestResponse.data.user.accountType !== AccountTypes.INVESTOR
    ) {
      navigate('/dashboard')
    }
  }, [authState.requestResponse])

  return (
    <>
      <NavbarOrganism />
      <div
        className={`${styles.investors} ${globals['p-desktop']} ${globals.flex} ${globals['gap-24']} ${globals['full-width']}`}
      >
        <div className={`${styles.side__panel}`}>
          <div
            className={`${styles.holder} ${globals['flex-column']} ${globals.flex} ${globals['center-items']}`}
          >
            {user !== undefined ? (
              <>
                <div className={`${styles.image} ${globals.flex} ${globals['center-items']}`}>
                  <img src={user.imageUrl} alt='profile' />
                </div>
                <div className={`${styles.data} ${globals['full-width']}`}>
                  <h2>
                    {user.firstName} {user.lastName}
                  </h2>
                  <div
                    className={`${globals['full-width']} ${globals.flex} ${globals['center-items']}`}
                  >
                    <div className={styles.badge}>investor</div>
                  </div>
                  <h3>
                    <span className={styles.span}>{user.email}</span>
                  </h3>
                  <h3>{user.contact}</h3>
                </div>
              </>
            ) : (
              ''
            )}

            <div
              className={`${styles.menu} ${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['gap-10']}`}
            >
              <button
                onClick={() => {
                  setCurrentTab(0)
                  navigate('/investor-dashboard')
                }}
              >
                Brower Farmers
              </button>
              <button
                onClick={() => {
                  setCurrentTab(1)
                  navigate('/investor-dashboard')
                }}
              >
                Investments Projects
              </button>
              <button
                onClick={() => {
                  setCurrentTab(2)
                  navigate('/investor-dashboard')
                }}
              >
                Profile Settings
              </button>
              <button
                onClick={() => {
                  localStorage.deleteLoginData()
                  dispatch(resetLoginUserState())
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
        <div className={`${styles.content}`}>
          {currentTab === 0 ? <FarmersViewTemplate /> : ''}
          {currentTab === 1 ? <ProjectsViewTemplate /> : ''}
          {currentTab === 2 ? <ProfileSetupViewTemplate /> : ''}
        </div>
      </div>
      <FooterOrganism />
    </>
  )
}

export default InvestorsPage
