import React from 'react'
import Fade from 'react-reveal'

import globals from '../../../../global.module.css'
import styles from './ceo.module.css'
import { staticSvgs } from '../../../../app/assets/images'
import { IconsRepository } from '../../../../repository/icons/icons.repository'

const CeoTemplate = () => {
  return (
    <section
      className={`${styles.ceo} ${globals['p-desktop']} ${globals['gap-24']} ${globals.flex} ${globals['full-width']} ${globals['center-items']}`}
    >
      <div className={`${styles.image} ${globals.flex} ${globals['center-items']}`}>
        <div>
          <div className={styles.logo}>
            <IconsRepository.LogoIcon
              width={80}
              height={50}
              color='var(--AGROX-LIGHT-GREEN-COLOR)'
            />
          </div>
          <Fade left>
            <img
              width={400}
              height={400}
              src={staticSvgs.headerAvatar.Avatar}
              alt='Nchia Emmanuela'
            />
          </Fade>
        </div>
      </div>
      <div className={styles.text}>
        <Fade up>
          <h2>
            Working hard for something we don&apos;t care about is called stress. Working hard for
            something we love is called passion.
          </h2>
        </Fade>
        <Fade left>
          <h3>Nchia Emmanuela Ekei</h3>
        </Fade>
        <h4>- CEO, Agrox</h4>
      </div>
    </section>
  )
}

export default CeoTemplate
