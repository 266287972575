import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import globals from '../../global.module.css'
import styles from './storedetails.module.css'

import NavbarOrganism from '../../components/organisms/navbar/Navbar.organism'
import FooterOrganism from '../../components/organisms/footer/Footer.organism'
import { staticSvgs } from '../../app/assets/images'
import ButtonAtom from '../../components/atoms/button/Button.atom'
import { buttonTypes } from '../../app/constants/constants.app'
import { publicApiRequest } from '../../lib/hooks/axios-instance'
import Loader from '../../components/loader/Loader'
import { IProduct } from '../../types/product-types/product.type'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { addToCart, removeFromCart } from '../../app/features/cart/cart.slice'
import { RootState } from '../../app/store/store'
import {
  NotificationTitle,
  triggerNotification,
} from '../../app/features/notification/notification.slice'

const StoreDetailsPage = () => {
  const { id } = useParams()

  const [farmersProducts, setFarmersProducts] = useState<IProduct[]>([])
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const cartState = useAppSelector((state: RootState) => state.cartSlice)
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)
  const user = authState.requestResponse.data?.user

  const fetchUsers = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ products: IProduct[] }>(`/products/farmer-products/${id}`)
        .then((response) => {
          setFarmersProducts(response.data.products)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location])

  return (
    <div>
      <NavbarOrganism />
      <div
        className={`${styles.stores} ${globals['flex-column']} ${globals.flex} ${globals['center-items']} ${globals['p-desktop']} ${globals['full-width']}`}
      >
        <div
          className={`${globals.flex} ${globals['full-width']} ${styles.lines__holder} ${globals['gap-12']} ${globals['center-items']}`}
        >
          <div className={styles.line}></div>
          <div className={`${globals.flex}`}>
            <div
              style={{
                marginTop: '10px',
              }}
              className={styles.image}
            >
              {farmersProducts.length > 0 ? (
                <img
                  src={
                    (farmersProducts[0].userId.profileURL as string)?.length > 0
                      ? farmersProducts[0].userId.profileURL
                      : staticSvgs.headerAvatar.Avatar
                  }
                  alt={farmersProducts[0].userId.firstName}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={styles.line}></div>
        </div>
        {farmersProducts.length > 0 ? (
          <h2 style={{ textAlign: 'center' }}>
            {farmersProducts[0].userId.firstName}&apos;s Farm Products
          </h2>
        ) : (
          ''
        )}

        <h3>
          {farmersProducts.length > 0
            ? 'Diversity in products is a great way to achieve a sustainable development growth. We all strive to achive zero hunger across the entire nation. Browse diversified farm products.'
            : 'Farmers must have atleast one product for their shops to be configured.'}
        </h3>

        {!loading && farmersProducts.length === 0 ? (
          <div
            style={{ marginTop: '50px' }}
            className={`${styles.no__products} ${globals.flex} ${globals['flex-column']} ${globals['center-items']} ${globals['gap-24']}`}
          >
            <img src={staticSvgs.dashboard.EmptySvg} width={400} height={400} alt='empty' />
            <h3
              style={{
                fontSize: '2rem',
                marginTop: '20px',
                fontWeight: '600',
              }}
            >
              No products yet from this farmer.
            </h3>
          </div>
        ) : (
          ''
        )}

        {loading ? <Loader /> : ''}
        {!loading ? (
          <div className={`${styles.cards}`}>
            {farmersProducts.length > 0 &&
              farmersProducts.map((product, index) => {
                return (
                  <Link key={index} to={`/product/${product._id}`}>
                    <article className={styles.card}>
                      <div className={styles.image}>
                        <img src={product.imageUrl} alt='p' />
                      </div>
                      <div className={styles.body}>
                        <h3>{product.name}</h3>
                        <p>{product.description}</p>
                        <p style={{ fontSize: '1.6rem', marginBottom: '5px' }}>
                          <span>Price: {product.price}</span> per <span>{product.unit}</span>
                        </p>
                        <p style={{ fontSize: '1.6rem' }}>
                          <span>Quantity: {product.quantity}</span>
                        </p>

                        <div
                          onClick={(e) => {
                            if (
                              cartState.products.find((item) => item._id === product._id) !==
                              undefined
                            ) {
                              dispatch(removeFromCart({ id: product._id }))
                            } else {
                              if (user?._id === product.userId._id) {
                                dispatch(
                                  triggerNotification({
                                    message:
                                      'Sorry can not add your own products to cart. This is just for you to see a representation of your store front.',
                                    title: NotificationTitle.ERROR,
                                  }),
                                )
                              } else {
                                dispatch(addToCart({ product: product }))
                              }
                            }

                            e.stopPropagation()
                          }}
                        >
                          <ButtonAtom
                            type={buttonTypes.primary}
                            btnText={
                              cartState.products.find((item) => item._id === product._id) !==
                              undefined
                                ? 'Remove From Cart'
                                : 'Add To Cart'
                            }
                          />
                        </div>
                      </div>
                    </article>
                  </Link>
                )
              })}
          </div>
        ) : (
          ''
        )}
      </div>
      <FooterOrganism />
    </div>
  )
}

export default StoreDetailsPage
