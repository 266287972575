import React, { useState, useCallback, useEffect } from 'react'

import globals from '../../../../global.module.css'
import styles from '../view.module.css'

import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes, inputState } from '../../../../app/constants/constants.app'
import { IconsRepository } from '../../../../repository/icons/icons.repository'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { RootState } from '../../../../app/store/store'
import SelectAtom from '../../../../components/atoms/input/Select.atom'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { LoginResponseType } from '../../../../types/auth.type'
import { updateAuthStateData } from '../../../../app/features/auth/slices/login-user.slice'
import { User } from '../../../../types/user-types/user.type'
import { LocalStorageService } from '../../../../service/localstorage.service'

const localStorage = new LocalStorageService()

const NewPersonalInfoView = () => {
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)
  const user = authState.requestResponse.data?.user

  const [formData, setFormData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    contact: user?.contact,
    image: user?.profileURL,
  })
  const [imageSelected, setImageSelected] = useState<File>()
  const [selectedGender, setSelectedGender] = useState<string>(user?.gender as string)
  const [loading, setLoading] = useState(false)
  const [myProfile, setMyPofile] = useState<User>({} as User)
  const dispatch = useAppDispatch()

  const { firstName, lastName, email, contact } = formData

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const updateProfile = async () => {
    setLoading(true)
    const body = new FormData()

    body.append('file', imageSelected === undefined ? '' : (imageSelected as File))
    body.append('firstName', `${firstName}`)
    body.append('lastName', `${lastName}`)
    body.append('email', `${formData.email}`)
    body.append('contact', `${formData.contact}`)
    body.append(
      'gender',
      selectedGender === 'NOT SELECTED'
        ? 'NOT SELECTED'
        : selectedGender === undefined
        ? 'NOT SELECTED'
        : selectedGender,
    )

    try {
      await publicApiRequest()
        .patch('/auth/update-profile', body)
        .then((_response) => {
          dispatch(
            triggerNotification({
              message: 'Profile successfully updated',
              title: NotificationTitle.SUCCESS,
            }),
          )
          setImageSelected(undefined)
          fetchMyProfile()
        })
    } catch (e) {
      dispatch(
        triggerNotification({
          message: 'An error occured, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const fetchMyProfile = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ user: User }>('/auth/my-profile')
        .then((response) => {
          const user: LoginResponseType = JSON.parse(localStorage.getLoginData() as string)
          localStorage.storeLoginData({
            user: response.data.user,
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
          })
          dispatch(
            updateAuthStateData({
              data: {
                user: response.data.user,
                accessToken: user.accessToken,
                refreshToken: user.refreshToken,
              },
            }),
          )
          setMyPofile(response.data.user)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchMyProfile()
  }, [fetchMyProfile])

  useEffect(() => {
    setFormData({
      firstName: myProfile?.firstName,
      lastName: myProfile?.lastName,
      email: myProfile?.email,
      contact: myProfile?.contact,
      image: myProfile?.profileURL,
    })

    setSelectedGender(myProfile?.gender)
  }, [myProfile])

  return (
    <div className={`${styles.view}`}>
      <h2>Personal Information</h2>

      <div
        style={{
          flexDirection: 'row-reverse',
        }}
        className={`${globals.flex} ${globals['gap-24']}`}
      >
        <div>
          <div className={`${styles.profile}`}>
            <div className={`${styles.image}`}>
              <img
                width={200}
                height={200}
                src={
                  imageSelected !== undefined
                    ? URL.createObjectURL(imageSelected as File)
                    : user?.profileURL
                    ? user?.profileURL
                    : '/images/img2.jpg'
                }
                alt={firstName}
              />
            </div>
            <input
              type='file'
              onChange={(e) => setImageSelected(e.target.files ? e.target.files[0] : undefined)}
            />
          </div>
          <div className={styles.foot__note}>
            <span>
              <i>Click to change profile picture</i>
            </span>
          </div>
        </div>

        <div
          className={`${styles.form} ${globals.flex} ${globals['full-width']} ${globals['flex-column']} ${globals['gap-16']}`}
        >
          <div className={`${globals.flex} ${globals['gap-16']}`}>
            <div>
              <span className={`${styles.icon}`}>
                <IconsRepository.UserIcon size={18} />
              </span>
              <input
                name='firstName'
                value={firstName}
                onChange={onChange}
                type='text'
                placeholder='First Name'
              />
            </div>
            <div>
              <span className={`${styles.icon}`}>
                <IconsRepository.UserIcon size={18} />
              </span>
              <input
                name='lastName'
                value={lastName}
                onChange={onChange}
                type='text'
                placeholder='Last Name'
              />
            </div>
          </div>
          <div>
            <SelectAtom
              onSelect={(name: string) => {
                setSelectedGender(name)
              }}
              placeholder='Select Gender'
              state={inputState.normal}
              list={['MALE', 'FEMALE']}
              type='text'
              value={selectedGender && selectedGender?.length > 0 ? selectedGender : user?.gender}
              name='method'
            />
          </div>
          <div className={`${globals.flex} ${globals['gap-16']}`}>
            <div>
              <span className={`${styles.icon}`}>
                <IconsRepository.MailIcon size={18} />
              </span>
              <input
                name='email'
                value={email}
                onChange={onChange}
                type='text'
                placeholder='Email Name'
              />
            </div>
            <div>
              <span className={`${styles.icon}`}>
                <IconsRepository.PhoneIcon size={18} />
              </span>

              <input
                name='contact'
                value={contact}
                onChange={onChange}
                type='text'
                placeholder='Contact'
              />
            </div>
          </div>
          <ButtonAtom
            disabled={loading}
            height='50px'
            type={buttonTypes.secondary}
            onClick={() => {
              updateProfile()
            }}
            borderRadius='4px'
            btnText={loading ? 'Please wait...' : 'Update Profile Info'}
          />
        </div>
      </div>
    </div>
  )
}

export default NewPersonalInfoView
