import React from 'react'

import styles from './labeledinput.module.css'

import InputAtom, { InputProps } from '../../atoms/input/Input.atom'

type LabeledInputProps = {
  label: string
}

const LabeledInputMolecule = ({
  type,
  name,
  placeholder,
  iconDir,
  iconLeft,
  iconRight,
  value,
  label,
  iconClick,
  state,
  onChange,
}: LabeledInputProps & InputProps) => {
  return (
    <div className={styles.labeled__input}>
      <label htmlFor={label}>{label}</label>
      <InputAtom
        name={name}
        iconDir={iconDir}
        iconLeft={iconLeft}
        iconRight={iconRight}
        type={type}
        id={label}
        value={value}
        state={state}
        onChange={onChange}
        placeholder={placeholder}
        iconClick={iconClick}
      />
    </div>
  )
}

export default LabeledInputMolecule
