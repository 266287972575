import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import globals from '../../global.module.css'
import styles from './dashboard.module.css'

import SidebarOrganism from '../../components/organisms/sidebar/Sidebar.organism'
import DashboardNavigationOrganism from '../../components/organisms/dashboard-navigation/DashboardNavigation.organism'
import { User } from '../../types/user-types/user.type'
import { useAppSelector } from '../../lib/hooks'
import { RootState } from '../../app/store/store'
import { appConstants } from '../../app/constants/constants.app'
import { AccountTypes } from '../../repository/data/accountType'

const DashboardPage = () => {
  const navigate = useNavigate()
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)

  useEffect(() => {
    if (!authState.requestResponse.data?.user) {
      navigate(appConstants.routes.auth.login)
    }

    if (
      authState.requestResponse.data?.user &&
      authState.requestResponse.data.user.accountType === AccountTypes.INVESTOR
    ) {
      navigate(appConstants.routes.investorsView)
    }

    if (
      authState.requestResponse.data?.user &&
      authState.requestResponse.data.user.accountType === AccountTypes.CUSTOMER
    ) {
      navigate('/dashboard/orders')
    }
  }, [authState.requestResponse])

  const user: User = authState.requestResponse.data?.user as User

  return (
    <div className={`${styles.dashboard} ${globals['full-width']}`}>
      <div className={styles.side__bar}>
        <SidebarOrganism user={user} />
      </div>
      <div className={styles.main__content}>
        <DashboardNavigationOrganism user={user} />
        {user !== undefined ? <Outlet /> : ''}
      </div>
    </div>
  )
}

export default DashboardPage
