import React from 'react'
import { IconType } from '../../types/icon.type'

const OpenInNewTabIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 25.3333H8C7.26667 25.3333 6.66667 24.7333 6.66667 24V8C6.66667 7.26667 7.26667 6.66667 8 6.66667H14.6667C15.4 6.66667 16 6.06667 16 5.33333C16 4.6 15.4 4 14.6667 4H6.66667C5.18667 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V17.3333C28 16.6 27.4 16 26.6667 16C25.9333 16 25.3333 16.6 25.3333 17.3333V24C25.3333 24.7333 24.7333 25.3333 24 25.3333ZM18.6667 5.33333C18.6667 6.06667 19.2667 6.66667 20 6.66667H23.4533L11.28 18.84C10.76 19.36 10.76 20.2 11.28 20.72C11.8 21.24 12.64 21.24 13.16 20.72L25.3333 8.54667V12C25.3333 12.7333 25.9333 13.3333 26.6667 13.3333C27.4 13.3333 28 12.7333 28 12V5.33333C28 4.6 27.4 4 26.6667 4H20C19.2667 4 18.6667 4.6 18.6667 5.33333Z'
        fill={color}
      />
    </svg>
  )
}

export default OpenInNewTabIcon
