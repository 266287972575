import React from 'react'
import { IconType } from '../../types/icon.type'

const ArrowIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66668 17.3333H21.56L15.0533 23.84C14.5333 24.36 14.5333 25.2133 15.0533 25.7333C15.5733 26.2533 16.4133 26.2533 16.9333 25.7333L25.72 16.9467C26.24 16.4267 26.24 15.5867 25.72 15.0667L16.9467 6.26668C16.4267 5.74668 15.5867 5.74668 15.0667 6.26668C14.5467 6.78668 14.5467 7.62668 15.0667 8.14668L21.56 14.6667H6.66668C5.93334 14.6667 5.33334 15.2667 5.33334 16C5.33334 16.7333 5.93334 17.3333 6.66668 17.3333Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowIcon
