import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../global.module.css'
import styles from './farmstore.module.css'

import FooterOrganism from '../../components/organisms/footer/Footer.organism'
import { User } from '../../types/user-types/user.type'
import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { Link } from 'react-router-dom'
import NavbarOrganism from '../../components/organisms/navbar/Navbar.organism'
import Loader from '../../components/loader/Loader'
import { IconsRepository } from '../../repository/icons/icons.repository'

const FarmstorePage = () => {
  const [farmers, setFarmers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)

  const fetchUsers = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ farmers: User[] }>('/farmers')
        .then((response) => {
          setFarmers(response.data.farmers)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
    <>
      <NavbarOrganism />
      <div className={`${styles.stores} ${globals['p-desktop']} ${globals['full-width']}`}>
        <h2>Browse Farm Stores</h2>
        <h3>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum incidunt sequi unde,
          voluptatum ducimus eaque laborum labore ex praesentium commodi!
        </h3>
        {loading ? <Loader /> : ''}
        <div className={`${styles.farmers} ${globals['full-width']}`}>
          {farmers.length > 0 &&
            !loading &&
            farmers.map((farmer, index) => {
              return (
                <Link to={`/farm-store/${farmer._id}`} key={index}>
                  <article className={styles.card}>
                    <div className={styles.image}>
                      {farmer.profileURL && farmer.profileURL.length > 0 ? (
                        <img src={farmer.profileURL} alt={farmer.firstName} />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={styles.body}>
                      <h3>
                        {farmer?.firstName} {farmer?.lastName}
                      </h3>
                      {/* <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus earum
                        facilis laboriosam, dignissimos reiciendis incidunt.
                      </p> */}

                      {/* <div
                        style={{ marginTop: '20px' }}
                        className={`${styles.rating} ${globals.flex} ${globals['a-center']}`}
                      >
                        <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                        <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                        <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                        <IconsRepository.FilledStarIcon color='var(--YELLOW_COLOR)' size={22} />
                        <p>(56 People)</p>
                      </div> */}
                    </div>
                  </article>
                </Link>
              )
            })}
        </div>
      </div>
      <FooterOrganism />
    </>
  )
}

export default FarmstorePage
