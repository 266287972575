import React from 'react'
import { IconType } from '../../types/icon.type'

const FolderIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.2267 7.99999L14.8934 10.6667H26.6667V24H5.33335V7.99999H12.2267ZM13.3334 5.33333H5.33335C3.86669 5.33333 2.68002 6.53333 2.68002 7.99999L2.66669 24C2.66669 25.4667 3.86669 26.6667 5.33335 26.6667H26.6667C28.1334 26.6667 29.3334 25.4667 29.3334 24V10.6667C29.3334 9.19999 28.1334 7.99999 26.6667 7.99999H16L13.3334 5.33333Z'
        fill={color}
      />
    </svg>
  )
}

export default FolderIcon
