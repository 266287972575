import React from 'react'
import { IconType } from '../../types/icon.type'

const CreateNewFolderIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.6667 7.99999H16L13.3334 5.33333H5.33335C3.85335 5.33333 2.68002 6.51999 2.68002 7.99999L2.66669 24C2.66669 25.48 3.85335 26.6667 5.33335 26.6667H26.6667C28.1467 26.6667 29.3334 25.48 29.3334 24V10.6667C29.3334 9.18666 28.1467 7.99999 26.6667 7.99999ZM26.6667 24H5.33335V7.99999H12.2267L14.8934 10.6667H26.6667V24ZM16 18.6667H18.6667V21.3333H21.3334V18.6667H24V16H21.3334V13.3333H18.6667V16H16V18.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default CreateNewFolderIcon
