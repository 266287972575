import React, { useCallback, useEffect, useState } from 'react'

import globals from '../../../../global.module.css'
import styles from './investors.module.css'

import { User } from '../../../../types/user-types/user.type'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import Loader from '../../../../components/loader/Loader'

const InvestorsDPage = () => {
  const [investors, setInvestors] = useState<User[]>([])
  const [loading, setLoading] = useState(false)

  const fetchUsers = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get<{ investors: User[] }>('/farmers/inv')
        .then((response) => {
          setInvestors(response.data.investors)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
    <div
      className={`${styles.orders} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${globals['p-desktop']}`}
    >
      <div className={styles.head}>
        <h2>Browse Investors List</h2>
      </div>
      {loading ? <Loader /> : ''};
      <div className={`${styles.farmers} ${globals['full-width']}`}>
        {investors.length > 0 &&
          !loading &&
          investors.map((farmer, index) => {
            return (
              <article key={index} className={styles.card}>
                <div className={styles.image}>
                  <img src={farmer.imageUrl} alt={farmer.firstName} />
                </div>
                <div className={styles.body}>
                  <h3>
                    {farmer?.firstName} {farmer?.lastName}
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus earum
                    facilis laboriosam, dignissimos reiciendis incidunt.
                  </p>
                </div>
              </article>
            )
          })}
      </div>
    </div>
  )
}

export default InvestorsDPage
