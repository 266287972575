import axios, { AxiosResponse } from 'axios'

import { serverApi } from '../../app/config/api.config'
import { LocalStorageService } from '../../service/localstorage.service'
import { LoginResponseType } from '../../types/auth.type'

export const InternalServerError = {
  message: 'Internal error occured during the request.',
  code: -500,
}

export const onFulfilledRequest = (response: AxiosResponse) => response

// eslint-disable-next-line
export const onRejectedRequest = (error: any) => Promise.reject(InternalServerError)

const localStorageService = new LocalStorageService()

const authData: LoginResponseType = JSON.parse(localStorageService.getLoginData() as string)

export const publicApiRequest = (url?: string) => {
  return axios.create({
    headers: {
      Authorization: `Bearer ${authData?.accessToken}`,
    },
    baseURL: url !== undefined ? url : serverApi,
  })
}
