import { Link } from 'react-router-dom'
import Fade from 'react-reveal'

import globals from '../../../../global.module.css'
import styles from './hero.module.css'

import { staticSvgs } from '../../../../app/assets/images'

const HeroTemplate = () => {
  return (
    <section
      className={`${styles.hero} ${globals['p-desktop']} ${globals['gap-24']} ${globals.flex} ${globals['a-center']}`}
    >
      <div className={`${styles.content} ${globals.flex} ${globals['flex-column']}`}>
        <Fade up>
          <h1>
            <span>Agriculture</span> at Heart.
          </h1>
        </Fade>
        <Fade up>
          <h2>
            Accelerating financial acquisition and market exposure for the agricultural sector to
            foster an increase in farm yield and profit generation for farmers.
          </h2>
        </Fade>

        <div className={`${styles.button__group} ${globals.flex}`}>
          <Link to='/welcome'>
            <button className={`${globals.flex} ${globals['a-center']} ${globals['gap-16']}`}>
              Get Started
            </button>
          </Link>
        </div>
      </div>

      <div className={`${styles.images__connection} ${globals.flex} ${globals['center-items']}`}>
        <img src={staticSvgs.home.HeroBgGroup} alt='hero' />
      </div>
    </section>
  )
}

export default HeroTemplate
