import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IProduct } from '../../../types/product-types/product.type'

type InitialStatetype = {
  products: IProduct[]
}

export const initialState: InitialStatetype = {
  products: [],
}

const cartSlice = createSlice({
  name: 'cartSlice',
  initialState: initialState,
  reducers: {
    resetCartState: (state) => {
      state.products = []
    },

    addToCart: (state, action: PayloadAction<{ product: IProduct }>) => {
      const productInCart = state.products.find((item) => item._id === action.payload.product._id)

      if (productInCart !== undefined && productInCart.cartCount !== undefined) {
        productInCart.cartCount++
      } else {
        state.products.push({ ...action.payload.product, cartCount: 1 })
      }
    },

    removeFromCart: (state, action: PayloadAction<{ id: string }>) => {
      const productNotMatchingId = state.products.filter((item) => item._id !== action.payload.id)
      state.products = productNotMatchingId
    },

    decrementQuantity: (state, action: PayloadAction<{ id: string }>) => {
      const productInCart = state.products.find((item) => item._id === action.payload.id)

      if (productInCart !== undefined && productInCart.cartCount !== undefined) {
        productInCart.cartCount--
      }
    },
  },
  extraReducers: (builder) => {
    builder
  },
})

export const { addToCart, decrementQuantity, resetCartState, removeFromCart } = cartSlice.actions
export default cartSlice.reducer
