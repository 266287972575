import React from 'react'
import { IconType } from '../../types/icon.type'

const AnnouncementIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 16C24 16.7333 24.6 17.3333 25.3333 17.3333H28C28.7333 17.3333 29.3333 16.7333 29.3333 16C29.3333 15.2667 28.7333 14.6667 28 14.6667H25.3333C24.6 14.6667 24 15.2667 24 16Z'
        fill={color}
      />
      <path
        d='M22.12 22.4267C21.68 23.0133 21.8 23.8267 22.3867 24.2533C23.0933 24.7733 23.84 25.3333 24.5467 25.8667C25.1333 26.3067 25.96 26.1867 26.3867 25.6C26.3867 25.5867 26.4 25.5867 26.4 25.5733C26.84 24.9867 26.72 24.16 26.1333 23.7333C25.4267 23.2 24.68 22.64 23.9867 22.12C23.4 21.68 22.5733 21.8133 22.1333 22.4C22.1333 22.4133 22.12 22.4267 22.12 22.4267Z'
        fill={color}
      />
      <path
        d='M26.4133 6.41333C26.4133 6.39999 26.4 6.39999 26.4 6.38666C25.96 5.79999 25.1333 5.67999 24.56 6.11999C23.8533 6.65333 23.0933 7.21333 22.4 7.74666C21.8133 8.18666 21.7067 9.01333 22.1467 9.58666C22.1467 9.59999 22.16 9.59999 22.16 9.61333C22.6 10.2 23.4133 10.32 24 9.87999C24.7067 9.35999 25.4533 8.78666 26.16 8.25333C26.7333 7.82666 26.84 6.99999 26.4133 6.41333Z'
        fill={color}
      />
      <path
        d='M10.6667 12H5.33335C3.86669 12 2.66669 13.2 2.66669 14.6667V17.3333C2.66669 18.8 3.86669 20 5.33335 20H6.66669V24C6.66669 24.7333 7.26669 25.3333 8.00002 25.3333C8.73335 25.3333 9.33335 24.7333 9.33335 24V20H10.6667L17.3334 24V8L10.6667 12Z'
        fill={color}
      />
      <path
        d='M20.6667 16C20.6667 14.2267 19.8934 12.6267 18.6667 11.5333V20.4533C19.8934 19.3733 20.6667 17.7733 20.6667 16Z'
        fill={color}
      />
    </svg>
  )
}

export default AnnouncementIcon
