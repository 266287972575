import React from 'react'

import styles from '../view.module.css'

const ToolMakersView = () => {
  return (
    <div className={`${styles.view}`}>
      <h2>Tool Makers Available</h2>
    </div>
  )
}

export default ToolMakersView
