import React from 'react'
import { IconType } from '../../types/icon.type'

const HelpIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 18.43H13L8.55 21.39C8.39982 21.4901 8.22528 21.5476 8.045 21.5563C7.86472 21.565 7.68546 21.5245 7.52635 21.4393C7.36724 21.3541 7.23424 21.2273 7.14154 21.0725C7.04884 20.9176 6.99992 20.7405 7 20.56V18.43C4 18.43 2 16.43 2 13.43V7.43C2 4.43 4 2.43 7 2.43H17C20 2.43 22 4.43 22 7.43V13.43C22 16.43 20 18.43 17 18.43Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 11.36V11.15C12 10.47 12.42 10.11 12.84 9.82C13.25 9.54 13.66 9.18 13.66 8.52C13.66 7.6 12.92 6.86 12 6.86C11.08 6.86 10.34 7.6 10.34 8.52M11.995 13.75H12.005'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default HelpIcon
