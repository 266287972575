import React, { useCallback, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './productdetails.module.css'

import { IProduct, ProductsResponse } from '../../../types/product-types/product.type'
import ButtonAtom from '../../atoms/button/Button.atom'
import { buttonTypes, inputState } from '../../../app/constants/constants.app'
import LabeledInputMolecule from '../../molecules/labeled-input/LabeledInput.molecule'
import UseForm from '../../../lib/hooks/use-form'
import { ICategory } from '../../../types/category-types/category.type'
import SelectAtom from '../../atoms/input/Select.atom'
import { publicApiRequest } from '../../../lib/hooks/axios-instance'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../app/features/notification/notification.slice'
import { useAppDispatch } from '../../../lib/hooks'
import Loader from '../../loader/Loader'

type Props = {
  product: IProduct | undefined
  cats: ICategory[]
  extractCatNames: () => string[]
  setSelectedCat: React.Dispatch<React.SetStateAction<string>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  selectedCat: string
  fetchProducts: () => Promise<void>
  loading: boolean
}

const ProductDetailsOrganism = ({
  product,
  cats,
  setSelectedCat,
  selectedCat,
  extractCatNames,
  fetchProducts,
  loading,
  setLoading,
}: Props) => {
  const [trigger, setTrigger] = useState(false)

  const [imageSelection, setImageSelection] = useState<File>({} as File)
  const [editing, setEditing] = useState(false)
  const dispatch = useAppDispatch()

  const { form, updateForm } = UseForm(
    {
      name: product?.name,
      description: product?.description,
      price: product?.price,
      quantity: product?.quantity,
      unit: product?.unit,
      categoryId: '',
    },
    setTrigger,
    trigger,
  )

  const editProductData = useCallback(async () => {
    setLoading(true)

    const body = {
      name: form.name,
      quantity: form.quantity,
      price: form.price,
      unit: form.unit,
      description: form.description,
    }

    try {
      await publicApiRequest()
        .put<ProductsResponse>(`/products/${product?._id}`, body)
        .then((_response) => {
          dispatch(
            triggerNotification({
              message: 'Product successfully edited.',
              title: NotificationTitle.SUCCESS,
            }),
          )
          fetchProducts()
        })
    } catch (e) {
      console.log({ e })
      dispatch(
        triggerNotification({
          message: 'An error occured, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <div
      className={`${styles.product__details} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']} ${globals['full-width']}`}
    >
      <div className={`${globals.flex} ${globals['gap-24']}`}>
        <div>
          <div className={styles.image}>
            <img
              src={
                imageSelection.name !== undefined
                  ? URL.createObjectURL(imageSelection)
                  : product?.imageUrl
              }
              width='100%'
              height={400}
              alt={product?.name}
            />
          </div>
        </div>
        {editing ? (
          <div className={`${styles.file__picker} ${globals.flex} ${globals['center-items']}`}>
            <h4>Choose a file</h4>
            <input
              type='file'
              onChange={(e) => {
                setImageSelection((e.target.files as FileList)[0])
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      {loading ? <Loader /> : ''}

      {editing && !loading ? (
        <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}>
          <SelectAtom
            onSelect={(name: string) => {
              setSelectedCat(name)
              cats.map((item) => {
                if (item.name === name) {
                  updateForm('categoryId', item._id)
                }
              })
            }}
            placeholder='Select Category'
            state={inputState.normal}
            list={extractCatNames()}
            type='text'
            value={selectedCat}
            name='method'
          />
          <LabeledInputMolecule
            label='Product name'
            placeholder='paw-paw'
            onChange={(e) => {
              updateForm('name', e.target.value)
            }}
            state={inputState.normal}
            type='text'
            name='name'
            value={form.name}
          />
          <LabeledInputMolecule
            label='Description'
            placeholder='Fresh paw=paw'
            onChange={(e) => {
              updateForm('description', e.target.value)
            }}
            state={inputState.normal}
            type='text'
            name='description'
            value={form.description}
          />
          <LabeledInputMolecule
            label='Quantity Available'
            placeholder='100'
            onChange={(e) => {
              updateForm('quantity', e.target.value)
            }}
            state={inputState.normal}
            type='number'
            name='quantity'
            value={`${form.quantity}`}
          />
          <LabeledInputMolecule
            label='Unit'
            placeholder='basket'
            onChange={(e) => {
              updateForm('unit', e.target.value)
            }}
            state={inputState.normal}
            type='text'
            name='unit'
            value={form.unit}
          />
          <LabeledInputMolecule
            label='Price (XAF)'
            placeholder='1000'
            onChange={(e) => {
              updateForm('price', e.target.value)
            }}
            state={inputState.normal}
            type='number'
            name='price'
            value={`${form.price}`}
          />
          <div
            className={`${styles.btn} ${globals.flex} ${globals['gap-16']} ${globals['a-center']}`}
          >
            <ButtonAtom
              fontSize='1.8rem'
              type={buttonTypes.primary}
              btnText={'Edit Data'}
              fullWidth={true}
              onClick={() => {
                setImageSelection({} as File)
                editProductData()
              }}
            />
            <ButtonAtom
              fontSize='1.8rem'
              type={buttonTypes.primary}
              btnText={'Save Image'}
              fullWidth={true}
              onClick={async () => {
                try {
                  const body = new FormData()
                  body.append('file', imageSelection)

                  await publicApiRequest()
                    .patch(`/products/${product?._id}`, body)
                    .then((response) => {
                      console.log(response.data)
                    })
                } catch (e) {
                  console.log(e)
                } finally {
                  fetchProducts()
                  setImageSelection({} as File)
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          <h2>{product?.name}</h2>
          <h3 style={{ marginTop: '15px' }}>
            {product?.quantity} {product?.unit}
          </h3>
          <h3>Unit Price: {product?.price} XAF</h3>
          <p>{product?.description}</p>

          <h3 className={`${styles.h3} ${styles.up}`}>
            Created At: <span>{product?.createdAt}</span>
          </h3>
          <h3 className={styles.h3}>
            Updated At: <span>{product?.updatedAt}</span>
          </h3>

          <div
            className={`${styles.btn} ${globals.flex} ${globals['gap-16']} ${globals['a-center']}`}
          >
            <ButtonAtom
              fontSize='1.8rem'
              type={buttonTypes.primary}
              btnText={'Edit'}
              fullWidth={true}
              onClick={() => {
                setEditing(!editing)
              }}
              bgColor='var(--AGROX-LIGHT-GREEN-COLOR)'
            />
            <ButtonAtom
              fontSize='1.8rem'
              type={buttonTypes.primary}
              btnText={'Delete'}
              fullWidth={true}
              onClick={async () => {
                try {
                  await publicApiRequest()
                    .delete(`/products/${product?._id}`)
                    .then(() => {
                      fetchProducts()
                    })
                } catch (e) {
                  console.log(e)
                }
              }}
              bgColor='var(--AGROX-RED-COLOR)'
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductDetailsOrganism
