import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './sidebar.module.css'

import { IconsRepository } from '../../../repository/icons/icons.repository'
import useWindowSize from '../../../lib/hooks/use-window-soze'
import { AccountTypes } from '../../../repository/data/accountType'
import { User } from '../../../types/user-types/user.type'
import { LocalStorageService } from '../../../service/localstorage.service'
import { useAppDispatch } from '../../../lib/hooks'
import { resetLoginUserState } from '../../../app/features/auth/slices/login-user.slice'

const localStorage = new LocalStorageService()

const SidebarOrganism = ({ user }: { user: User }) => {
  const { width } = useWindowSize()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const iconSize = width < 1520 ? 20 : 36

  const sidebarLinks = [
    {
      linkName: 'Dashboard',
      path: '/dashboard',
      icon: <IconsRepository.DashboardIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER, AccountTypes.INVESTOR, AccountTypes.MANUFACTURER],
    },
    {
      linkName: 'Manage Tools',
      path: '/dashboard',
      icon: <IconsRepository.DashboardIcon size={iconSize} />,
      accountType: [AccountTypes.MACHINERY],
    },
    {
      linkName: 'Manage Orders',
      path: '/dashboard/tools/orders',
      icon: <IconsRepository.OrderIcon size={iconSize} />,
      accountType: [AccountTypes.MACHINERY],
    },
    {
      linkName: 'Shop Tools',
      path: '/dashboard/cart',
      icon: <IconsRepository.ShoppingCartIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER],
    },
    {
      linkName: 'Investments',
      path: '/about-agrox',
      icon: <IconsRepository.InvestorsIcon size={iconSize} />,
      accountType: [AccountTypes.INVESTOR],
    },
    {
      linkName: 'Investors',
      path: '/dashboard/investors',
      icon: <IconsRepository.InvestorsIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER],
    },
    {
      linkName: 'Analysis',
      path: '/dashboard/analysis',
      icon: <IconsRepository.AnalysisIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER],
    },
    {
      linkName: 'Farm Orders',
      path: '/dashboard/orders',
      icon: <IconsRepository.OrderIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER, AccountTypes.CUSTOMER],
    },
    {
      linkName: 'Tool Orders',
      path: '/dashboard/tool-orders',
      icon: <IconsRepository.OrderIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER],
    },
    {
      linkName: 'Manage Projects',
      path: '/dashboard/manage-projects',
      icon: <IconsRepository.StorefrontIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER],
    },
    {
      linkName: 'Settings',
      path: '/dashboard/settings',
      icon: <IconsRepository.SettingsIcon size={iconSize} />,
      accountType: [AccountTypes.FARMER, AccountTypes.INVESTOR, AccountTypes.MANUFACTURER],
    },
  ]

  return (
    <aside className={`${styles.sidebar} ${globals.flex} ${globals['flex-column']}`}>
      <div
        className={`${styles.sidebar__content} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${globals['s-b']}`}
      >
        <ul className={`${styles.items} ${globals.flex} ${globals['flex-column']}`}>
          {sidebarLinks.length > 0 &&
            sidebarLinks.map((link, index) => (
              <Fragment key={index}>
                {link.accountType.includes(user?.accountType as AccountTypes) ? (
                  <li className={`${styles.item} `}>
                    <Link
                      to={link.path}
                      className={`${globals.flex} ${
                        location.pathname === link.path ? styles.active : ''
                      } ${globals['a-center']} ${globals['gap-24']}`}
                    >
                      <span>{link.icon}</span>
                      <span>{link.linkName}</span>
                    </Link>
                  </li>
                ) : (
                  ''
                )}
              </Fragment>
            ))}
        </ul>
        <ul className={`${styles.items} ${globals.flex} ${globals['flex-column']}`}>
          {/* <li className={`${styles.item} `}>
            <Link
              to='/'
              className={`${globals.flex} ${location.pathname === '/' ? styles.active : ''} ${
                globals['a-center']
              } ${globals['gap-24']}`}
            >
              <span>
                <IconsRepository.HelpIcon size={iconSize} />
              </span>
              <span>Support</span>
            </Link>
          </li> */}
          <li className={`${styles.item} `}>
            <Link
              to='/dashboard/profile'
              className={`${globals.flex} ${
                location.pathname === '/dashboard/profile' ? styles.active : ''
              } ${globals['a-center']} ${globals['gap-24']}`}
            >
              <span>
                <IconsRepository.UserIcon size={iconSize} />
              </span>
              <span>Profile</span>
            </Link>
          </li>
          <li className={`${styles.item} `}>
            <Link
              onClick={(e) => {
                e.preventDefault()
                localStorage.deleteLoginData()
                dispatch(resetLoginUserState())
              }}
              to='#'
              className={`${globals.flex} ${globals['a-center']} ${globals['gap-24']}`}
            >
              <span>
                <IconsRepository.LogoutIcon size={iconSize} />
              </span>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default SidebarOrganism
