import React from 'react'
import { IconType } from '../../types/icon.type'

const DeleteIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.99999 25.3333C7.99999 26.8 9.19999 28 10.6667 28H21.3333C22.8 28 24 26.8 24 25.3333V9.33333H7.99999V25.3333ZM25.3333 5.33333H20.6667L19.3333 4H12.6667L11.3333 5.33333H6.66666V8H25.3333V5.33333Z'
        fill={color}
      />
    </svg>
  )
}

export default DeleteIcon
