import React from 'react'

import { IconType } from '../../types/icon.type'

const CheckIcon = ({ size = 32, color = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 21.56L7.37333 16.9333C6.85333 16.4133 6.01333 16.4133 5.49333 16.9333C4.97333 17.4533 4.97333 18.2933 5.49333 18.8133L11.0667 24.3867C11.5867 24.9067 12.4267 24.9067 12.9467 24.3867L27.0533 10.28C27.5733 9.75999 27.5733 8.91999 27.0533 8.39999C26.5333 7.87999 25.6933 7.87999 25.1733 8.39999L12 21.56Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckIcon
