import React, { useState } from 'react'

import styles from '../view.module.css'

import { RootState } from '../../../../app/store/store'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import PCardMolecule from '../cards/p-card/PCard.molecule'
import ModalMolecule from '../../../../components/molecules/modal/Modal.molecule'
import PDetailsMolecule from '../cards/p-details/PDetails.molecule'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { buttonTypes } from '../../../../app/constants/constants.app'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { resetToolCartState } from '../../../../app/features/tool-cart/tool-cart.slice'
import { User } from '../../../../types/user-types/user.type'

const ToolCartView = () => {
  const toolCartState = useAppSelector((state: RootState) => state.toolCartSlice)
  const authState = useAppSelector((state) => state.loginUserSlice)

  const [selectedId, setSelectedId] = useState('')

  const [creating, setCreating] = useState(false)
  const dispatch = useAppDispatch()

  const placeOrder = async () => {
    setCreating(true)
    const ids: string[] = []
    const qtties: number[] = []

    toolCartState.tools.map((item) => {
      ids.push(item._id as string)
      qtties.push(item.cartCount as number)
    })

    const body = {
      customerId: authState.requestResponse.data?.user._id,
      docModel: 'Tool',
      farmerId: (toolCartState.tools[0].userId as User)._id,
      productIds: ids,
      productQuantities: qtties,
      delivered: false,
      collected: false,
      amount: toolCartState.tools.reduce((sum, prod) => {
        return sum + prod.price * (prod.cartCount as number)
      }, 0),
    }

    try {
      await publicApiRequest()
        .post('/orders', body)
        .then((response) => {
          console.log(response)
          dispatch(
            triggerNotification({
              message: 'Order successfully created.',
              title: NotificationTitle.SUCCESS,
            }),
          )

          dispatch(resetToolCartState())
        })
    } catch (e) {
      console.log(e)

      dispatch(
        triggerNotification({
          message: 'An error occured creating your order, please try again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setCreating(false)
    }
  }

  return (
    <div className={`${styles.view}`}>
      <h2>My Cart: {toolCartState?.tools?.length} tools</h2>
      {selectedId.length > 0 && toolCartState.tools.find((p) => p._id === selectedId) ? (
        <ModalMolecule
          close={() => {
            setSelectedId('')
          }}
        >
          <PDetailsMolecule
            toolCard
            image={toolCartState.tools.find((p) => p._id === selectedId)?.imageUrl as string}
            name={toolCartState.tools.find((p) => p._id === selectedId)?.name as string}
            description={
              toolCartState.tools.find((p) => p._id === selectedId)?.description as string
            }
            price={`${toolCartState.tools.find((p) => p._id === selectedId)?.price}`}
            unit={''}
            tool={toolCartState.tools.find((p) => p._id === selectedId)}
            setSelectedId={setSelectedId}
            ableToEdit={false}
            quantity={toolCartState.tools.find((p) => p._id === selectedId)?.quantity}
          />
        </ModalMolecule>
      ) : (
        ''
      )}
      <div className={`${styles.grid}`}>
        {toolCartState.tools &&
          toolCartState.tools.length > 0 &&
          toolCartState.tools.map((tool, index) => {
            return (
              <PCardMolecule
                toolCard={true}
                count={toolCartState.tools.find((p) => p._id === selectedId)?.cartCount}
                onClick={() => {
                  setSelectedId(tool._id as string)
                }}
                image={tool.imageUrl as string}
                name={tool.name}
                description={tool.description}
                price={`${tool.price}`}
                unit=''
                key={index}
              />
            )
          })}
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <ButtonAtom
          onClick={() => {
            placeOrder()
          }}
          disabled={creating}
          btnText={creating ? 'Please wait...' : 'Confirm & Place Order'}
          type={buttonTypes.primary}
        />
      </div>
    </div>
  )
}

export default ToolCartView
