import { LoginResponseType } from './../types/auth.type'

export class LocalStorageService {
  storeLoginData(data: LoginResponseType) {
    localStorage.setItem('user', JSON.stringify(data))
  }

  storePreviousRoute(path: string) {
    localStorage.setItem('prevPath', path)
  }

  getLoginData() {
    return localStorage.getItem('user')
  }

  deleteLoginData() {
    localStorage.removeItem('user')
  }
}
