import React from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './dashboardnavigation.module.css'

import { IconsRepository } from '../../../repository/icons/icons.repository'
import { staticSvgs } from '../../../app/assets/images'
import useWindowSize from '../../../lib/hooks/use-window-soze'
import { User } from '../../../types/user-types/user.type'
import { useAppSelector } from '../../../lib/hooks'
import { AccountTypes } from '../../../repository/data/accountType'

const DashboardNavigationOrganism = ({ user }: { user: User }) => {
  const { width } = useWindowSize()
  const iconSize = width < 1520 ? 20 : 36
  const cartState = useAppSelector((state) => state.toolCartSlice)

  const authState = useAppSelector((state) => state.loginUserSlice)

  return (
    <nav
      role='navigation'
      className={`${styles.navigation} ${globals['p-desktop']} ${globals.flex} ${globals['center-items']} ${globals['s-b']}`}
    >
      <div
        className={`${styles.sidebar__toggler} ${globals.flex} ${globals['center-items']} ${globals['gap-16']}`}
      >
        <span>
          <IconsRepository.HambugerMenuIcon size={30} />
        </span>
        <h2>
          Hello, <span>{user?.firstName} 👋</span>
        </h2>
      </div>

      <div
        className={`${styles.profile__icons} ${globals.flex} ${globals['a-center']} ${globals['gap-24']}`}
      >
        <div
          className={`${styles.icons} ${globals.flex} ${globals['a-center']} ${globals['gap-10']}`}
        >
          <span title='orders'>
            <IconsRepository.OrderIcon size={iconSize} />
          </span>
          <span title='settings'>
            <IconsRepository.SettingsIcon size={iconSize} />
          </span>
          <span title='notifications'>
            <IconsRepository.BellIcon size={iconSize} />
          </span>
          {authState.requestResponse.data?.user &&
          authState.requestResponse.data?.user.accountType === AccountTypes.FARMER ? (
            <Link to='/cart?user=farmer'>
              <span className={styles.notification} title='notifications'>
                <IconsRepository.ShoppingCartIcon size={iconSize} />
                <div className={styles.bubble}>
                  <span>{cartState.tools.length}</span>
                </div>
              </span>
            </Link>
          ) : (
            ''
          )}
        </div>
        <div className={styles.profile}>
          <Link to={'/dashboard/profile'}>
            {' '}
            <div className={styles.image}>
              <img
                src={
                  authState.requestResponse.data?.user?.profileURL
                    ? authState.requestResponse.data?.user?.profileURL
                    : staticSvgs.headerAvatar.Avatar
                }
                alt='profile'
              />
            </div>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default DashboardNavigationOrganism
