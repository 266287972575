import React from 'react'

import globals from '../../../../global.module.css'
import styles from './analysis.module.css'

import ChartComponent from '../../../../components/molecules/chart/Chart.molecule'
import { tableData } from '../../../../repository/data/tableData.data'

const AnalysisDashboardPage = () => {
  return (
    <div
      className={`${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${globals['p-desktop']} ${styles.analysis}`}
    >
      <div className={`${styles.chart} ${globals.flex} ${globals['gap-16']}`}>
        <div>
          <ChartComponent type='area' />
        </div>
        <div>
          <ChartComponent type='bar' />
        </div>
      </div>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th>Products</th>
              <th>Price</th>
              <th>Units</th>
              <th>Quantity Sold</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length !== 0 &&
              tableData !== undefined &&
              tableData.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <figure className={styles.img}>
                        <img src={data.productImage} alt='' />
                      </figure>
                      {data.product}
                    </td>
                    <td>{data.price}</td>
                    <td>{data.units}</td>
                    <td>{data.quantitySold}</td>
                    <td>{data.totalAmount}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AnalysisDashboardPage
