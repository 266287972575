import { Link, Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import globals from '../../global.module.css'
import styles from './dashboard.module.css'

import TabComponentMolecule from '../../components/molecules/tab-component/TabComponent.molecule'
import { IconsRepository } from '../../repository/icons/icons.repository'
import ButtonAtom from '../../components/atoms/button/Button.atom'
import { appConstants, buttonTypes } from '../../app/constants/constants.app'
import { RootState } from '../../app/store/store'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { resetLoginUserState } from '../../app/features/auth/slices/login-user.slice'
import { LocalStorageService } from '../../service/localstorage.service'
import { AccountTypes } from '../../repository/data/accountType'

const localStorage = new LocalStorageService()

const NewDashboardPage = () => {
  const navigate = useNavigate()
  const authState = useAppSelector((state: RootState) => state.loginUserSlice)
  const dispatch = useAppDispatch()
  const [listOfTabs, setListOfTabs] = useState([
    { id: 0, label: 'Manage Products', link: '/new-dashboard' },
    { id: 1, label: 'Orders', link: '/new-dashboard/orders' },
    { id: 2, label: 'Farming Tools', link: '/new-dashboard/farming-tools' },
    { id: 3, label: 'Personal Info', link: '/new-dashboard/personal-info' },
  ])

  useEffect(() => {
    if (!authState.requestResponse.data?.user) {
      navigate(appConstants.routes.auth.login)
    }
  }, [authState.requestResponse])

  const user = authState.requestResponse.data?.user

  useEffect(() => {
    if (user) {
      if (user.accountType === AccountTypes.FARMER) {
        setListOfTabs([
          { id: 0, label: 'Manage Products', link: '/new-dashboard' },
          { id: 6, label: 'Manage Project', link: '/new-dashboard/create-project' },
          { id: 1, label: 'Orders', link: '/new-dashboard/orders' },
          { id: 2, label: 'Farming Tools', link: '/new-dashboard/farming-tools' },
          { id: 3, label: 'Personal Info', link: '/new-dashboard/personal-info' },
          { id: 4, label: 'Cart', link: '/new-dashboard/cart' },
          { id: 5, label: 'My Orders', link: '/new-dashboard/my-orders' },
        ])
      } else if (user.accountType === AccountTypes.MACHINERY) {
        setListOfTabs([
          { id: 0, label: 'Manage Tools', link: '/new-dashboard' },
          { id: 1, label: 'Orders', link: '/new-dashboard/orders' },
          { id: 3, label: 'Personal Info', link: '/new-dashboard/personal-info' },
          { id: 5, label: 'My Orders', link: '/new-dashboard/my-orders' },
        ])
      } else if (user.accountType === AccountTypes.INVESTOR) {
        setListOfTabs([{ id: 2, label: 'Farming Projects', link: '/new-dashboard/projects' }])
      } else {
        setListOfTabs([
          { id: 0, label: 'Manage Orders', link: '/new-dashboard' },
          { id: 3, label: 'Personal Info', link: '/new-dashboard/personal-info' },
        ])
      }
    }
  }, [user])

  return (
    <div className={`${globals.flex}${styles.dashboard} ${globals['p-desktop']}`}>
      <nav
        className={`${styles.navigation} ${globals['full-width']} ${globals.flex} ${globals['a-center']} ${globals['s-b']}`}
        role='navigation'
      >
        <div className={`${styles.logo}`}>
          <Link to={appConstants.routes.home}>
            <IconsRepository.LogoIcon color='var(--AGROX-GREEN-COLOR)' />
          </Link>
        </div>

        <ul
          className={`${globals.flex} ${styles.menu} ${globals['a-center']} ${globals['gap-24']}`}
        >
          <li>
            <Link to={'/'}>
              <IconsRepository.UserIcon size={18} />{' '}
              {authState.requestResponse.data?.user?.firstName}{' '}
              <span>| {authState.requestResponse.data?.user?.accountType}</span>
            </Link>
          </li>
          <li>
            <Link to={'/'}>Home</Link>
          </li>
          <li>
            <Link to={'/'}>Help</Link>
          </li>
          <li>
            <ButtonAtom
              onClick={() => {
                localStorage.deleteLoginData()
                dispatch(resetLoginUserState())
              }}
              borderRadius='4px'
              btnText='Logout'
              type={buttonTypes.secondary}
            />
          </li>
        </ul>
      </nav>
      <TabComponentMolecule listOfTabs={listOfTabs}>
        <div className={`${styles.page__view}`}>
          <Outlet />
        </div>
        <div></div>
      </TabComponentMolecule>
    </div>
  )
}

export default NewDashboardPage
