import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './navbar.module.css'

import { IconsRepository } from '../../../repository/icons/icons.repository'
import { useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../app/store/store'
import { appConstants } from '../../../app/constants/constants.app'

const NavbarOrganism = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const cartState = useAppSelector((state: RootState) => state.cartSlice)
  const toolState = useAppSelector((state: RootState) => state.toolCartSlice)

  const [params] = useSearchParams()
  const user = params.get('user')

  return (
    <nav
      role='navigation'
      className={`${globals['p-desktop']} ${styles.nav} ${globals.flex} ${globals['s-b']} ${globals['gap-24']}`}
    >
      <Link to={appConstants.routes.home}>
        <div className={styles.logo}>
          <IconsRepository.LogoIcon color='var(--AGROX-LIGHT-GREEN-COLOR)' />
        </div>
      </Link>

      <div
        onClick={() => {
          setMobileOpen(!mobileOpen)
        }}
        className={`${styles.burger}`}
      >
        {mobileOpen ? <IconsRepository.HambugerMenuIcon /> : <IconsRepository.HambugerMenuIcon />}
      </div>

      <ul
        className={`${styles.menu} ${mobileOpen ? styles.show : ''} ${globals.flex} ${
          globals['a-center']
        } ${globals['gap-16']}`}
      >
        <li>
          <Link to='/farm-stores'>Farm Stores</Link>
        </li>
        {/* <li>
          <Link to='/companies'>Companies</Link>
        </li> */}
        {/* <li>
          <Link to='/tools-and-materials'>Tools & Materials</Link>
        </li> */}

        <li className={styles.diff}>
          <Link
            className={`${globals.flex} ${globals['a-center']} ${globals['gap-10']}`}
            to={user === 'farmer' ? '/cart?user=farmer' : '/cart'}
          >
            <IconsRepository.ShoppingCartIcon size={20} />
            Cart
            {user !== 'farmer' ? (
              <>
                {cartState.products.length > 0 ? (
                  <div className={`${styles.count} ${globals.flex} ${globals['center-items']}`}>
                    <h4>{cartState.products.length}</h4>
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {toolState.tools.length > 0 ? (
                  <div className={`${styles.count} ${globals.flex} ${globals['center-items']}`}>
                    <h4>{toolState.tools.length}</h4>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </Link>
        </li>
        <li
          style={{
            marginLeft: '12px',
          }}
        >
          <Link
            className={`${globals.flex} ${globals['a-center']} ${globals['gap-10']}`}
            to='/new-dashboard'
          >
            <IconsRepository.UserIcon size={19} />
            My Account
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default NavbarOrganism
