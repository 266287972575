import React from 'react'

import NavbarOrganism from '../../components/organisms/navbar/Navbar.organism'
// import NavigationBarOrganism from '../../components/organisms/navigation-bar/NavigationBar.organism'
import HeroTemplate from '../../components/templates/home/hero/Hero.home.template'
import FeaturesTemplate from '../../components/templates/home/features/Features.home.template'
import CeoTemplate from '../../components/templates/home/ceo/Ceo.template'
import FooterOrganism from '../../components/organisms/footer/Footer.organism'
// import TabComponentMolecule from '../../components/molecules/tab-component/TabComponent.molecule'

const HomePage = () => {
  return (
    <div>
      <NavbarOrganism />
      <HeroTemplate />
      {/* <TabComponentMolecule
        listOfTabs={[
          { id: 0, label: 'Account', link: '/account' },
          { id: 1, label: 'Farmers', link: '/famers' },
        ]}
      >
        <div>hi</div>
        <div>Yea</div>
      </TabComponentMolecule> */}
      <FeaturesTemplate />
      <CeoTemplate />
      <FooterOrganism />
    </div>
  )
}

export default HomePage
