import React, { useState, useCallback, useEffect } from 'react'

import styles from '../view.module.css'

import PCardMolecule from '../cards/p-card/PCard.molecule'
import ModalMolecule from '../../../../components/molecules/modal/Modal.molecule'
import PDetailsMolecule from '../cards/p-details/PDetails.molecule'
import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import {
  NotificationTitle,
  triggerNotification,
} from '../../../../app/features/notification/notification.slice'
import { useAppDispatch } from '../../../../lib/hooks'
import { ITool } from '../../../../types/tool-types/tool.types'

const NewFarmingToolsView = () => {
  const [selectedId, setSelectedId] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const [tools, setTools] = useState<ITool[]>([])

  const fetchTools = useCallback(async () => {
    setLoading(true)
    try {
      await publicApiRequest()
        .get('/tools')
        .then((response) => {
          setTools(response.data.tools)

          dispatch(
            triggerNotification({
              message: 'Tools Loaded.',
              title: NotificationTitle.SUCCESS,
            }),
          )
        })
    } catch (e) {
      console.log(e)

      dispatch(
        triggerNotification({
          message: 'Error occured... Please reload page again.',
          title: NotificationTitle.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchTools()
  }, [fetchTools])

  return (
    <div className={`${styles.view}`}>
      <h2>Farming Tools</h2>
      {selectedId.length > 0 ? (
        <ModalMolecule
          close={() => {
            setSelectedId('')
          }}
        >
          <PDetailsMolecule
            loading={loading}
            image={tools.find((p) => p._id === selectedId)?.imageUrl as string}
            name={tools.find((p) => p._id === selectedId)?.name as string}
            description={tools.find((p) => p._id === selectedId)?.description as string}
            price={`${tools.find((p) => p._id === selectedId)?.price}`}
            unit=''
            quantity={tools.find((p) => p._id === selectedId)?.quantity}
            category=''
            tool={tools.find((p) => p._id === selectedId)}
            setSelectedId={setSelectedId}
            ableToEdit={false}
          />
        </ModalMolecule>
      ) : (
        ''
      )}
      <div className={`${styles.grid}`}>
        {loading &&
          Array(8)
            .fill(0)
            .map((_product, index) => {
              return (
                <PCardMolecule
                  loading={true}
                  image=''
                  name=''
                  description=''
                  price=''
                  unit=''
                  key={index}
                />
              )
            })}
      </div>
      <div className={`${styles.grid}`}>
        {!loading &&
          tools &&
          tools.length > 0 &&
          tools.map((tool, index) => {
            return (
              <PCardMolecule
                toolCard={true}
                onClick={() => {
                  setSelectedId(tool._id as string)
                }}
                image={tool.imageUrl as string}
                name={tool.name}
                description={tool.description}
                price={`${tool.price}`}
                unit=''
                key={index}
              />
            )
          })}
      </div>
    </div>
  )
}

export default NewFarmingToolsView
