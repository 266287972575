import { createAsyncThunk } from '@reduxjs/toolkit'

import { getExceptionPayload } from '../../../../lib/utils/get-exception-payload'
import { ApiRequestErrorType } from '../../../../types/api.type'
import { AuthService } from '../../../../service/auth.service'
import {
  ChangePasswordDataType,
  ForgotPasswordDataType,
  LoginDataType,
  RegisterDataType,
  VerifyUserDataType,
} from '../../../../types/auth.type'

const authService = new AuthService()

export const loginUserThunk = createAsyncThunk(
  '/api/auth/login',
  async (data: LoginDataType, { rejectWithValue }) => {
    try {
      const response = await authService.loginUserService(data)
      return response
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const registerUserThunk = createAsyncThunk(
  '/api/auth/register',
  async (data: RegisterDataType, { rejectWithValue }) => {
    try {
      const response = await authService.registerUserService(data)
      return response
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const verifyUserThunk = createAsyncThunk(
  '/api/auth/verify-user',
  async (data: VerifyUserDataType, { rejectWithValue }) => {
    try {
      const response = await authService.verifyUserService(data)
      return response
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const forgotPasswordThunk = createAsyncThunk(
  '/api/auth/forgot-password',
  async (data: ForgotPasswordDataType, { rejectWithValue }) => {
    try {
      const response = await authService.forgotPasswordService(data)
      return response
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const changePasswordThunk = createAsyncThunk(
  '/api/auth/change-password',
  async (data: ChangePasswordDataType, { rejectWithValue }) => {
    try {
      const response = await authService.changePasswordService(data)
      return response
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
