import React from 'react'

import styles from '../view.module.css'

const FarmersView = () => {
  return (
    <div className={`${styles.view}`}>
      <h2>Farmers Available</h2>
    </div>
  )
}

export default FarmersView
